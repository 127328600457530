import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { SuccessfullyDeleteBotModal as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const SuccessfullyDeleteBotModal = ({
  successfullyDeleteBotModalShow,
  closeSuccessfullyDeleteBotModal,
  type = 'bot'
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <Modal
      show={successfullyDeleteBotModalShow}
      onHide={
        type === 'bot'
          ? () => closeSuccessfullyDeleteBotModal()
          : () => window.location.reload()
      }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={checkPrimaryLight}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4 className="text-center font-weight-bold">
          {type === 'bot' ? textLang.text[lang] : textLang.textPortfolio[lang]}
        </h4>
      </Modal.Body>
      {type === 'bot' && (
        <Modal.Footer>
          <Button onClick={() => closeSuccessfullyDeleteBotModal()}>
            {textLang.close[lang]}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

SuccessfullyDeleteBotModal.propTypes = {
  successfullyDeleteBotModalShow: PropTypes.bool.isRequired,
  closeSuccessfullyDeleteBotModal: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default SuccessfullyDeleteBotModal;
