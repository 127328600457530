import React, { useContext } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card, Row, Alert } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import corner1 from 'assets/img/illustrations/corner-1.png';
import Background from 'components/common/Background';
import { riskLevelExplain as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <>
      <Row className="mb-3">
        <Card className="pe-0">
          <Card.Header className="position-relative">
            <Background
              image={corner1}
              className="bg-card z-index-1"
              style={{
                backgroundPosition: 'right',
                zIndex: '-1'
              }}
            />
            <h4 className="text-800 mb-0">{textLang.title[lang]}</h4>
          </Card.Header>
        </Card>
      </Row>
      <Row className="mb-3">
        <Card>
          <Card.Header as={Flex} alignItems="center"></Card.Header>
          <Card.Body className="p-0">
            <SimpleBarReact className="">
              <div className="pt-0 px-card">
                <h5
                  className="fs--2 text-800"
                  dangerouslySetInnerHTML={{
                    __html: textLang.description[lang]
                  }}
                ></h5>
              </div>
              <Alert className="mt-2 me-3 ms-3" variant="warning">
                <p
                  className="fs--2 mb-0"
                  dangerouslySetInnerHTML={{
                    __html: textLang.disclaimer[lang]
                  }}
                ></p>
              </Alert>
            </SimpleBarReact>
          </Card.Body>
          <Card.Footer className="py-3"></Card.Footer>
        </Card>
      </Row>
    </>
  );
};

export default index;
