import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Col, Row, Modal, Button, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { ConfirmMailModal as textLang } from 'staticData/languages';
import Wizard from 'pages/common/wizard/Wizard';
import { useHistory } from 'react-router-dom';
import sendMail from 'assets/img/animated-icons/sendMail.json';
import Lottie from 'lottie-react';

const ConfirmMail = ({
  ConfirmMailShow,
  setConfirmMailShow,
  step,
  withHeader = true,
  userCanClose = true
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [disableButton, setDisableButton] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const routerHistory = useHistory();

  const resendMail = async () => {
    setDisableButton(true);
    setShowSpinner(true);

    // Generate Recaptcha Token & Resend Mail
    window.grecaptcha.enterprise.ready(async () => {
      const recaptcha_token = await window.grecaptcha.enterprise.execute(
        '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
        { action: 'resend_confirm' }
      );
      let form = new FormData();
      form.append('recaptcha_token', recaptcha_token);

      let resendMailResponse = await endpointInterface(
        lang,
        'backend',
        'resendMail',
        'post',
        true,
        form
      );

      setShowSpinner(false);
      if (resendMailResponse.validResponse) {
        setShowMessage(true);
      } else {
        toast.error(resendMailResponse.responseMessage, {
          closeButton: false
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    });
  };

  return (
    <Modal
      show={ConfirmMailShow}
      onHide={() => (userCanClose ? setConfirmMailShow(false) : null)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>{withHeader && <Wizard step={step} />}</Modal.Header>
      {showMessage ? (
        <Modal.Body className="text-center">
          <Row className="justify-content-center">
            <Col xs={10}>
              <h5>{textLang.msg[lang]}</h5>
              <Lottie
                animationData={sendMail}
                loop={true}
                style={{ height: '300px' }}
              />
            </Col>
          </Row>
        </Modal.Body>
      ) : (
        <Modal.Body className="text-center">
          <Row className="justify-content-center">
            <h5>{textLang.text[lang]}</h5>
            <Col xs={10}>
              <h6>{textLang.text1[lang]}</h6>
            </Col>
          </Row>
          <Row className="justify-content-center py-3">
            <Col xs={8} md={6}>
              <Button
                className="w-100"
                disabled={disableButton}
                onClick={() => resendMail()}
              >
                <Flex className="justify-content-center">
                  {showSpinner && (
                    <Spinner
                      animation="border"
                      className="me-2 mt-1"
                      style={{ width: '1rem', height: '1rem' }}
                    ></Spinner>
                  )}
                  {textLang.button[lang]}
                </Flex>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      )}
      {userCanClose ? (
        <Modal.Footer>
          <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
            <IconButton
              onClick={() => setConfirmMailShow(false)}
              variant="outline-success"
              className="me-2 mb-1 "
              icon=""
              iconAlign="left"
              transform="shrink-3"
            >
              {textLang.close[lang]}
            </IconButton>
          </Flex>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
            <IconButton
              onClick={() => routerHistory.push('/')}
              variant="outline-primary"
              className="me-2 mb-1 "
              icon=""
              iconAlign="left"
              transform="shrink-3"
            >
              Home
            </IconButton>
          </Flex>
        </Modal.Footer>
      )}
    </Modal>
  );
};

ConfirmMail.propTypes = {
  ConfirmMailShow: PropTypes.bool.isRequired,
  setConfirmMailShow: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  withHeader: PropTypes.bool.isRequired,
  userCanClose: PropTypes.bool.isRequired
};

export default ConfirmMail;
