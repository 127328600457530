import React, {
  forwardRef,
  useContext,
  useEffect /*, useState*/,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
import { getColor, rgbaColor, breakpoints } from 'helpers/utils';
import AppContext from 'context/Context';
import { monthsList as textLang } from 'staticData/languages';
import { getBreakpoint, coinColor } from 'staticData/common';
import { CanvasRenderer } from 'echarts/renderers';
// import { icon } from '@fortawesome/fontawesome-svg-core';

echarts.use([
  LineChart,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  CanvasRenderer
]);

const operationToIcon = {
  BUY: {
    iconLink: 'https://www.fabiovalla.it/hodlie/markers/marker-3-g.png',
    color: 'green'
  },
  SELL: {
    iconLink: 'https://www.fabiovalla.it/hodlie/markers/marker-3-r.png',
    color: 'red'
  }
};

const getOption = (
  // lang,
  dateInfo,
  benchmark = null,
  benchmarkName = '',
  zoomActive,
  minSpan = 0,
  maxSpan = 100,
  startParam = 0,
  endParam = 100,
  currency = '$'
) => {
  const legedMap = {
    Benchmark: benchmarkName,
    Buy: 'BUY',
    Sell: 'SELL'
  };
  var options = {
    tooltip: {
      show: window.innerWidth >= breakpoints['sm'] ? true : false,
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      borderWidth: 1,
      transitionDuration: 0,
      textStyle: {
        fontWeight: 500,
        fontSize: 12,
        color: getColor('dark')
      },
      formatter: params => {
        const hasMarkersSeriesData = params.some(
          p => (p.seriesName === 'Buy' || p.seriesName === 'Sell') && p.value
        );
        return hasMarkersSeriesData
          ? getTooltipLable(params, currency /*, benchmarkName, lang*/)
          : null;
      }
    },
    legend: {
      show: true,
      data: ['Benchmark', 'Buy', 'Sell'],
      formatter: params => legedMap[params],
      textStyle: {
        color: getColor('500')
      },
      selectedMode: false,
      bottom: 0
    },
    xAxis: {
      type: 'category',
      data: dateInfo,
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: rgbaColor('#fff', 0.1)
        }
      },
      axisLine: {
        lineStyle: {
          color: rgbaColor('#fff', 0.1)
        }
      },
      axisTick: {
        show: true,
        length: 10,
        lineStyle: {
          color: rgbaColor('#fff', 0.1)
        }
      },
      axisLabel: {
        color: getColor('400'),
        fontWeight: 600,
        fontSize: 10,
        margin: 15,
        interval: 'auto'
      }
    },
    yAxis: {
      type: 'value',
      scale: true,
      axisPointer: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        color: getColor('400'),
        fontWeight: 600,
        fontSize: 10,
        margin: 5
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: [
      {
        name: 'Benchmark',
        type: 'line',
        smooth: true,
        data: benchmark.map(item => Number(item)),
        symbol: benchmark.length !== 1 ? 'none' : 'circle',
        itemStyle: {
          color: coinColor[benchmarkName.substring(0, 3)] || '#fff'
        },
        lineStyle: {
          color: coinColor[benchmarkName.substring(0, 3)] || '#fff',
          width: 1
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.5)
              },
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        },
        emphasis: {
          // lineStyle: {
          //   width: 2
          // },
          itemStyle: {
            color: rgbaColor(getColor('100'))
          },
          scale: true
        },
        z: 1
      }
    ],
    grid: {
      right: 40,
      left: 40,
      bottom:
        window.innerWidth >= breakpoints['xl']
          ? '15%'
          : window.innerWidth >= breakpoints['sm']
          ? '20%'
          : '25%',
      top: '5%'
    }
  };
  if (zoomActive) {
    options.dataZoom = [
      {
        type: 'inside',
        start: startParam * 0.999,
        end: endParam,
        minSpan: minSpan,
        maxSpan: maxSpan
      }
    ];
  }
  return options;
};

const formatAMPM = (date, lang, withMinutes = true) => {
  var hours = date.getHours();
  var ampm = '';
  if (lang === 'en-EN') {
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  }
  var strTime = hours;
  if (withMinutes) {
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    strTime = strTime + ':' + minutes;
  }
  strTime = strTime + ampm + (!withMinutes && lang !== 'en-EN' ? ':00' : '');
  return strTime;
};

const getTooltipLable = (params, currency /*, benchmarkName, lang*/) => {
  let text = ``;
  // let benchmarkTime = formatAMPM(new Date(params[0].axisValue), lang);
  let hasMarkersSeries = false;

  params.map(p => {
    // if (p.seriesName === 'Benchmark') {
    //   let textColor = coinColor[benchmarkName.substring(0, 3)] || '#fff';
    //   text += `<font color='${textColor}'><b>${benchmarkName}</b></font>: ${
    //     '$' + p.value
    //   } (h: ${benchmarkTime})`;
    // } else
    if ((p.seriesName === 'Buy' || p.seriesName === 'Sell') && p.value) {
      let stockQty = p.data.stockQuantity;
      let price = p.data.order.price;
      let time = p.data.time;
      text += `<font color='${operationToIcon[p.name]?.color || 'white'}'><b>${
        p.name
      }</b></font> ${currency}${(stockQty * price).toFixed(
        2
      )} (h: ${time})<br />
      <font color='${
        operationToIcon[p.name]?.color || 'white'
      }'>${p.name.toLowerCase()}-price</font>: ${currency}${price.toFixed(
        2
      )}<br />`;
      hasMarkersSeries = true;
    }
  });

  return hasMarkersSeries ? text : false;
};

const LinePaymentChartBenchmark = forwardRef(
  (
    {
      benchmarkName,
      orders,
      zoomActive = false,
      moreFun = false,
      benchmark,
      botData,
      style
    },
    ref
  ) => {
    const {
      config: { lang, currency }
    } = useContext(AppContext);
    const previousValues = useRef(null);
    const pointInOneDay = 144;
    const pointForBotType = {
      takeProfitBot30: { min: pointInOneDay / 6, max: pointInOneDay * 3 },
      default: { min: pointInOneDay, max: pointInOneDay * 7 }
    };
    // revert the orders array to get the temporal order
    const ordersReverse = orders.slice().reverse();

    const getTimeLabel = (time, withMinutes = true) => {
      let date = new Date(time);
      let months = textLang.months[lang];
      let day = date.getDate();
      let month = months[date.getMonth()];
      let year = date.getFullYear();
      let text =
        day +
        ' ' +
        month +
        ' ' +
        year +
        ' ' +
        formatAMPM(date, lang, withMinutes);
      return text;
    };

    // Get dates array
    const dates = Object.keys(benchmark);
    const datesArray = dates.map(e => getTimeLabel(e));

    // Get benchmark array
    const benchmarkDataAll = Object.values(benchmark);
    const benchmarkData = benchmarkDataAll.slice(-datesArray.length);

    // Define min and max span
    let totalDataPoints = benchmarkData.length;
    let minPointToshow =
      pointForBotType[botData.type]?.min || pointForBotType.default.min;
    let maxPointToshow =
      pointForBotType[botData.type]?.max || pointForBotType.default.max;
    const minPointInChart =
      totalDataPoints < minPointToshow ? totalDataPoints : minPointToshow;
    var minSpan = ((minPointInChart - 1) / totalDataPoints) * 100;
    if (minSpan > 100) {
      minSpan = 100;
    }
    const maxPointInChart =
      totalDataPoints < maxPointToshow ? totalDataPoints : maxPointToshow;
    var maxSpan = ((maxPointInChart - 1) / totalDataPoints) * 100;
    if (maxSpan > 100) {
      maxSpan = 100;
    }
    // Calculate initial start and end values
    var startParam = 100 - minSpan;
    var endParam = 100;

    const getFunMarker = operationType => {
      let stikersLink = 'https://www.fabiovalla.it/hodlie/stickers/';
      let randomStiker = Math.floor(Math.random() * 8) + 1;
      return `${stikersLink}${operationType[0]}${randomStiker}.webp`;
    };

    // Initialize markerSeries with null
    let markerSellSeries = new Array(benchmarkData.length).fill(null);
    let markerBuySeries = new Array(benchmarkData.length).fill(null);

    let orderIndex = 0;

    // Iterate over each element in benchmarkData
    for (let i = 0; i < benchmarkData.length - 1; i++) {
      // Get the current and next timestamps
      let currentTs = new Date(dates[i]).getTime();
      let nextTs = new Date(dates[i + 1]).getTime();
      while (
        orderIndex < ordersReverse.length &&
        new Date(ordersReverse[orderIndex].ts).getTime() >= currentTs &&
        new Date(ordersReverse[orderIndex].ts).getTime() <= nextTs
      ) {
        let operationType = ordersReverse[orderIndex].operationtype;
        let stockQuantity = ordersReverse[orderIndex].stockqty;
        let hourTime = formatAMPM(new Date(ordersReverse[orderIndex].ts), lang);
        let walletPrice = benchmarkData[i];
        let value;
        if (operationType === 'SELL') {
          value = walletPrice + walletPrice * 0.001;
          markerSellSeries[i] = {
            name: operationType,
            value: value,
            stockQuantity: stockQuantity,
            time: hourTime,
            xAxis: i,
            yAxis: value,
            order: ordersReverse[orderIndex],
            symbol:
              'image://' +
              (moreFun
                ? getFunMarker(operationType)
                : // : getIMGMarker(operationType, percentWeight))
                  operationToIcon[operationType].iconLink)
          };
        } else if (operationType === 'BUY') {
          value = walletPrice - walletPrice * 0.001;
          markerBuySeries[i] = {
            name: operationType,
            value: value,
            stockQuantity: stockQuantity,
            time: hourTime,
            xAxis: i,
            yAxis: value,
            order: ordersReverse[orderIndex],
            symbol:
              'image://' +
              (moreFun
                ? getFunMarker(operationType)
                : // : getIMGMarker(operationType, percentWeight))
                  operationToIcon[operationType].iconLink)
          };
        }

        orderIndex += 1;
      }
    }

    var option = getOption(
      // lang,
      datesArray,
      benchmarkData,
      benchmarkName,
      zoomActive,
      minSpan,
      maxSpan,
      startParam,
      endParam,
      currency
    );

    // Add the marker series to option.series (first and second series, SELL and BUY)
    option.series.push({
      name: 'Sell',
      type: 'scatter', // Use 'scatter' for a scatter plot
      symbol: 'image://' + operationToIcon['SELL'].iconLink,
      symbolSize: moreFun
        ? 30
        : window.innerWidth < breakpoints['xl']
        ? 10
        : 15,
      data: markerSellSeries, // Use markerSellSeries as data,
      itemStyle: {
        color: 'red'
      },
      z: 2
    });
    option.series.push({
      name: 'Buy',
      type: 'scatter', // Use 'scatter' for a scatter plot
      symbol: 'image://' + operationToIcon['BUY'].iconLink,
      symbolSize: moreFun
        ? 30
        : window.innerWidth < breakpoints['xl']
        ? 10
        : 15,
      data: markerBuySeries, // Use markerBuySeries as data,
      itemStyle: {
        color: 'green'
      },
      z: 2
    });

    // Use the useEffect hook to update the chart options when zoomActive changes
    useEffect(() => {
      let updateTimeout;
      let handTimeOut;
      // Check if the chart instance is available
      if (ref.current) {
        const canvas = ref.current
          .getEchartsInstance()
          .getZr()
          .painter.getLayers()[0].dom;
        canvas.style.cursor = 'grab';

        // Get the chart instance
        const chartInstance = ref.current.getEchartsInstance();
        // Add a mouseover event listener
        chartInstance.on('mouseover', params => {
          if (
            (params.seriesName === 'Buy' || params.seriesName === 'Sell') &&
            params.value
          ) {
            // Change the cursor when hovering over a marker
            canvas.style.cursor = 'pointer';
          }
        });

        // Add a mouseout event listener to reset the cursor
        chartInstance.on('mouseout', () => {
          canvas.style.cursor = 'grab';
        });

        // Define the dataZoom event handler
        const handleDataZoom = params => {
          // change cursor to grabbing
          const canvas = ref.current
            .getEchartsInstance()
            .getZr()
            .painter.getLayers()[0].dom;
          canvas.style.cursor = 'grabbing';
          clearTimeout(handTimeOut);
          handTimeOut = setTimeout(() => {
            canvas.style.cursor = 'grab';
          }, 100);

          // If the user's selection is larger than maxSpan
          if (params.end - params.start > maxSpan) {
            // Remove the dataZoom event handler to prevent an infinite loop
            chartInstance.off('dataZoom', handleDataZoom);
            // Calculate the new start and end values
            startParam = params.start;
            endParam = params.start + maxSpan;
            // Dispatch a dataZoom action to reset the selection to maxSpan
            chartInstance.dispatchAction({
              type: 'dataZoom',
              start: startParam,
              end: endParam
            });
            // Re-add the dataZoom event handler
            chartInstance.on('dataZoom', handleDataZoom);
          } else {
            // Check if the event was triggered by mouse wheel or touch
            if (params.batch) {
              const zoomAction = params.batch[0];
              startParam = zoomAction.start;
              endParam = zoomAction.end;
            } else {
              // The event was triggered by the zoom bar
              startParam = params.start;
              endParam = params.end;
            }
          }
          if (
            endParam - startParam >= maxSpan * 0.999 &&
            previousValues.current
          ) {
            startParam = previousValues.current.start;
            endParam = previousValues.current.end;
            chartInstance.off('dataZoom', handleDataZoom);
            chartInstance.dispatchAction({
              type: 'dataZoom',
              start: startParam,
              end: endParam
            });
            chartInstance.on('dataZoom', handleDataZoom);
          }
          if (
            endParam - startParam <= minSpan * 1.001 &&
            previousValues.current
          ) {
            startParam = previousValues.current.start;
            endParam = previousValues.current.end;
            chartInstance.off('dataZoom', handleDataZoom);
            chartInstance.dispatchAction({
              type: 'dataZoom',
              start: startParam,
              end: endParam
            });
            chartInstance.on('dataZoom', handleDataZoom);
          }
          previousValues.current = { start: startParam, end: endParam };
          const startIndex = Math.round((startParam / 100) * dates.length);
          let endIndex = Math.round((endParam / 100) * dates.length);

          // Ensure endIndex does not exceed the array length
          endIndex = endIndex >= dates.length ? dates.length - 1 : endIndex;

          // Extract the visible portion of the benchmarkData
          const visibleData = benchmarkData.slice(startIndex, endIndex + 1);

          // Calculate the max, min, and midpoint
          const max = Math.max(...visibleData);
          const min = Math.min(...visibleData);
          const midpoint = (max + min) / 2;

          // Calculate the percentage change
          const percentageChange = ((max - min) / midpoint) * 100;

          // If the percentage change is less than 1%, use 0.001, otherwise use 0.002 as multiplier for the second and third series
          const multiplier = percentageChange < 2 ? 0.001 : 0.002;

          // Update the markers for the SELL and BUY series
          const updatedSellData = [...option.series[1].data];
          const updatedBuyData = [...option.series[2].data];
          let valueChanged = false; // Add a flag to track if any value has changed

          for (
            let i = Math.max(0, startIndex - 10);
            i <= Math.min(endIndex + 10, benchmarkData.length - 1);
            i++
          ) {
            if (updatedSellData[i]) {
              const multiplierValue = 1 + multiplier;
              const newValue = Number(benchmarkData[i]) * multiplierValue;
              if (newValue !== updatedSellData[i].value) {
                valueChanged = true;
              }
              updatedSellData[i] = {
                ...updatedSellData[i],
                value: newValue,
                yAxis: newValue
              };
            }
            if (updatedBuyData[i]) {
              const multiplierValue = 1 - multiplier;
              const newValue = Number(benchmarkData[i]) * multiplierValue;
              if (newValue !== updatedBuyData[i].value) {
                valueChanged = true;
              }
              updatedBuyData[i] = {
                ...updatedBuyData[i],
                value: newValue,
                yAxis: newValue
              };
            }
          }

          // Clear any existing timeout
          clearTimeout(updateTimeout);

          // Set a timeout to update the chart after the user has stopped dragging for 500ms
          updateTimeout = setTimeout(() => {
            if (valueChanged) {
              const updatedSeries = option.series.map((series, index) => {
                if (index === 1) {
                  // Update the data for the SELL series
                  return { ...series, data: updatedSellData };
                } else if (index === 2) {
                  // Update the data for the BUY series
                  return { ...series, data: updatedBuyData };
                } else {
                  return series;
                }
              });

              chartInstance.setOption({
                series: updatedSeries
              });
            }
          }, 300);
        };
        // In your useEffect hook
        chartInstance.on('dataZoom', handleDataZoom);
      }
    }, [zoomActive, botData.id]); // Re-run the effect when zoomActive changes

    return (
      <ReactEChartsCore
        echarts={echarts}
        ref={ref}
        option={option}
        style={style[getBreakpoint(window.innerWidth)]}
      />
    );
  }
);

LinePaymentChartBenchmark.propTypes = {
  benchmarkName: PropTypes.string,
  style: PropTypes.object,
  orders: PropTypes.object,
  zoomActive: PropTypes.bool,
  moreFun: PropTypes.bool,
  benchmark: PropTypes.object,
  botData: PropTypes.object
};

export default LinePaymentChartBenchmark;
