import React, { useRef, useContext } from 'react';
import AppContext from 'context/Context';
import { TermsOfUse as textLang } from 'staticData/termsOfUse';
import corner1 from 'assets/img/illustrations/corner-1.png';
import Background from 'components/common/Background';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const sectionRefs = textLang.sections.map(() => useRef(null));

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const textSize = 'small';
  const lastUpdate = '21/08/2023';

  return (
    <>
      <Row className="ms-0 me-0 mb-3">
        <Card className="m-0 p-0">
          <Card.Header>
            <Background
              image={corner1}
              className="bg-card z-index-1"
              style={{
                backgroundPosition: 'right',
                zIndex: '-1'
              }}
            />
            <h4 className="text-800 mb-0">{textLang.title[lang]}</h4>
          </Card.Header>
        </Card>
      </Row>
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          {textLang.sections.map((section, index) => (
            <Card
              key={index}
              className="mb-3"
              id={section.link}
              ref={sectionRefs[index]}
            >
              <Card.Header className="bg-light">
                <h5 className="mb-0">{index + 1 + ' ' + section.name[lang]}</h5>
              </Card.Header>
              <Card.Body>
                {section.texts.map((text, insideIndex) => (
                  <>
                    <p
                      key={insideIndex}
                      className={'mb-2 ps-3 ' + textSize}
                      dangerouslySetInnerHTML={{
                        __html:
                          '<b>' +
                          (index + 1) +
                          '.' +
                          (insideIndex + 1) +
                          '</b> ' +
                          text[lang]
                      }}
                    ></p>
                    {text.subtexts &&
                      text.subtexts.map((subtext, subInsideIndex) => (
                        <p
                          key={subInsideIndex}
                          className={'mb-2 ps-3 ' + textSize}
                          style={{ marginLeft: '30px' }}
                          dangerouslySetInnerHTML={{
                            __html:
                              '<b>' +
                              (index + 1) +
                              '.' +
                              (insideIndex + 1) +
                              '.' +
                              (subInsideIndex + 1) +
                              '</b> ' +
                              subtext[lang]
                          }}
                        ></p>
                      ))}
                  </>
                ))}
              </Card.Body>
            </Card>
          ))}
          <p
            className={'mb-2 ps-3 ' + textSize}
            dangerouslySetInnerHTML={{ __html: textLang.ending[lang] }}
          ></p>
          <p className={'mb-2 ps-3 ' + textSize}>
            {textLang.lastUpdate[lang] + lastUpdate}
          </p>
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">{textLang.onThisPage[lang]}</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  {textLang.sections.map((section, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        href={'#' + section.link}
                        className="nav-link px-0 py-1"
                        active={activeSection === index}
                      >
                        <b>{index + 1}</b>
                        {' ' + section.name[lang]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default index;
