import React from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav } from 'react-bootstrap';
import classNames from 'classnames';

const WizardLayout = ({ step, totalSteps }) => {
  return (
    <Card
      as={Form}
      noValidate
      className="theme-wizard"
      style={{
        backgroundColor: 'var(--falcon-body-bg)',
        width: '-webkit-fill-available',
        boxShadow: 'unset'
      }}
    >
      <Nav className="justify-content-center">
        {Array(totalSteps)
          .fill(null)
          .map((_, index) => (
            <NavItem key={index} index={index} step={step} />
          ))}
      </Nav>
    </Card>
  );
};

const NavItem = ({ index, step }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        style={{ cursor: 'default' }}
      >
        <span
          className="nav-item-circle-parent"
          style={{ padding: '0 0.5rem' }}
        >
          <span className="nav-item-circle" />
        </span>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  step: PropTypes.number,
  totalSteps: PropTypes.number
};

NavItem.propTypes = {
  step: PropTypes.number,
  index: PropTypes.number
};

export default WizardLayout;
