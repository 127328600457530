import React, { useState, useContext, useEffect } from 'react';
import { Row, Card, Spinner, Col, Button } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import WalletHeader from './WalletHeader';
import BotRow from 'pages/common/BotRow';
import 'assets/scss/custom/tables.scss';
import 'assets/scss/custom/walletPage.scss';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { teamLevel } from 'staticData/common';
import SubSectionHeader from 'pages/common/SubSectionHeader';
import PortfolioRow from 'pages/common/portfolioRow/PortfolioRow';
import { currencyMap } from 'services/coins/common';

const VirtualMode = () => {
  const {
    config: { lang, currency },
    coinValues,
    userInfos
  } = useContext(AppContext);
  const [botList, setBotList] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [dynamicCoins, setDynamicCoins] = useState({});
  const [loadedBotList, setLoadedBotList] = useState(false);
  // const [showUpToPro, setShowUpToPro] = useState(false);

  const canSeePortfolio = true;
  // Boolean(userInfos?.permissions?.bot_type_virtual_allowed_categories) &&
  // userInfos.permissions.bot_type_virtual_allowed_categories.includes(
  //   'portfolio'
  // );

  const canSeeBots = true;
  // Boolean(userInfos?.permissions?.bot_type_virtual_allowed_categories) &&
  // userInfos.permissions.bot_type_virtual_allowed_categories.includes('bot');

  const getBotData = async () => {
    var params = { virtual: true };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getBots',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      return walletStatsResponse.data;
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const transformArray = arr => {
    const bots = [];
    const portfolios = [];

    arr.forEach(data => {
      if (data.status !== 'TERMINATED') {
        const { assets, last_stat, ...bot_details } = data;
        const addedCapital = (bot_details.allocation_changes || []).reduce(
          (sum, item) => sum + item.metadata?.eqv[currencyMap[currency]] || 0,
          0
        );
        const transformedData = {
          coins: assets,
          last_stat: last_stat,
          bot_details: bot_details,
          added_capital: addedCapital
        };

        if (bot_details?.category === 'bot') {
          bots.push(transformedData);
        } else if (bot_details?.category === 'portfolio') {
          portfolios.push(transformedData);
        }
      }
    });

    return { bots, portfolios };
  };

  const manageBotsData = async () => {
    let localWalletStat = await getBotData();
    let localBotList = transformArray(localWalletStat);
    setPortfolioList(localBotList.portfolios);
    setBotList(localBotList.bots);
    setLoadedBotList(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        manageBotsData();
      } catch (error) {
        console.error(error.message);
      }
    };
    if (coinValues.current) {
      setDynamicCoins(coinValues.current);
    }
    fetchData();
  }, []);

  useEffect(async () => {
    var intervalCoinsData = setInterval(async () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
      }
    }, 1000);

    return () => {
      clearInterval(intervalCoinsData);
    };
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Card className="pe-0">
          <Card.Header className="position-relative">
            <h4 className="text-800 mb-0">{textLang.title[lang]}</h4>
          </Card.Header>
        </Card>
      </Row>
      <WalletHeader />
      {(canSeePortfolio || userInfos?.level >= teamLevel) && (
        <>
          <SubSectionHeader title={<Flex>AI Portfolio</Flex>} />
          {loadedBotList ? (
            portfolioList.length ? (
              portfolioList.map((bot, index) => (
                <PortfolioRow
                  bot={bot}
                  key={index}
                  dynamicCoins={dynamicCoins}
                />
              ))
            ) : (
              <Row className={'pb-3'}>
                <Col xs={12}>
                  <PageHeader
                    as={Link}
                    to="/invest?type=portfolio&exchange=virtual"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    title={textLang.addPortfolio[lang]}
                    description={textLang.addTextPortfolio[lang]}
                    className="mb-3"
                    col={{ lg: 12 }}
                  >
                    <Button variant="link" size="sm" className="ps-0">
                      {textLang.addButton[lang]}
                      <FontAwesomeIcon
                        icon="chevron-right"
                        className="ms-1 fs--2"
                      />
                    </Button>
                  </PageHeader>
                </Col>
              </Row>
            )
          ) : (
            <Flex className="justify-content-center mt-4">
              <Spinner />
            </Flex>
          )}
        </>
      )}
      {(canSeeBots || userInfos?.level >= teamLevel) && (
        <>
          <SubSectionHeader
            className="mt-3"
            title={
              <Flex>
                AI Agents
                {botList.length > 0 && (
                  <>
                    {' '}
                    <span className="d-none d-sm-block">
                      ({String(botList.length)}) -
                      <Link to="/invest?exchange=virtual&type=bot">
                        {textLang.addBots[lang]}
                      </Link>
                    </span>
                  </>
                )}
              </Flex>
            }
          />
          <Link className="d-sm-none" to="/invest?type=bot?exchange=virtual">
            {textLang.addBots[lang]}
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Link>
          {loadedBotList ? (
            botList.length ? (
              botList.map((bot, index) => (
                <BotRow bot={bot} key={index} dynamicCoins={dynamicCoins} />
              ))
            ) : (
              <Row className={'pb-3'}>
                <Col xs={12}>
                  <PageHeader
                    as={Link}
                    to="/invest?exchange=virtual&type=bot"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    title={textLang.add[lang]}
                    description={textLang.addText[lang]}
                    className="mb-3"
                    col={{ lg: 12 }}
                  >
                    <Button variant="link" size="sm" className="ps-0">
                      {textLang.addButton[lang]}
                      <FontAwesomeIcon
                        icon="chevron-right"
                        className="ms-1 fs--2"
                      />
                    </Button>
                  </PageHeader>
                </Col>
              </Row>
            )
          ) : (
            <Flex className="justify-content-center mt-4">
              <Spinner />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default VirtualMode;
