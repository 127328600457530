import React, { useRef, useContext } from 'react';
import AppContext from 'context/Context';
import { TermsAndConditions as textLang } from 'staticData/termsAndConditions';
import corner1 from 'assets/img/illustrations/corner-1.png';
import Background from 'components/common/Background';
import { Card, Col, Nav, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import ReactToPrint from 'react-to-print';
import DocumentToPrint from './document';
import TosFormatter from './tosFormatter';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
library.add(faDownload);

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const sectionRefs = textLang.sections.map(() => useRef(null));

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const componentRef = useRef();

  return (
    <>
      <Row className="ms-0 me-0 mb-3">
        <Card className="m-0 p-0">
          <Card.Header className="d-flex flex-row">
            <Background
              image={corner1}
              className="bg-card"
              style={{
                backgroundPosition: 'right'
              }}
            />
            <h4
              style={{ width: '-webkit-fill-available' }}
              className="text-800 mb-0"
            >
              {textLang.title[lang]}
            </h4>

            <div style={{ zIndex: 2 }} className="cursor-pointer">
              <ReactToPrint
                documentTitle={
                  'Hodlie_T&C_v' + textLang.version + '_' + lang.split('-')[1]
                }
                content={() => componentRef.current}
                trigger={() => (
                  <div className="px-2 theme-control-toggle">
                    <OverlayTrigger
                      key="left"
                      placement={'left'}
                      overlay={
                        <Tooltip id="ThemeColor">
                          {textLang.download[lang]}
                        </Tooltip>
                      }
                    >
                      <div className="">
                        <FontAwesomeIcon icon={'download'} className="fs-0" />
                      </div>
                    </OverlayTrigger>
                  </div>
                )}
              />
              <div style={{ display: 'none' }}>
                <DocumentToPrint sectionRefs={sectionRefs} ref={componentRef} />
              </div>
            </div>
          </Card.Header>
        </Card>
      </Row>
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          <TosFormatter sectionRefs={sectionRefs} />
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">{textLang.onThisPage[lang]}</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  {textLang.sections.map((section, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        href={'#' + section.link}
                        className="nav-link px-0 py-1"
                        active={activeSection === index}
                      >
                        <b>{index + 1}</b>
                        {' ' + section.name[lang]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default index;
