import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FeedbackPopUp as textLang, languages } from 'staticData/languages';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeedbackPopUp = ({ feedbackModalShow, handleHideModal }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    <Modal
      show={feedbackModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="justify-content-center d-grid">
        <h5>{textLang.morePop[lang]}</h5>
        <Flex className="justify-content-center py-3">
          <FontAwesomeIcon
            className="pe-3"
            icon="thumbs-up"
            size="2x"
            style={{ color: 'var(--falcon-success)', cursor: 'pointer' }}
            onClick={() => handleHideModal('thumbs up')}
          />
          <FontAwesomeIcon
            className="ps-3"
            icon="thumbs-down"
            transform="flip-h"
            size="2x"
            style={{ color: 'var(--falcon-danger)', cursor: 'pointer' }}
            onClick={() => handleHideModal('thumbs down')}
          />
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

FeedbackPopUp.propTypes = {
  feedbackModalShow: PropTypes.bool.isRequired,
  handleHideModal: PropTypes.func.isRequired
};

const MessagePopUp = ({
  messageModalShow,
  setMessageModalShow,
  data,
  popUpId
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const [feedbackModalShow, setFeedbackModalShow] = useState(false);
  const TEST = Object.keys(data).includes('test') ? data.test : '';
  const title = Object.keys(data).includes('title') ? data.title : TEST;
  const text = Object.keys(data).includes('text') ? data.text : TEST;
  const sendFeedbak = Object.keys(data).includes('want_feedback')
    ? data.want_feedback
    : false;

  const handleHideModalFeedBack = feedback => {
    let form = new FormData();
    form.append('feedback', feedback);
    form.append('id', popUpId);
    endpointInterface(lang, 'backend', 'popupFeedback', 'post', true, form);
    setFeedbackModalShow(false);
  };

  const handleHideModal = () => {
    setMessageModalShow(false);
    if (sendFeedbak) setFeedbackModalShow(true);
    else handleHideModalFeedBack('none');
  };

  return (
    <>
      <FeedbackPopUp
        feedbackModalShow={feedbackModalShow}
        setFeedbackModalShow={setFeedbackModalShow}
        handleHideModal={handleHideModalFeedBack}
        popUpId={popUpId}
      />
      <Modal
        show={messageModalShow}
        onHide={handleHideModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            dangerouslySetInnerHTML={{
              __html: Object.keys(title).some(r => languages.includes(r))
                ? title[lang]
                : title
            }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            <p
              className="text-center m-0"
              dangerouslySetInnerHTML={{
                __html: Object.keys(text).some(r => languages.includes(r))
                  ? text[lang]
                  : text
              }}
            ></p>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleHideModal}
            dangerouslySetInnerHTML={{
              __html: textLang.closing[lang]
            }}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MessagePopUp.propTypes = {
  messageModalShow: PropTypes.bool.isRequired,
  setMessageModalShow: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  popUpId: PropTypes.number.isRequired
};

export default MessagePopUp;
