export const titlesForm = [
  {
    'en-EN':
      'These questions will help us assess your emotional attitude and impulsiveness towards investments (on a scale from 1 to 5, where 1 is "Strongly Disagree" and 5 is "Strongly Agree")',
    'it-IT': `Queste domande ci aiuteranno a valutare il tuo atteggiamento emotivo e la tua impulsività nei confronti degli investimenti (su una scala da 1 a 5, dove 1 è "Per niente d'accordo" e 5 è "Completamente d'accordo")`
  },
  {
    'en-EN':
      'Now we ask you to answer some short questions about your experience with cryptocurrencies and your financial education. This will help us identify the most suitable risk profile.',
    'it-IT':
      'Ora ti chiediamo di rispondere a delle brevi domande circa la tua esperienza con le criptovalute e la tua educazione finanziaria. Questo ci aiuterà ad identificare il profilo di rischio più idoneo.'
  }
];

export const riskProfiles = [
  {
    title: {
      'en-EN': 'Low Risk',
      'it-IT': `Basso Rischio`
    },
    descr: {
      'en-EN':
        'A low-risk profile indicates a preference for safe and stable investments. In this profile, the risk of loss is minimal, and the focus is on capital preservation. This profile is ideal for those who want to avoid market volatility and prefer slow and steady growth of their capital.',
      'it-IT':
        'Un profilo a basso rischio indica una preferenza per investimenti sicuri e stabili. In questo profilo, il rischio di perdita è minimo, e il focus è sulla conservazione del capitale. Questo profilo è ideale per chi vuole evitare la volatilità del mercato e preferisce una crescita lenta e costante del proprio capitale.'
    }
  },
  {
    title: {
      'en-EN': 'Moderate Risk',
      'it-IT': `Rischio Moderato`
    },
    descr: {
      'en-EN':
        'A moderate-risk profile reflects a balance between security and potential returns. In this case, investments are diversified between low-risk products and those with higher risk but greater potential return. The risk of loss is present but is carefully managed to limit exposure to significant market fluctuations.',
      'it-IT': `Un profilo a rischio moderato riflette un equilibrio tra sicurezza e opportunità di rendimento. In questo caso, gli investimenti sono diversificati tra prodotti a basso rischio e quelli con un rischio più elevato ma con potenziale di maggior rendimento.Il rischio di perdita è presente ma è gestito attentamente per limitare l'esposizione a forti fluttuazioni di mercato.`
    }
  },
  {
    title: {
      'en-EN': 'High Risk',
      'it-IT': `Alto Rischio`
    },
    descr: {
      'en-EN':
        'A high-risk profile indicates a propensity to take significant risks in exchange for potential high returns. This profile is characterized by greater volatility and the possibility of capital loss. It is suitable for those willing to accept substantial short-term losses with the goal of achieving superior long-term gains.',
      'it-IT': `Un profilo ad alto rischio indica una predisposizione a correre rischi significativi in cambio di potenziali rendimenti elevati. Questo profilo è caratterizzato da una maggiore volatilità e possibilità di perdita del capitale. È adatto a chi è disposto ad accettare perdite importanti nel breve termine con l'obiettivo di ottenere guadagni superiori nel lungo periodo.
`
    }
  }
];

export const completeRiskQuestions = [
  {
    answers: [
      {
        childID: 'risky_products',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'risky_products',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'risky_products',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'risky_products',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'risky_products',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'risk',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'I am willing to take significant risks to seize new profitable investment opportunities.',
      'it-IT':
        'Sono disposto a correre rischi significativi per cogliere nuove opportunità di investimento.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'high_tollerance',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'high_tollerance',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'high_tollerance',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'high_tollerance',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'high_tollerance',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'risky_products',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'My preference falls on financial instruments with a higher potential return, even if they carry a higher level of risk.',
      'it-IT':
        'La mia preferenza ricade su strumenti finanziari con un potenziale rendimento più elevato, anche se comportano un livello di rischio superiore.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'value',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'value',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'value',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'value',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'value',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'high_tollerance',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'During downturns in the financial markets, I am able to tolerate substantial losses on my investment portfolio.',
      'it-IT':
        'In caso di fasi di ribasso dei mercati finanziari, sono in grado di tollerare perdite consistenti sul mio portafoglio di investimenti.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'stress',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'stress',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'stress',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'stress',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'stress',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'value',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'I tend to maintain my investment positions even in the presence of a temporary decrease in their market value.',
      'it-IT':
        'Tendo a mantenere le mie posizioni di investimento anche in presenza di una temporanea diminuzione del loro valore di mercato.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'diversify',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'diversify',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'diversify',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'diversify',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'diversify',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'stress',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'Investments do not represent a source of psychological stress that would lead me to monitor their performance on a daily basis.',
      'it-IT':
        'Gli investimenti non rappresentano una fonte di stress psicologico tale da indurmi a monitorarne quotidianamente l’andamento.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'planning',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'planning',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'planning',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'planning',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'planning',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'diversify',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'I am inclined to diversify my investment portfolio by allocating capital across a wide range of assets, including high risk instruments.',
      'it-IT':
        'Sono propenso a diversificare il mio portafoglio di investimenti allocando capitali in una vasta gamma di asset, inclusi strumenti ad elevata rischiosità.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'crypto',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'crypto',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'crypto',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'crypto',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'crypto',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'planning',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'When planning my investments, I tend to focus on a long-term time horizon, accepting short-term fluctuations in value.',
      'it-IT':
        'Nel pianificare i miei investimenti, tendo a focalizzarmi su un orizzonte temporale di lungo periodo, accettando oscillazioni di valore nel breve termine.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'time',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'crypto_risk',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'crypto',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'Have you ever invested in crypto?',
      'it-IT': 'Hai mai investito in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'blockchain',
        id: '<6m',
        text: {
          'en-EN': 'Less than 6 months',
          'it-IT': 'Meno di 6 mesi'
        }
      },
      {
        childID: 'blockchain',
        id: '<1a',
        text: {
          'en-EN': 'From 6 months to 1 year',
          'it-IT': 'Da 6 mesi a 1 anno'
        }
      },
      {
        childID: 'blockchain',
        id: '>1a',
        text: {
          'en-EN': 'More than 1 year',
          'it-IT': 'Più di 1 anno'
        }
      }
    ],
    id: 'time',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'How long have you been investing in cryptocurrencies?',
      'it-IT': 'Da quanto tempo investi in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'crypto_risk',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'crypto_risk',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'blockchain',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'Do you know what a blockchain is and how it works?',
      'it-IT': "Sai cos'è una blockchain e come funziona?"
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'less20',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'less20',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'crypto_risk',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN':
        'Are you aware of the risks associated with cryptocurrency investments?',
      'it-IT': 'Conosci i rischi associati agli investimenti in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'low_volume',
        id: 'sell',
        text: {
          'en-EN': 'I would immediately sell my cryptocurrencies.',
          'it-IT': 'Venderei subito le mie criptovalute.'
        }
      },
      {
        childID: 'low_volume',
        id: 'wait',
        text: {
          'en-EN': 'I would wait to better assess the situation.',
          'it-IT': 'Attenderei per valutare meglio la situazione.'
        }
      },
      {
        childID: 'low_volume',
        id: 'invest',
        text: {
          'en-EN': 'I would invest more to take advantage of the lower prices.',
          'it-IT': 'Investirei di più per sfruttare il calo dei prezzi.'
        }
      },
      {
        childID: 'low_volume',
        id: 'idk',
        text: {
          'en-EN': 'I don’t know',
          'it-IT': 'Non lo so'
        }
      }
    ],
    id: 'less20',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN':
        'If the cryptocurrency market suffered a 20% decline, what would be your reaction?',
      'it-IT':
        'Se il mercato delle criptovalute subisse una flessione del 20%, quale sarebbe la tua reazione?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'main_reason',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Yes'
        }
      },
      {
        childID: 'main_reason',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      },
      {
        childID: 'main_reason',
        id: 'idk',
        text: {
          'en-EN': 'I don’t know',
          'it-IT': 'Non saprei'
        }
      }
    ],
    id: 'low_volume',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN':
        'Are you comfortable investing in new projects or tokens with low trading volume?',
      'it-IT':
        'Ti senti a tuo agio nell’investire in nuovi progetti o token con basso volume di scambi?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'main_goal',
        id: 'diversification',
        text: {
          'en-EN': 'Portfolio diversification',
          'it-IT': 'Diversificazione del portafoglio'
        }
      },
      {
        childID: 'main_goal',
        id: 'performance',
        text: {
          'en-EN': 'High performance potential',
          'it-IT': 'Alto potenziale di rendimento'
        }
      },
      {
        childID: 'main_goal',
        id: 'technology',
        text: {
          'en-EN': 'Interest in technology',
          'it-IT': 'Interesse per la tecnologia'
        }
      },
      {
        childID: 'main_goal',
        id: 'other',
        text: {
          'en-EN': 'Other motivations',
          'it-IT': 'Altre motivazioni'
        }
      }
    ],
    id: 'main_reason',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is your main motivation for investing in cryptocurrency?',
      'it-IT':
        'Qual è la tua principale motivazione per investire in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'job',
        id: 'any',
        text: {
          'en-EN': 'Any eventuality',
          'it-IT': 'Una qualsiasi evenienza'
        }
      },
      {
        childID: 'job',
        id: 'protection',
        text: {
          'en-EN': 'Protection against inflation',
          'it-IT': 'Protezione dall’inflazione'
        }
      },
      {
        childID: 'job',
        id: 'wealth',
        text: {
          'en-EN': 'Increasing the wealth',
          'it-IT': 'Accrescere il patrimonio'
        }
      },
      {
        childID: 'job',
        id: 'retirement',
        text: {
          'en-EN': 'Supplementing retirement',
          'it-IT': 'Integrare la pensione'
        }
      },
      {
        childID: 'job',
        id: 'expense',
        text: {
          'en-EN': 'A planned expense',
          'it-IT': 'Una spesa pianificata'
        }
      }
    ],
    id: 'main_goal',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is your main investment goal?',
      'it-IT': 'Qual è il tuo principale obiettivo di investimento?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'public',
        id: 'employee',
        text: {
          'en-EN': 'Employee',
          'it-IT': 'Lavoratore dipendente'
        }
      },
      {
        childID: 'public',
        id: 'autonomous',
        text: {
          'en-EN': 'Self-employed worker',
          'it-IT': 'Lavoratore autonomo'
        }
      },
      {
        childID: 'public',
        id: 'manager',
        text: {
          'en-EN': 'Entrepreneur / professional / manager',
          'it-IT': 'Imprenditore / professionista / dirigente'
        }
      },
      {
        childID: 'public',
        id: 'retired',
        text: {
          'en-EN': 'Retired',
          'it-IT': 'Pensionato'
        }
      },
      {
        childID: 'public',
        id: 'unemployed',
        text: {
          'en-EN': 'Unemployed',
          'it-IT': 'Disoccupato'
        }
      }
    ],
    id: 'job',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is your current job situation?',
      'it-IT': 'Quale è la tua attuale situazione lavorativa?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'funds_origin',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'funds_origin',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'public',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'Do you hold public administration/public body/other offices?',
      'it-IT':
        'Ricopri cariche di pubblica amministrazione/enti pubblici/altre?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'percentage',
        id: 'income',
        text: {
          'en-EN': 'Income from economic activity performed',
          'it-IT': 'Reddito da attività economica svolta'
        }
      },
      {
        childID: 'percentage',
        id: 'job_income',
        text: {
          'en-EN': 'Income from pension/employee treatment',
          'it-IT': 'Reddito da trattamento pensionistico/dipendente'
        }
      },
      {
        childID: 'percentage',
        id: 'inherited',
        text: {
          'en-EN': 'Bequest or disposal of inherited property',
          'it-IT': 'Lascito o dismissione di beni ereditari'
        }
      },
      {
        childID: 'percentage',
        id: 'capital_income',
        text: {
          'en-EN': 'Capital income',
          'it-IT': 'Redditi di capitale'
        }
      },
      {
        childID: 'percentage',
        id: 'real_estate',
        text: {
          'en-EN': 'Real estate returns',
          'it-IT': 'Rendite immobiliari'
        }
      },
      {
        childID: 'percentage',
        id: 'other',
        text: {
          'en-EN': 'Other annuities',
          'it-IT': 'Altre rendite'
        }
      },
      {
        childID: 'percentage',
        id: 'resources',
        text: {
          'en-EN': 'Resources belonging to the Effective Owner',
          'it-IT': 'Risorse appartenenti al Titolare Effettivo'
        }
      }
    ],
    id: 'funds_origin',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is the origin of your main income and wealth funds?',
      'it-IT':
        'Qual è l’origine dei tuoi fondi reddituali e patrimoniali principali?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: '',
        id: 'less_5',
        text: {
          'en-EN': 'Less than or equal to 5%',
          'it-IT': 'Minore o uguale al 5%'
        }
      },
      {
        childID: '',
        id: 'less_10',
        text: {
          'en-EN': 'Between 5% and 10%',
          'it-IT': 'Tra il 5% e il 10%'
        }
      },
      {
        childID: '',
        id: 'less_30',
        text: {
          'en-EN': 'Between 10% and 30%',
          'it-IT': 'Tra il 10% e il 30%'
        }
      },
      {
        childID: '',
        id: 'over_30',
        text: {
          'en-EN': 'Over 30%',
          'it-IT': 'Oltre il 30%'
        }
      }
    ],
    id: 'percentage',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN':
        'What percentage of your savings would you like to invest in Hodlie?',
      'it-IT':
        'Qual è la percentuale dei tuoi risparmi che vorresti investire su Hodlie?'
    },
    type: 'multipleChoice'
  }
];

export const riskQuestions = [
  {
    answers: [
      {
        childID: 'risky_products',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'risky_products',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'risky_products',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'risky_products',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'risky_products',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'risk',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'I am willing to take significant risks to seize new profitable investment opportunities.',
      'it-IT':
        'Sono disposto a correre rischi significativi per cogliere nuove opportunità di investimento.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'high_tollerance',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'high_tollerance',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'high_tollerance',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'high_tollerance',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'high_tollerance',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'risky_products',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'My preference falls on financial instruments with a higher potential return, even if they carry a higher level of risk.',
      'it-IT':
        'La mia preferenza ricade su strumenti finanziari con un potenziale rendimento più elevato, anche se comportano un livello di rischio superiore.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'value',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'value',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'value',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'value',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'value',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'high_tollerance',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'During downturns in the financial markets, I am able to tolerate substantial losses on my investment portfolio.',
      'it-IT':
        'In caso di fasi di ribasso dei mercati finanziari, sono in grado di tollerare perdite consistenti sul mio portafoglio di investimenti.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'stress',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'stress',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'stress',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'stress',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'stress',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'value',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'I tend to maintain my investment positions even in the presence of a temporary decrease in their market value.',
      'it-IT':
        'Tendo a mantenere le mie posizioni di investimento anche in presenza di una temporanea diminuzione del loro valore di mercato.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'diversify',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'diversify',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'diversify',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'diversify',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'diversify',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'stress',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'Investments do not represent a source of psychological stress that would lead me to monitor their performance on a daily basis.',
      'it-IT':
        'Gli investimenti non rappresentano una fonte di stress psicologico tale da indurmi a monitorarne quotidianamente l’andamento.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'planning',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'planning',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'planning',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'planning',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'planning',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'diversify',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'I am inclined to diversify my investment portfolio by allocating capital across a wide range of assets, including high risk instruments.',
      'it-IT':
        'Sono propenso a diversificare il mio portafoglio di investimenti allocando capitali in una vasta gamma di asset, inclusi strumenti ad elevata rischiosità.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'crypto',
        id: '1',
        text: {
          'en-EN': 'Strongly Disagree',
          'it-IT': "Per niente D'accordo"
        }
      },
      {
        childID: 'crypto',
        id: '2',
        text: {
          'en-EN': 'Somewhat Disagree',
          'it-IT': 'Per lo più in disaccordo'
        }
      },
      {
        childID: 'crypto',
        id: '3',
        text: {
          'en-EN': 'Neither Agree nor Disagree',
          'it-IT': 'Né d’accordo né in disaccordo'
        }
      },
      {
        childID: 'crypto',
        id: '4',
        text: {
          'en-EN': 'Somewhat Agree',
          'it-IT': "Per lo più d'accordo"
        }
      },
      {
        childID: 'crypto',
        id: '5',
        text: {
          'en-EN': 'Strongly Agree',
          'it-IT': "Completamente d'accordo"
        }
      }
    ],
    id: 'planning',
    noSkip: true,
    titleItem: 0,
    question: {
      'en-EN':
        'When planning my investments, I tend to focus on a long-term time horizon, accepting short-term fluctuations in value.',
      'it-IT':
        'Nel pianificare i miei investimenti, tendo a focalizzarmi su un orizzonte temporale di lungo periodo, accettando oscillazioni di valore nel breve termine.'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'time',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'main_reason',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'crypto',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'Have you ever invested in crypto?',
      'it-IT': 'Hai mai investito in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'blockchain',
        id: '<6m',
        text: {
          'en-EN': 'Less than 6 months',
          'it-IT': 'Meno di 6 mesi'
        }
      },
      {
        childID: 'blockchain',
        id: '<1a',
        text: {
          'en-EN': 'From 6 months to 1 year',
          'it-IT': 'Da 6 mesi a 1 anno'
        }
      },
      {
        childID: 'blockchain',
        id: '>1a',
        text: {
          'en-EN': 'More than 1 year',
          'it-IT': 'Più di 1 anno'
        }
      }
    ],
    id: 'time',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'How long have you been investing in cryptocurrencies?',
      'it-IT': 'Da quanto tempo investi in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'main_reason',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'main_reason',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'blockchain',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'Do you know what a blockchain is and how it works?',
      'it-IT': "Sai cos'è una blockchain e come funziona?"
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'job',
        id: 'diversification',
        text: {
          'en-EN': 'Portfolio diversification',
          'it-IT': 'Diversificazione del portafoglio'
        }
      },
      {
        childID: 'job',
        id: 'performance',
        text: {
          'en-EN': 'High performance potential',
          'it-IT': 'Alto potenziale di rendimento'
        }
      },
      {
        childID: 'job',
        id: 'technology',
        text: {
          'en-EN': 'Interest in technology',
          'it-IT': 'Interesse per la tecnologia'
        }
      },
      {
        childID: 'job',
        id: 'other',
        text: {
          'en-EN': 'Other motivations',
          'it-IT': 'Altre motivazioni'
        }
      }
    ],
    id: 'main_reason',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is your main motivation for investing in cryptocurrency?',
      'it-IT':
        'Qual è la tua principale motivazione per investire in criptovalute?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'public',
        id: 'employee',
        text: {
          'en-EN': 'Employee',
          'it-IT': 'Lavoratore dipendente'
        }
      },
      {
        childID: 'public',
        id: 'autonomous',
        text: {
          'en-EN': 'Self-employed worker',
          'it-IT': 'Lavoratore autonomo'
        }
      },
      {
        childID: 'public',
        id: 'manager',
        text: {
          'en-EN': 'Entrepreneur / professional / manager',
          'it-IT': 'Imprenditore / professionista / dirigente'
        }
      },
      {
        childID: 'public',
        id: 'retired',
        text: {
          'en-EN': 'Retired',
          'it-IT': 'Pensionato'
        }
      },
      {
        childID: 'public',
        id: 'unemployed',
        text: {
          'en-EN': 'Unemployed',
          'it-IT': 'Disoccupato'
        }
      }
    ],
    id: 'job',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is your current job situation?',
      'it-IT': 'Quale è la tua attuale situazione lavorativa?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'funds_origin',
        id: 'yes',
        text: {
          'en-EN': 'Yes',
          'it-IT': 'Sì'
        }
      },
      {
        childID: 'funds_origin',
        id: 'no',
        text: {
          'en-EN': 'No',
          'it-IT': 'No'
        }
      }
    ],
    id: 'public',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'Do you hold public administration/public body/other offices?',
      'it-IT':
        'Ricopri cariche di pubblica amministrazione/enti pubblici/altre?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: 'percentage',
        id: 'income',
        text: {
          'en-EN': 'Income from economic activity performed',
          'it-IT': 'Reddito da attività economica svolta'
        }
      },
      {
        childID: 'percentage',
        id: 'job_income',
        text: {
          'en-EN': 'Income from pension/employee treatment',
          'it-IT': 'Reddito da trattamento pensionistico/dipendente'
        }
      },
      {
        childID: 'percentage',
        id: 'inherited',
        text: {
          'en-EN': 'Bequest or disposal of inherited property',
          'it-IT': 'Lascito o dismissione di beni ereditari'
        }
      },
      {
        childID: 'percentage',
        id: 'capital_income',
        text: {
          'en-EN': 'Capital income',
          'it-IT': 'Redditi di capitale'
        }
      },
      {
        childID: 'percentage',
        id: 'real_estate',
        text: {
          'en-EN': 'Real estate returns',
          'it-IT': 'Rendite immobiliari'
        }
      },
      {
        childID: 'percentage',
        id: 'other',
        text: {
          'en-EN': 'Other annuities',
          'it-IT': 'Altre rendite'
        }
      },
      {
        childID: 'percentage',
        id: 'resources',
        text: {
          'en-EN': 'Resources belonging to the Effective Owner',
          'it-IT': 'Risorse appartenenti al Titolare Effettivo'
        }
      }
    ],
    id: 'funds_origin',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN': 'What is the origin of your main income and wealth funds?',
      'it-IT':
        'Qual è l’origine dei tuoi fondi reddituali e patrimoniali principali?'
    },
    type: 'multipleChoice'
  },
  {
    answers: [
      {
        childID: '',
        id: 'less_5',
        text: {
          'en-EN': 'Less than or equal to 5%',
          'it-IT': 'Minore o uguale al 5%'
        }
      },
      {
        childID: '',
        id: 'less_10',
        text: {
          'en-EN': 'Between 5% and 10%',
          'it-IT': 'Tra il 5% e il 10%'
        }
      },
      {
        childID: '',
        id: 'less_30',
        text: {
          'en-EN': 'Between 10% and 30%',
          'it-IT': 'Tra il 10% e il 30%'
        }
      },
      {
        childID: '',
        id: 'over_30',
        text: {
          'en-EN': 'Over 30%',
          'it-IT': 'Oltre il 30%'
        }
      }
    ],
    id: 'percentage',
    noSkip: true,
    titleItem: 1,
    question: {
      'en-EN':
        'What percentage of your savings would you like to invest in Hodlie?',
      'it-IT':
        'Qual è la percentuale dei tuoi risparmi che vorresti investire su Hodlie?'
    },
    type: 'multipleChoice'
  }
];
