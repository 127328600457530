import React, { useContext } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ScatterChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { breakpoints, getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Sentiment as textLang } from 'staticData/languages';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  ScatterChart,
  CanvasRenderer,
  LegendComponent
]);

const SentimentChart = ({
  data,
  clickPoint,
  selectedPoint,
  titleArray,
  centroid,
  hasData
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const getPointColor = x => {
    let percent;
    const opacity = 0.4;
    if (x >= -0.3 && x <= 0.3) {
      const percent = x + 0.5;
      const red = Math.floor(254 + percent * (229 - 254));
      const green = Math.floor(206 + percent * (255 - 206));
      const blue = Math.floor(5 + percent * (0 - 5));
      return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    } else {
      percent = (x - -1) / (1 - -1);
      return `rgba(${255 * (1 - percent)}, ${255 * percent}, 0, ${opacity})`;
    }
  };

  const getPointSize = y => {
    return 10 + Math.round(y * 20);
  };

  const getOption = (scatterData, titleArray) => ({
    animation: true,
    animationDuration: 1000,
    animationEasing: 'cubicOut',
    animationDurationUpdate: 1000,
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'none'
      },
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-1100') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: function (params) {
        if (params.seriesIndex === 0 || params.seriesIndex === 4)
          return (
            '<b>' +
            titleArray[
              params.seriesIndex === 4 && selectedPoint
                ? selectedPoint.index
                : params.dataIndex
            ] +
            '</b><br/>' +
            textLang.sentiment[lang] +
            ': ' +
            params.data[0].toFixed(2) +
            '<br/>' +
            textLang.impact[lang] +
            ': ' +
            params.data[1].toFixed(2)
          );
        else if (params.seriesIndex === 3) {
          return (
            '<b>' +
            textLang.generalScore[lang] +
            '</b><br/>' +
            textLang.sentiment[lang] +
            ': ' +
            params.data[0].toFixed(2) +
            '<br/>' +
            textLang.impact[lang] +
            ': ' +
            params.data[1].toFixed(2)
          );
        }
      }
    },
    xAxis: {
      name: textLang.sentiment[lang],
      nameLocation: 'middle',
      nameGap: 20,
      min: -1,
      max: 1,
      axisLabel: {
        color: getColor('gray-600')
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-600')
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-1000'),
          width: 1
        }
      }
    },
    yAxis: {
      name: textLang.impact[lang],
      nameLocation: 'middle',
      nameGap: 30,
      min: 0,
      max: 1,
      axisLabel: {
        color: getColor('gray-600')
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-600')
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-1000'),
          width: 1
        }
      }
    },
    series: [
      {
        data: scatterData,
        symbolSize: params => getPointSize(params[1]),
        type: 'scatter',
        itemStyle: {
          color: params => getPointColor(params.data[0])
        }
      },
      {
        data: [
          [centroid[0], 0],
          [centroid[0], 1]
        ],
        type: 'line',
        lineStyle: {
          color: getColor('gray-1000'),
          type: 'dashed'
        },
        symbol: 'none',
        z: 1
      },
      {
        data: [
          [-1, centroid[1]],
          [1, centroid[1]]
        ],
        type: 'line',
        lineStyle: {
          color: getColor('gray-1000'),
          type: 'dashed'
        },
        symbol: 'none',
        z: 1
      },
      {
        data: [centroid],
        type: 'scatter',
        symbolSize: 5,
        itemStyle: {
          color: getColor('primary')
        },
        z: 2
      },
      {
        data: selectedPoint ? [selectedPoint.xy] : [],
        type: 'scatter',
        symbolSize: params => getPointSize(params[1]) + 3,
        itemStyle: {
          color: params => getPointColor(params.data[0]),
          borderColor: '#FFFFFF',
          borderWidth: 2
        },
        z: 3
      }
    ],
    grid: {
      left: '6%',
      right: '6%',
      top: '6%',
      bottom: '6%',
      containLabel: true
    }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(hasData ? data : [], titleArray)}
      style={{ height: '18.75rem' }}
      onEvents={{
        click: function (params) {
          if (params.seriesIndex === 0) {
            clickPoint(
              params.dataIndex,
              window.innerWidth < breakpoints['sm'] ? false : true
            );
          }
        }
      }}
    />
  );
};

SentimentChart.propTypes = {
  data: PropTypes.array.isRequired,
  clickPoint: PropTypes.bool.isRequired,
  selectedPoint: PropTypes.object.isRequired,
  titleArray: PropTypes.string.isRequired,
  centroid: PropTypes.array.isRequired,
  hasData: PropTypes.bool.isRequired
};

export default SentimentChart;
