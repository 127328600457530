import React, { useContext, useState, /*useEffect,*/ useRef } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import 'assets/scss/custom/form.scss';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { Form as textLang } from 'staticData/languages';
import { Link } from 'react-router-dom';
import { titlesForm, riskProfiles, riskQuestions } from './riskQuestions';
import { breakpoints } from 'helpers/utils';
import { trackEvent } from 'services/externals/mixpanel';

const index = () => {
  const {
    config: { lang, savedRiskForm }
    // setConfig
  } = useContext(AppContext);

  const queryParameters = new URLSearchParams(window.location.search);
  const typeUrl = queryParameters.get('type');

  const isSavedForm =
    Object.keys(savedRiskForm).length > 0 &&
    !savedRiskForm.ended &&
    typeUrl !== 'restart';

  const questions = riskQuestions;
  const [currentQuestion, setCurrentQuestion] = useState(
    isSavedForm ? savedRiskForm.currentQuestion : riskQuestions[0]
  );
  const [historyArray, setHistoryArray] = useState(
    isSavedForm ? savedRiskForm.historyArray : []
  );
  const [endForm, setEndForm] = useState(false);
  const [formHistory, setFormHistory] = useState(
    isSavedForm ? savedRiskForm.formHistory : {}
  );
  const [loading, setLoading] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(
    isSavedForm ? savedRiskForm.questionNumber : 0
  );
  const [riskLevel, setRiskLevel] = useState();
  const historyRef = useRef(isSavedForm ? savedRiskForm : {});

  const hadleBack = () => {
    if (questionNumber > 0) {
      // save data
      setQuestionNumber(questionNumber - 1);
      historyRef.current['questionNumber'] = questionNumber - 1;
      delete formHistory[currentQuestion.id];
      historyRef.current['formHistory'] = formHistory;
      setCurrentQuestion(historyArray[historyArray.length - 1]);
      historyRef.current['currentQuestion'] =
        historyArray[historyArray.length - 1];
      historyArray.pop();
      historyRef.current['historyArray'] = historyArray;
    }
  };

  const setAnswers = async form => {
    trackEvent('Risk form', {
      event: 'end'
    });
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'setRiskForm',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        form: form
      }
    });
    if (resp.validResponse) {
      setRiskLevel(resp.data.risk_level);
      historyRef.current['ended'] = true;
      setEndForm(true);
    }
  };

  const setAnswer = async (question, answer) => {
    var localFormHostory = { ...formHistory };
    if (!currentQuestion.noHistory) {
      localFormHostory[question] = answer.text ? answer.id : answer;
      setFormHistory(localFormHostory);
      // save data
      historyRef.current['formHistory'] = localFormHostory;
    }
    if (answer.childID !== '') {
      const newQuestion = questions.find(o => o.id === answer.childID);
      // save data
      historyRef.current['questionNumber'] = questionNumber + 1;
      historyRef.current['currentQuestion'] = newQuestion;
      historyRef.current['historyArray'] = [...historyArray, currentQuestion];
      setQuestionNumber(questionNumber + 1);
      setCurrentQuestion(newQuestion);
      setHistoryArray([...historyArray, currentQuestion]);
      window.scrollTo(0, 0);
    } else {
      setLoading(true);
      await setAnswers(localFormHostory);
      setLoading(false);
    }
  };

  const getRiskLevelInfo = () => {
    if (riskLevel <= 4) return riskProfiles[0];
    else if (riskLevel <= 7) return riskProfiles[1];
    else return riskProfiles[2];
  };

  // TODO
  // useEffect(() => {
  //   return () => {
  //     // save data to local storage for later
  //     setConfig('savedRiskForm', historyRef.current);
  //   };
  // }, []);

  return (
    <>
      <Row className="mb-2">
        <Card
          className="px-0"
          style={{
            boxShadow: 'unset'
          }}
        >
          <Card.Header
            className="position-relative"
            style={{
              backgroundColor: 'var(--falcon-body-bg)'
            }}
          >
            <h6 className="text-600 my-3 text-center">
              {riskLevel ? (
                ''
              ) : (
                <>{titlesForm[currentQuestion.titleItem || 0][lang]}</>
              )}
            </h6>
          </Card.Header>
        </Card>
      </Row>

      {loading ? (
        <div style={{ padding: '15%' }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      ) : !endForm ? (
        <>
          <Row className="g-4 justify-content-center">
            <Row className="mb-1 mt-3 my-sm-5 justify-content-center">
              <h3 className="text-center">
                {currentQuestion.id === 'continue'
                  ? textLang.continueTitleNoBot[lang]
                  : currentQuestion.question[lang]}
              </h3>
              {currentQuestion.id === 'continue' && (
                <Col md={8} className="">
                  <h5
                    className="text-center d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question[lang]
                    }}
                  ></h5>
                  <h6
                    className="text-center d-sm-none"
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question[lang]
                    }}
                  ></h6>
                </Col>
              )}
            </Row>
            {currentQuestion.type === 'multipleChoice' &&
              currentQuestion.answers.map((answer, index) => (
                <Col
                  key={index}
                  lg={4}
                  xxl={4}
                  onClick={() => setAnswer(currentQuestion.id, answer)}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    className={
                      'px-4 rounded-3 border position-relative hover-bgImg-0 hover-bg-custom-300'
                    }
                    style={{
                      backgroundImage: 'var(--falcon-line-chart-gradient)',
                      lineHeight:
                        window.innerWidth < breakpoints.sm ? '60px' : '120px',
                      height:
                        window.innerWidth < breakpoints.sm ? '60px' : '120px',
                      textAlign: 'center'
                    }}
                  >
                    <h3
                      className="fs-0 fw-semi-bold mb-2 text-center"
                      style={{
                        lineHeight: '1.5',
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }}
                    >
                      {index + 1}. {answer.text[lang]}
                    </h3>
                  </div>
                </Col>
              ))}
          </Row>
          {questionNumber > 0 && (
            <Row className="g-4 justify-content-start mt-4">
              <Col xs={6} sm={3} className="">
                <div className="d-flex justify-content-center">
                  <button className="btn" onClick={() => hadleBack()}>
                    {textLang.back[lang]}
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : (
        riskLevel && (
          <Row className="g-4 mt-4">
            <Col className="">
              <div className="text-center justify-content-center">
                <h2 className="mb-3">{getRiskLevelInfo().title[lang]}</h2>
                <h5 className="text-center mb-3">
                  {getRiskLevelInfo().descr[lang]}
                </h5>
                <Button
                  variant="primary"
                  as={Link}
                  to="/invest?type=portfolio"
                  // onClick={() => handleClickButton()}
                >
                  {textLang.backToPort[lang]}
                </Button>
              </div>
            </Col>
          </Row>
        )
      )}
    </>
  );
};

export default index;
