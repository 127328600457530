import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormTitle = ({ icon, title, subtitle }) => {
  return (
    <Flex className="mb-4 mt-4">
      <span className="fa-stack me-2 ms-n1">
        <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon={icon}
          className="fa-inverse fa-stack-1x text-primary"
        />
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
        </h5>
        <p className="mb-0">{subtitle}</p>
      </div>
    </Flex>
  );
};

FormTitle.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default FormTitle;
