import React from 'react';
import PropTypes from 'prop-types';

const SubSectionHeader = ({ title, fontColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: '0.3em'
      }}
    >
      <h5
        style={{
          color: 'var(--falcon-500)',
          whiteSpace: 'nowrap'
        }}
      >
        {title}
      </h5>
      <hr
        style={{
          color: fontColor || 'var(--falcon-500)',
          width: '95%',
          marginLeft: '1%'
        }}
      />
    </div>
  );
};

SubSectionHeader.propTypes = {
  title: PropTypes.string,
  fontColor: PropTypes.string
};

export default SubSectionHeader;
