import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormControl,
  Spinner,
  Row,
  Col,
  InputGroup
} from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import {
  RegistrationForm as textLang,
  AddPWDFormSignIn
} from 'staticData/languages';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { gtm_event_userRegistered } from 'services/externals/google/googleTagManager';
import { hs_sendMailAddress } from 'services/externals/hubspot';
import { identifyMixpanelUser, trackEvent } from 'services/externals/mixpanel';
import { toltSignup } from 'services/externals/tolt';
import 'assets/scss/custom/login.scss';

const RegistrationForm = ({ hasLabel, getInfo }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  // GET referral code if in URL
  const queryParameters = new URLSearchParams(window.location.search);
  const referralInURL = queryParameters.get('ref') ? true : false;
  const utm_campaign = queryParameters.get('utm_campaign')
    ? queryParameters.get('utm_campaign').trim()
    : '';
  const routerHistory = useHistory();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    externalReferral: referralInURL ? queryParameters.get('ref') : '',
    isAccepted: false,
    marketingConsense: false
  });
  const [validMail, setValidMail] = useState(true);
  const [validReferral, setValidReferral] = useState(true);
  const [mailFeedback, setMailFeedback] = useState();
  const [validPWD, setValidPWD] = useState(true);
  const [validRepeatPWD, setValidRepeatPWD] = useState(true);
  const [PWDFeedback, setPWDFeedback] = useState();
  const [pwdVisible, setPwdVisible] = useState(false);
  const [repeatPWDFeedback, setRepeatPWDFeedback] = useState();
  const [referralFeedback, setReferralFeedback] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const location = useLocation();
  const fullPath = location.pathname + location.search;

  const registerUser = async recaptcha_token => {
    let form = new FormData();
    form.append('email', formData.email.trim());
    form.append('password', formData.password.trim());
    form.append('password_confirm', formData.repeatPassword.trim());
    form.append('mkt_email', formData.marketingConsense);
    form.append('referral', formData.externalReferral.trim());
    form.append('utm_campaign', utm_campaign);
    form.append('recaptcha_token', recaptcha_token);
    form.append('tolt_referral', window.tolt_referral || '');
    let addUserResponse = await endpointInterface(
      lang,
      'backend',
      'registration',
      'post',
      true,
      form
    );
    if (addUserResponse.validResponse) {
      let data = await getInfo(true);
      // Send the user_registered GTM event
      gtm_event_userRegistered(data?.tracking_id || null);
      // Send mail address to hubspot
      hs_sendMailAddress(addUserResponse.data?.email || '', fullPath);
      // send event and identify in mixpanel
      trackEvent('signup', {
        method: 'mail',
        mail: addUserResponse.data?.email || formData.email
      });
      identifyMixpanelUser(
        addUserResponse.data?.tracking_id || 0,
        addUserResponse.data?.email || formData.email
      );
      // send registration data to tolt
      toltSignup(addUserResponse.data?.email || formData.email);
      routerHistory.push('/');
    } else {
      var check = true;
      setLoadingButton(false);
      if (addUserResponse.errorID === 'mailAlreadyEntered') {
        setMailFeedback(addUserResponse.responseMessage);
        setValidMail(false);
        check = false;
      }
      if (check) {
        toast.error(addUserResponse.responseMessage, { closeButton: false });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    }
  };

  const sendGoogleToken = async response => {
    let token = response['credential'];
    let form = new FormData();
    form.append('token', token);
    form.append('referral', formData.externalReferral.trim());
    form.append('utm_campaign', utm_campaign);
    form.append('tolt_referral', window.tolt_referral || '');
    let loginResponse = await endpointInterface(
      lang,
      'backend',
      'googleLogin',
      'post',
      true,
      form
    );
    let userWasRegistered = false;
    if (
      loginResponse.data.already_registered != undefined &&
      loginResponse.data.already_registered === true
    )
      userWasRegistered = true;
    if (loginResponse.validResponse) {
      let data = await getInfo(true);
      // Send mail address to hubspot
      hs_sendMailAddress(loginResponse.data?.email || '', fullPath);
      if (userWasRegistered) {
        // identify user in mixpanel
        identifyMixpanelUser(
          loginResponse.data?.tracking_id || 0,
          loginResponse.data?.email || formData.email
        );
        routerHistory.push('/');
      } else {
        // Send the user_registered GTM event
        gtm_event_userRegistered(data?.tracking_id || null);
        // send event and identify in mixpanel
        trackEvent('signup', {
          method: 'google',
          mail: loginResponse.data?.email || formData.email
        });
        identifyMixpanelUser(
          loginResponse.data?.tracking_id || 0,
          loginResponse.data?.email || formData.email
        );
        // send registration data to tolt
        toltSignup(loginResponse.data?.email || formData.email);
        routerHistory.push('/');
      }
    } else {
      toast.error(loginResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoadingButton(true);
    setValidMail(true);
    setValidReferral(true);
    setMailFeedback(textLang.mailFormat[lang]);
    setReferralFeedback(textLang.referral[lang]);
    setValidPWD(true);
    setValidRepeatPWD(true);
    setPWDFeedback(AddPWDFormSignIn.pwdErr[lang]);
    setRepeatPWDFeedback(AddPWDFormSignIn.passNoMatch[lang]);
    let minLen = formData.password.length < 8;
    let num = formData.password.search(/\d/) === -1;
    let lowerCase = formData.password.search(/[a-z]/) == -1;
    let upperCase = formData.password.search(/[A-Z]/) == -1;
    var mailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var valid = true;
    if (minLen || num || lowerCase || upperCase) {
      setValidPWD(false);
      valid = false;
    }
    if (formData.password !== formData.repeatPassword) {
      setValidRepeatPWD(false);
      valid = false;
    }
    if (!mailRE.test(formData.email)) {
      setValidMail(false);
      valid = false;
    }
    if (
      !formData.externalReferral.match('^[A-Za-z0-9 ]+$') &&
      formData.externalReferral.trim() != ''
    ) {
      setValidReferral(false);
      valid = false;
    }
    if (valid) {
      // Generate Recaptcha Token & Register
      window.grecaptcha.enterprise.ready(async () => {
        const recaptcha_token = await window.grecaptcha.enterprise.execute(
          '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
          { action: 'registration' }
        );

        registerUser(recaptcha_token);
      });
    } else setLoadingButton(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <h3>{textLang.title[lang]}</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>{textLang.email[lang]}</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? textLang.email[lang] : ''}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="text"
            isInvalid={!validMail}
          />
          <FormControl.Feedback type="invalid">
            {mailFeedback}
          </FormControl.Feedback>
        </Form.Group>

        {hasLabel && <Form.Label>{AddPWDFormSignIn.pwd[lang]}</Form.Label>}
        <InputGroup className="mb-3">
          <Form.Control
            placeholder={!hasLabel ? AddPWDFormSignIn.pwd[lang] : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={pwdVisible ? 'text' : 'password'}
            isInvalid={!validPWD}
          />
          <InputGroup.Text
            className="eye-password"
            style={{ width: '55px' }}
            onClick={() => setPwdVisible(pwdVisible ? false : true)}
          >
            <FontAwesomeIcon icon={pwdVisible ? 'eye' : 'eye-slash'} />
          </InputGroup.Text>
          <FormControl.Feedback type="invalid">
            {PWDFeedback}
          </FormControl.Feedback>
        </InputGroup>

        {hasLabel && (
          <Form.Label>{AddPWDFormSignIn.repeatPwd[lang]}</Form.Label>
        )}
        <InputGroup className="mb-3">
          <Form.Control
            placeholder={!hasLabel ? AddPWDFormSignIn.repeatPwd[lang] : ''}
            value={formData.repeatPassword}
            name="repeatPassword"
            onChange={handleFieldChange}
            type={pwdVisible ? 'text' : 'password'}
            isInvalid={!validRepeatPWD}
          />
          <InputGroup.Text
            className="eye-password"
            style={{ width: '55px' }}
            onClick={() => setPwdVisible(pwdVisible ? false : true)}
          >
            <FontAwesomeIcon icon={pwdVisible ? 'eye' : 'eye-slash'} />
          </InputGroup.Text>
          <FormControl.Feedback type="invalid">
            {repeatPWDFeedback}
          </FormControl.Feedback>
        </InputGroup>

        <Form.Group className="mb-3">
          {referralInURL && validReferral ? (
            <Form.Label>
              <FontAwesomeIcon icon={'check-circle'} className="me-1" />
              Referral Code {textLang.insert[lang]}
            </Form.Label>
          ) : (
            <>
              {hasLabel && (
                <Form.Label>
                  Referral Code ({textLang.optional[lang]})
                </Form.Label>
              )}
              <Form.Control
                placeholder={!hasLabel ? 'Referral' : ''}
                value={formData.externalReferral}
                name="externalReferral"
                onChange={handleFieldChange}
                type="text"
                isInvalid={!validReferral}
              />
              <FormControl.Feedback type="invalid">
                {referralFeedback}
              </FormControl.Feedback>
            </>
          )}
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Check type="checkbox" id="mktCheckbox" className="form-check">
            <Form.Check.Input
              type="checkbox"
              name="marketingConsense"
              checked={formData.marketingConsense}
              onChange={e =>
                setFormData({
                  ...formData,
                  marketingConsense: e.target.checked
                })
              }
            />
            <Form.Check.Label
              className="form-label"
              dangerouslySetInnerHTML={{
                __html: textLang.mktAccept[lang]
              }}
            ></Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="acceptCheckbox"
            className="form-check"
          >
            <Form.Check.Input
              type="checkbox"
              name="isAccepted"
              checked={formData.isAccepted}
              onChange={e =>
                setFormData({
                  ...formData,
                  isAccepted: e.target.checked
                })
              }
            />
            <Form.Check.Label
              className="form-label"
              dangerouslySetInnerHTML={{
                __html: textLang.accept[lang]
              }}
            ></Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <Form.Group className="mb-4">
          <Flex className="justify-content-center">
            <Button
              className="custom-button"
              type="submit"
              disabled={
                !formData.email ||
                !formData.isAccepted ||
                !formData.password ||
                !formData.repeatPassword ||
                loadingButton
              }
            >
              {textLang.confirm[lang]}
            </Button>
          </Flex>
          {loadingButton ? (
            <Row className="mt-3">
              <Col
                md={6}
                className="gx-1"
                style={{ margin: '0 auto', textAlign: 'center' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </Col>
            </Row>
          ) : null}
        </Form.Group>
        <Flex className="justify-content-center mt-3 mb-3">
          {textLang.or[lang]}
        </Flex>
        <div className="d-flex justify-content-center">
          <GoogleOAuthProvider clientId="963417905135-ho3o0v3h0lurjmgp9kgkdqfunai4k1nm.apps.googleusercontent.com">
            <GoogleLogin
              text="signin_with"
              onSuccess={sendGoogleToken}
              onError={error => toast.error(error, { closeButton: false })}
              width="250px"
              useOneTap
            />
          </GoogleOAuthProvider>
        </div>
      </Form>
    </>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
  getInfo: PropTypes.func
};

export default RegistrationForm;
