import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
// import { rgbaColor } from 'helpers/utils';
import { Link } from 'react-router-dom';
import { MostBots as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { coinColor } from 'staticData/common';
import { coinsData } from 'services/coins/common';
// import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import 'react-toastify/dist/ReactToastify.css';
// import { toast } from 'react-toastify';
import { breakpoints } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

// const colorMap = {
//   gold: rgbaColor('#ffd700'),
//   silver: rgbaColor('#bec7c7'),
//   neutral: rgbaColor('#17B169', 1.0),
//   invested: rgbaColor('#0074d9', 0.7),
//   notInvested: rgbaColor('#0074d9', 0.4)
// };

const CoinTable = ({
  dataReady,
  walletCoinMap,
  loaded,
  setLoaded,
  parentLoaded,
  exchange = 'binance'
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [dataList, setDataList] = useState([]);
  // const [generalWallet, setGeneralWallet] = useState({});
  // // const [loaded, setLoaded] = useState(false);
  // const [realResponse, setRealResponse] = useState({});

  const columns = [
    {
      accessor: 'name',
      Header: '',
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        className: 'ps-0',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { key, bgColor } = rowData.row.original;
        return (
          <Flex className={classNames('align-items-baseline', {})}>
            <h6 className="mb-0">
              {coinsData[key] ? (
                <img
                  src={coinsData[key].img}
                  alt={key}
                  width="20px"
                  className="me-2"
                />
              ) : (
                bgColor && (
                  <span
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: bgColor,
                      borderRadius: '50%',
                      display: 'inline-block',
                      marginRight: '8px'
                    }}
                  />
                )
              )}
              {key}
            </h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'equivalent',
      Header: '',
      headerProps: {
        className: 'text-900 pe-0',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { value, perc } = rowData.row.original;
        return (
          <Flex
            className={classNames(
              'align-items-baseline justify-content-end',
              {}
            )}
          >
            <h6 className="mb-0">${Number(value).toFixed(2)}</h6>
            <p className="mb-0 ps-1 fs--2">
              ({perc ? Number(perc).toFixed(2) : 0}%)
            </p>
          </Flex>
        );
      }
    }
  ];

  /**
   * Return all exchanges overview, bur use only Binance actually (change in future)
   * @param {*} allocatedAmount
   * @returns
   */
  // const getRealWallet = async allocatedAmount => {
  //   let exchangeInfos;
  //   // exclude to load again the backend call
  //   if (!loaded)
  //     exchangeInfos = await endpointInterface(
  //       lang,
  //       'backend',
  //       'getUserExchangesOverview',
  //       'get',
  //       true,
  //       {},
  //       true
  //     );
  //   // use the correct response (the state if is loaded)
  //   if (loaded) exchangeInfos = realResponse;
  //   else setRealResponse(exchangeInfos);
  //   if (!exchangeInfos.validResponse) {
  //     toast.error(exchangeInfos.responseMessage, {
  //       closeButton: false
  //     });
  //     setTimeout(() => {
  //       toast.dismiss();
  //     }, 5000);
  //     return {};
  //   } else {
  //     // Get only Binance, TODO: get wallet name from context 'visualType'
  //     let realWallet = exchangeInfos.data['Binance'];
  //     let availableAmount = realWallet.amount - allocatedAmount;
  //     let allocatedPerc = (allocatedAmount * 100) / realWallet.amount;
  //     let availablePerc = (availableAmount * 100) / realWallet.amount;
  //     return {
  //       allocated: allocatedAmount ? allocatedAmount.toFixed(2) : 0,
  //       allocatedPerc: availableAmount < 0 ? 100 : allocatedPerc.toFixed(2),
  //       available: availableAmount < 0 ? 0 : availableAmount.toFixed(2),
  //       availablePerc: availableAmount < 0 ? 0 : availablePerc.toFixed(2)
  //     };
  //   }
  // };

  /**
   * Order Coin and get an Array from Object
   * @returns Array of coins
   */
  const orderData = () => {
    // Exclude the total wallet (virtual or real)
    const filteredArray = Object.entries(walletCoinMap[exchange]).filter(
      ([key]) => key !== 'total'
    );
    // Sort array
    const sortedArray = filteredArray.sort((a, b) => b[1] - a[1]);
    const orderedChartData = sortedArray.map(([key, value]) => ({
      key,
      value,
      name: key,
      perc:
        walletCoinMap[exchange]['total'] && value
          ? (value / walletCoinMap[exchange]['total']) * 100
          : 0,
      itemStyle: { color: coinColor[key] }
    }));
    return orderedChartData;
  };

  const initChartData = async () => {
    if (Object.keys(walletCoinMap).includes(exchange)) {
      // Get DataList for coin List under Cake Chart
      let chartCoinsData = orderData();
      setDataList(chartCoinsData);
      // Get data for Cacke Chart
      // let managedWallet = walletCoinMap[exchange].total;
      // let tmpGeneralWallet = await getRealWallet(managedWallet);
      // let tableGeneralWallet = [
      //   {
      //     key: textLang['Not Invested'][lang],
      //     value: tmpGeneralWallet.available,
      //     perc: tmpGeneralWallet.availablePerc,
      //     bgColor: colorMap.notInvested
      //   },
      //   {
      //     key: textLang['Invested'][lang],
      //     value: tmpGeneralWallet.allocated,
      //     perc: tmpGeneralWallet.allocatedPerc,
      //     bgColor: colorMap.invested
      //   }
      // ];
      // setGeneralWallet(tableGeneralWallet);
      if (!loaded) setLoaded(true);
    }
  };

  useEffect(() => {
    try {
      if (parentLoaded && dataReady && !loaded) {
        initChartData();
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [dataReady, walletCoinMap, parentLoaded]);

  return (
    <Card className={'h-100 virtualCard'}>
      {loaded && parentLoaded ? (
        <Card.Body
          className={classNames('', {
            'align-content-center': dataList.length === 0
          })}
        >
          {dataList.length === 0 ? (
            <p
              className="text-700 text-center"
              style={{
                fontSize: '10pt'
              }}
            >
              {textLang.noBotDescription[lang]}
            </p>
          ) : (
            <>
              {/* <h6 className="pb-1">{textLang.wallet[lang]}</h6>

              {generalWallet && generalWallet.length > 0 && (
                <Row className="pb-2">
                  <AdvanceTableWrapper
                    columns={columns}
                    data={[
                      ...generalWallet
                        .filter(row => row.value > 0)
                        .sort((a, b) => b.value - a.value)
                    ]}
                    sortable
                    pagination
                    perPage={10}
                  >
                    <AdvanceTable
                      table
                      headerClassName="bg-40 text-nowrap align-middle d-none extra-class"
                      rowClassName="align-middle white-space-nowrap border-bottom-table"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs-10 mb-0 overflow-hidden'
                      }}
                    />
                  </AdvanceTableWrapper>
                </Row>
              )} */}
              <h6 className="py-1">{textLang.assets[lang]}</h6>

              {(!dataList || dataList.length == 0) && (
                <p
                  className="text-700"
                  style={{
                    fontSize: '10pt'
                  }}
                >
                  <span
                    style={{
                      height: '10px',
                      width: '10px',
                      borderRadius: '50%',
                      display: 'inline-block',
                      marginRight: '8px'
                    }}
                  ></span>
                  {textLang.noAssets[lang]}
                  <Link className="text-decoration-none" to="/invest">
                    {textLang.start[lang]}
                  </Link>
                </p>
              )}
              <div>
                <SimpleBarReact
                  style={{
                    maxHeight: `410px`,
                    width: '100%'
                  }}
                >
                  {dataList && dataList.length > 0 && (
                    <AdvanceTableWrapper
                      columns={columns}
                      data={[
                        ...dataList
                          .filter(row => row.value > 0)
                          .sort((a, b) => b.value - a.value)
                      ]}
                      sortable
                      pagination
                      perPage={10}
                    >
                      <AdvanceTable
                        table
                        headerClassName="bg-40 text-nowrap align-middle d-none extra-class"
                        rowClassName="align-middle white-space-nowrap border-bottom-table"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs-10 mb-0 overflow-hidden'
                        }}
                      />
                    </AdvanceTableWrapper>
                  )}
                </SimpleBarReact>
              </div>
            </>
          )}
        </Card.Body>
      ) : (
        <div style={{ paddingTop: '20%' }}>
          <LoadingSpinner subText=""></LoadingSpinner>
        </div>
      )}
    </Card>
  );
};

CoinTable.propTypes = {
  walletCoinMap: PropTypes.object,
  dataReady: PropTypes.bool.isRequired,
  loaded: PropTypes.bool,
  setLoaded: PropTypes.func,
  parentLoaded: PropTypes.bool,
  exchange: PropTypes.string
};

export default CoinTable;
