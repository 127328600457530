import React, { useState, useContext } from 'react';
import { Card, Row } from 'react-bootstrap';
import PricingDefaultHeader from './PricingDefaultHeader';
import PricingDefaultCard from './PricingDefaultCard';
import AppContext from 'context/Context';
import { getPricing } from 'pages/Pricing/pricingList';

const index = () => {
  const { userInfos } = useContext(AppContext);

  // Calculate current isYearly flag position, default to Year, monthly if a monthly subscription exists
  const currentIsYearly =
    !userInfos?.subscription ||
    !(userInfos && 'recurring_months' in userInfos.subscription) ||
    userInfos?.subscription.recurring_months === 12;

  const [isYearly, setIsYearly] = useState(currentIsYearly);

  const Pricing = getPricing(true, true, true);

  if (isYearly)
    Pricing.map(pricing => {
      pricing.price = pricing.price.filter(item => !item.onlyMonthly);
    });
  else {
    Pricing.map(pricing => {
      pricing.price = pricing.price.filter(item => !item.onlyYearly);
      if (pricing.isYearlyPromo) {
        pricing.price.pop();
        pricing.isYearlyPromo = false;
      }
    });
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="pb-0">
          <Row className="g-0">
            <PricingDefaultHeader
              isYearly={isYearly}
              setIsYearly={setIsYearly}
              disabled={
                userInfos?.pro_tier === 1 &&
                userInfos?.subscription?.recurring_months === 12
              }
            />
            {Pricing.map((pricing, index) => (
              <PricingDefaultCard key={index} pricing={pricing} index={index} />
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default index;
