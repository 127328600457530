import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Card,
  Col,
  Tooltip,
  OverlayTrigger,
  ProgressBar,
  Button
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import IconButton from 'components/common/IconButton';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Rewards as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { ShareReferralOnSocial as shareTextLang } from 'staticData/languages';
import ShareOnSocial from './modals/shareOnSocial';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { getColor } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralModal from './modals/GeneralModal';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import classNames from 'classnames';
import { CanvasRenderer } from 'echarts/renderers';
import 'assets/scss/custom/rewards.scss';
import { ShareOnSocialModal as textLangShare } from 'staticData/languages';
import { trackEvent } from 'services/externals/mixpanel';
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from 'react-share';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const handleTrackEvent = type => {
  trackEvent('Share', {
    type: type
  });
};

const index = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const referral = userInfos.my_referral;
  var defaultTooltipText = textLang.copy[lang];
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const [shareOnSocialModalShow, setShareOnSocialModalShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [referralUsers, setReferralUsers] = useState([]);
  const [redeemedItems, setRedeemedItems] = useState([]);
  const [availableRedeem, setAvailableRedeem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const barVariantMap = {
    HAS_REFERRER: getColor('success'),
    PAID: getColor('success'),
    TRIALING: 'yellow'
  };

  const getDate = dateStr => {
    let date = new Date(dateStr);

    let day = date.getDate(); // giorno del mese
    let month = date.getMonth() + 1; // i mesi in JavaScript iniziano da 0
    let year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const copyReferral = async () => {
    handleTrackEvent('manual copy');
    navigator.clipboard.writeText(referral);
    setTooltipText(textLang.copied[lang]);
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  const getReferralStatus = async () => {
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'referralStatus',
      httpMethod: 'get',
      usingCredentials: true
    });
    if (resp.validResponse) return resp.data;
    return { referrals: [], redeemed_items: [], available_redeems: {} };
  };

  const processData = data => {
    let referrals = data.referrals.slice();
    // Sort the array based on status and created_at
    referrals.sort((a, b) => {
      if (a.status === 'PAID' && b.status !== 'PAID') return -1;
      if (b.status === 'PAID' && a.status !== 'PAID') return 1;
      if (a.status === 'TRIALING' && b.status !== 'TRIALING') return -1;
      if (b.status === 'TRIALING' && a.status !== 'TRIALING') return 1;
      if (a.status === 'REGISTERED' && b.status !== 'REGISTERED') return -1;
      if (b.status === 'REGISTERED' && a.status !== 'REGISTERED') return 1;

      // If status is the same, sort by created_at
      let dateA = new Date(a.created_at);
      let dateB = new Date(b.created_at);
      return dateA - dateB;
    });

    // If data.has_referrer is true, add an object with status: 'referral' at the beginning of the array
    if (data.has_referrer) {
      referrals.unshift({ status: 'HAS_REFERRER' });
    }

    // Complete the array up to 10 elements with status: null
    while (referrals.length < 10) {
      referrals.push({ status: null });
    }

    setReferralUsers(referrals);
    setRedeemedItems(data.redeemed_items);
    setAvailableRedeem(data.available_redeems.DISCOUNT);
  };

  useEffect(async () => {
    processData(await getReferralStatus());
    if (!loaded) {
      setLoaded(true);
    }
  }, [refreshData]);

  const logoSize = 54;
  const shareBody = (
    <>
      <h4 className="text-center font-weight-bold">
        {textLangShare.shareOnSocial[lang]}
      </h4>
      <Row className="mt-4 mb-4 d-flex flex-row align-items-center justify-content-center">
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('whatsapp')}
        >
          <WhatsappShareButton
            url={shareTextLang.shareUrl + referral}
            title={shareTextLang.shareText[lang]}
          >
            <WhatsappIcon size={logoSize} round />
          </WhatsappShareButton>
        </div>
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('telegram')}
        >
          <TelegramShareButton
            url={shareTextLang.shareUrl + referral}
            title={shareTextLang.shareTitle[lang]}
          >
            <TelegramIcon size={logoSize} round />
          </TelegramShareButton>
        </div>
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('facebook')}
        >
          <FacebookShareButton
            url={shareTextLang.shareUrl + referral}
            quote={shareTextLang.shareText[lang]}
            hashtag="#hodlie"
          >
            <FacebookIcon size={logoSize} round />
          </FacebookShareButton>
        </div>
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('email')}
        >
          <EmailShareButton
            url={shareTextLang.shareUrl + referral}
            subject={shareTextLang.shareTitle[lang]}
            body={shareTextLang.shareText[lang]}
            separator={': '}
            hashtag="#hodlie"
          >
            <EmailIcon size={logoSize} round />
          </EmailShareButton>
        </div>
      </Row>
    </>
  );

  return (
    <>
      <GeneralModal
        showModal={showModal}
        setShowModal={setShowModal}
        head={textLang.modalHead[lang]}
        body={
          loaded &&
          availableRedeem && (
            <>
              <p>
                {textLang.modalBody[lang]}{' '}
                <font color={getColor('success')}>
                  {availableRedeem.substring(
                    availableRedeem.length - 2,
                    availableRedeem.length
                  )}
                  %
                </font>{' '}
                {textLang.modalBody1[lang]}
              </p>
              <p>{textLang.modalBody2[lang]}</p>
            </>
          )
        }
        buttonText={textLang.confirm[lang]}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
      <ShareOnSocial
        modalShow={shareOnSocialModalShow}
        setModalShow={setShareOnSocialModalShow}
        body={shareBody}
      ></ShareOnSocial>
      <Row xs={12} className="pb-3 mb-0">
        <Col lg={12} className="pb-0 mb-0">
          <Card className="overflow-hidden">
            <Card.Header>
              <h2
                className="text-800 mb-0"
                dangerouslySetInnerHTML={{
                  __html: textLang.affiliationTitle[lang]
                }}
              ></h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <p>
                    <b>{textLang.firstCardText[lang]}</b>
                    <p>{textLang.firstCardText1[lang]}</p>
                    <ul>
                      <li>
                        <b>{textLang.firstCardLi0bold[lang]}</b>
                        {textLang.firstCardLi0[lang]}
                      </li>
                      <li>
                        <b>{textLang.firstCardLi1bold[lang]}</b>
                        {textLang.firstCardLi1[lang]}
                      </li>
                    </ul>
                    {textLang.firstCardFooter[lang]}
                    <b>{textLang.firstCardFooterBold[lang]}</b>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className="mb-3">
          <Card className="overflow-hidden h-100">
            <Card.Body
              className="justify-content-center p-2 pe-0 align-content-center"
              style={{ flexWrap: 'wrap' }}
            >
              <Row className="justify-content-center pb-sm-5">
                <Col className="d-grid justify-content-center">
                  <Flex className="p-0">
                    <span className="d-flex justify-content-center p-0 m-0">
                      <p
                        className="text-900"
                        style={{ fontSize: '19px', margin: '3px' }}
                      >
                        {textLang.inviteAFriend[lang]}
                      </p>
                    </span>
                  </Flex>
                  <Flex className="mb-2 mt-1">
                    <SoftBadge
                      className=" d-flex align-items-center justify-content-center"
                      bg="info"
                    >
                      <p className="m-0 p-0" style={{ fontSize: '14px' }}>
                        {referral}
                      </p>
                    </SoftBadge>
                    <OverlayTrigger
                      key="copy"
                      placement="top"
                      overlay={
                        <Tooltip id="copyReferral">{tooltipText}</Tooltip>
                      }
                    >
                      <div style={{ marginLeft: '5px', width: 'fit-content' }}>
                        <IconButton
                          style={{ padding: '0 0.35rem' }}
                          variant="outline-info"
                          icon="copy"
                          onClick={copyReferral}
                        ></IconButton>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="share"
                      placement="top"
                      overlay={
                        <Tooltip id="share">
                          {textLang.shareWithFriend[lang]}
                        </Tooltip>
                      }
                    >
                      <div
                        style={{ marginLeft: '5px', width: 'fit-content' }}
                        className="d-sm-none"
                      >
                        <IconButton
                          style={{ padding: '0 0.35rem' }}
                          variant="outline-info"
                          icon="share-alt"
                          onClick={() => setShareOnSocialModalShow(true)}
                        ></IconButton>
                      </div>
                    </OverlayTrigger>
                  </Flex>
                </Col>
              </Row>
              <Row className="d-none d-sm-block">
                <Col className="">{shareBody}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={7} className="mb-3">
          <Card className="overflow-hidden">
            <Card.Body>
              <Row className="pb-3 mb-0">
                <Col
                  dangerouslySetInnerHTML={{
                    __html: textLang.lastCardTextHtml[lang]
                  }}
                ></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row xs={12} className="pb-3 mb-0">
        <Col lg={12}>
          <Card className="overflow-hidden">
            <Card.Header className="mb-0">
              <h5 className="text-800">{textLang.userProgress[lang]}</h5>
            </Card.Header>
            <Card.Body className="py-5">
              <Row>
                <Col>
                  <span
                    style={{
                      position: 'relative',
                      width: '80%',
                      height: '20px'
                    }}
                  >
                    <ProgressBar
                      style={{ height: 30 }}
                      className="shadow-none rounded-4 mb-3"
                    >
                      {loaded &&
                        referralUsers.map(
                          (user, index) =>
                            index < 10 && (
                              <ProgressBar
                                style={{
                                  backgroundColor:
                                    barVariantMap[user.status] ||
                                    getColor('200')
                                }}
                                now={10}
                                key={user.username || 'null_' + String(index)}
                                className={classNames(
                                  'overflow-visible position-relative',
                                  {
                                    // 'rounded-end rounded-pill': index === 0,
                                    // 'rounded-start rounded-pill':
                                    //   index === 9,
                                    'border-end border-white border-2':
                                      index !== 9,
                                    'rounded-0': index !== 9 && index !== 0
                                  }
                                )}
                              />
                            )
                        )}
                      {loaded &&
                        referralUsers.map(
                          (_, index) =>
                            index < 10 && (
                              <>
                                {(index === 1 ||
                                  index === 4 ||
                                  index === 9) && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: `${(index + 1) * 10}%`,
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <b
                                      style={{
                                        position: 'absolute',
                                        top: '-40px',
                                        opacity: redeemedItems.some(
                                          item =>
                                            item.reward_id ===
                                            `REFERRAL${(index + 1) * 10}`
                                        )
                                          ? ''
                                          : redeemedItems.filter(
                                              item =>
                                                item.reward_type === 'REFERRAL'
                                            ).length > 0
                                          ? '0.3'
                                          : '',
                                        color: redeemedItems.some(
                                          item =>
                                            item.reward_id ===
                                            `REFERRAL${(index + 1) * 10}`
                                        )
                                          ? getColor('success')
                                          : ''
                                      }}
                                    >
                                      {(index + 1) * 10}%
                                    </b>
                                    <FontAwesomeIcon
                                      style={{
                                        position: 'absolute',
                                        top: '-20px'
                                      }}
                                      icon={'caret-down'}
                                    />
                                  </div>
                                )}
                                {(index === 3 || index === 6) && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: `${(index + 1) * 10}%`,
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        position: 'absolute',
                                        bottom: '-20px'
                                      }}
                                      icon={'caret-up'}
                                    />
                                    <FontAwesomeIcon
                                      style={{
                                        position: 'absolute',
                                        bottom: '-40px'
                                      }}
                                      color={
                                        referralUsers.filter(
                                          user =>
                                            user.status === 'PAID' ||
                                            user.status === 'HAS_REFERRER'
                                        ).length >=
                                        index + 1
                                          ? getColor('success')
                                          : ''
                                      }
                                      icon={'tshirt'}
                                    />
                                  </div>
                                )}
                              </>
                            )
                        )}
                    </ProgressBar>
                  </span>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center justify-content-sm-end">
              <Button
                variant="success"
                disabled={!availableRedeem}
                onClick={() => setShowModal(true)}
              >
                {textLang.redeem[lang]}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row xs={12} className="pb-3 mb-0">
        <Col lg={4} className="pb-0 mb-3 mb-sm-0">
          <Card className="overflow-hidden">
            <Card.Header className="mb-0">
              <h5 className="text-800">
                {textLang.friends[lang]}{' '}
                <font color={barVariantMap['PAID']}>Explorer/Investor</font>
              </h5>
            </Card.Header>
            <SimpleBarReact style={{ minHeight: '10rem', maxHeight: '10rem' }}>
              <Card.Body className="my-1">
                {loaded &&
                  referralUsers.map(
                    (user, index) =>
                      user.status === 'PAID' && (
                        <Flex
                          key={index}
                          className="justify-content-between fs--1"
                        >
                          <p>{user.username}</p>
                          <p>{getDate(user.created_at)}</p>
                        </Flex>
                      )
                  )}
              </Card.Body>
            </SimpleBarReact>
          </Card>
        </Col>
        <Col lg={4} className="pb-0 mb-3 mb-sm-0">
          <Card className="overflow-hidden">
            <Card.Header className="mb-0">
              <h5 className="text-800">
                {textLang.friends[lang]}{' '}
                <font color={barVariantMap['TRIALING']}>
                  {textLang.free[lang]}
                </font>
              </h5>
            </Card.Header>
            <SimpleBarReact style={{ minHeight: '10rem', maxHeight: '10rem' }}>
              <Card.Body className="my-1">
                {loaded &&
                  referralUsers.map(
                    (user, index) =>
                      user.status === 'TRIALING' && (
                        <Flex
                          key={index}
                          className="justify-content-between fs--1"
                        >
                          <p>{user.username}</p>
                          <p>{getDate(user.created_at)}</p>
                        </Flex>
                      )
                  )}
              </Card.Body>
            </SimpleBarReact>
          </Card>
        </Col>
        <Col lg={4} className="pb-0 mb-0">
          <Card className="overflow-hidden">
            <Card.Header className="mb-0">
              <h5 className="text-800">{textLang.friends[lang]} Basic</h5>
            </Card.Header>
            <SimpleBarReact style={{ minHeight: '10rem', maxHeight: '10rem' }}>
              <Card.Body className="my-1">
                {loaded &&
                  referralUsers.map(
                    (user, index) =>
                      user.status === 'REGISTERED' && (
                        <Flex
                          key={index}
                          className="justify-content-between fs--1"
                        >
                          <p>{user.username}</p>
                          <p>{getDate(user.created_at)}</p>
                        </Flex>
                      )
                  )}
              </Card.Body>
            </SimpleBarReact>
          </Card>
        </Col>
      </Row>
      <Card className="overflow-hidden">
        <Card.Header>
          <h2 className="text-800 mb-3">{textLang.lastParTitle[lang]}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <p>
                <p>{textLang.p1Title[lang]}</p>
                <ul>
                  <li>{textLang.p1_1[lang]}</li>
                  <li>{textLang.p1_3[lang]}</li>
                </ul>
              </p>
              <p>
                <p>{textLang.p2Title[lang]}</p>
                <ul>
                  <li>{textLang.p2_1[lang]}</li>
                  <li>{textLang.p2_2[lang]}</li>
                  <li>{textLang.p2_3[lang]}</li>
                </ul>
              </p>
              <p>
                <p>{textLang.p4Title[lang]}</p>
                <ul>
                  <li>{textLang.p4_1[lang]}</li>
                  <li>{textLang.p4_2[lang]}</li>
                  <li>{textLang.p4_3[lang]}</li>
                  <li>{textLang.p4_4[lang]}</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

index.propTypes = {
  referral: PropTypes.string
};

export default index;
