import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import { MostBots as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { coinNameMap } from 'staticData/common';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart
]);

const getOptions = (coinChartData, holdChartData) => ({
  color: [
    getColor('info'),
    getColor('primary'),
    getColor('secondary'),
    getColor('warning'),
    getColor('success')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    show: coinChartData && coinChartData.length > 0, // if there are no bots don't print the percentage
    transitionDuration: 0,
    formatter: function (params) {
      return `<strong>${
        params.data.key
          ? coinNameMap[params.data.key] + ` (${params.data.key})`
          : params.data.name
      }:</strong> ${Number(params.percent).toFixed(2)}% (${Number(
        params.data.value
      ).toFixed(2)}$)`;
    }
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius:
        coinChartData && coinChartData.length > 0
          ? ['65%', '80%']
          : ['0%', '0%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: coinChartData
    },
    {
      name: 'Marketing',
      type: 'pie',
      radius:
        holdChartData && holdChartData.length > 0
          ? ['88%', '100%']
          : ['0%', '0%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: holdChartData
    }
  ]
});

const MostBotsChart = ({ coinChartData, totalCoins, holdChartData }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const chartRef = useRef(null);

  return (
    <div className="position-relative py-2">
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(coinChartData, holdChartData)}
        style={{ height: '9.5rem' }}
      />
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
          <b>
            {totalCoins !== 0 ? textLang.assets[lang] : textLang.noBots[lang]}
          </b>
        </p>
        <p className="fs-2 mb-0 font-sans-serif fw-medium mt-n2 pt-2">
          {totalCoins !== 0 ? totalCoins : null}
        </p>
      </div>
    </div>
  );
};

MostBotsChart.propTypes = {
  coinChartData: PropTypes.array,
  totalCoins: PropTypes.number,
  holdChartData: PropTypes.array
};

export default MostBotsChart;
