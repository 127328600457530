import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Modal, CloseButton } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Sentiment as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const NewsModal = ({ showModal, setShowModal, head, body, newsLink }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {head && (
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{head}</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base"
            onClick={() => setShowModal(false)}
            variant="white"
            style={{ boxShadow: 'none', backgroundColor: 'transparent' }}
          />
        </Modal.Header>
      )}
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
          <IconButton
            variant="secondary"
            className="me-2 mb-1 "
            icon={'times'}
            iconAlign="left"
            transform="shrink-3"
            onClick={() => setShowModal(false)}
          >
            {textLang.close[lang]}
          </IconButton>
        </Flex>
        <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
          <IconButton
            as={'a'}
            variant="primary"
            className="me-2 mb-1 "
            icon={'external-link-alt'}
            iconAlign="left"
            transform="shrink-3"
            href={newsLink}
            target="__blank"
          >
            {textLang.source[lang]}
          </IconButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

NewsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  head: PropTypes.object,
  body: PropTypes.object,
  newsLink: PropTypes.string
};

export default NewsModal;
