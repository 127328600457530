import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { botGeneralInfo } from 'staticData/common';
import { walletImgRender } from 'services/coins/components';
import { Link } from 'react-router-dom';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';

const BotRow = ({ bot, dynamicCoins }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const initialEquivalent = bot.bot_details.initial_eqv[currencyMap[currency]];

  const addedCapital = bot.added_capital;

  const lastValue = bot.last_stat
    ? Object.keys(dynamicCoins).length
      ? calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
      : bot.last_stat.eqv[currencyMap[currency]]
    : initialEquivalent;

  const performance = lastValue - (initialEquivalent + addedCapital);

  const getBotDataById = id => {
    for (const key in botGeneralInfo) {
      if (botGeneralInfo[key].id === id) {
        return { type: key, name: botGeneralInfo[key].name };
      }
    }
    return 'noBot';
  };

  const getColorAndCaret = num => {
    if (Number(num) < 0) return ['text-danger', 'caret-down'];
    if (Number(num) > 0) return ['text-success', 'caret-up'];
    return ['text-dark', 'minus'];
  };

  const botStatusColor = status => {
    if (status.toUpperCase() === 'ACTIVE') return 'success';
    if (status.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const performanceColorAndCaret = getColorAndCaret(performance);

  return (
    <Row className="mt-3">
      <Col>
        <Card
          as={Link}
          to={'botdetails/' + bot.bot_details.name}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {/* > MOBILE */}
          <Card.Body className="py-0 d-none d-sm-block hover-card">
            <Row>
              <Row className="align-items-center">
                <Col md={2}>
                  <LottieBotLazy
                    type={getBotDataById(bot.bot_details.type).type}
                    loop={true}
                    style={{ width: '80%' }}
                  />
                </Col>
                <Col md={2}>
                  <h5 className="mb-0">{bot.bot_details.name}</h5>
                  <small>
                    {getBotDataById(bot.bot_details.type).name || ''}
                  </small>
                </Col>
                <Col md={2}>
                  <h5 className="text-center">Asset</h5>
                  {walletImgRender(bot.coins)}
                </Col>
                <Col md={2} className="text-center">
                  <p className="mt-2 mb-0 fs--1">{textLang.invested[lang]}</p>
                  <p className="mt-2">
                    <b>
                      {currency}
                      {(initialEquivalent + addedCapital).toFixed(2)}
                    </b>
                  </p>
                </Col>
                <Col md={2} className="text-center">
                  <p className="mt-2 mb-0 fs--1">{textLang.managed[lang]}</p>
                  <p className="mt-2">
                    <b className={'text-dark'}>
                      {currency}
                      {lastValue.toFixed(2)}
                    </b>
                  </p>
                </Col>
                <Col md={2} className="text-center">
                  <p className="mt-2 mb-0 fs--1">
                    {textLang.performance[lang]}
                  </p>
                  <p className="mt-2">
                    <b className={performanceColorAndCaret[0]}>
                      {Number(performance) !== 0 && (
                        <span className="me-1">
                          <FontAwesomeIcon icon={performanceColorAndCaret[1]} />
                        </span>
                      )}
                      {currency}
                      {Math.abs(Number(performance).toFixed(2))}
                    </b>
                  </p>
                </Col>
              </Row>
            </Row>
          </Card.Body>

          {/* MOBILE */}
          <Card.Body className="py-0 d-sm-none">
            <Row className="align-items-center pt-3">
              <Col xs={6}>
                <LottieBotLazy
                  type={getBotDataById(bot.bot_details.type).type}
                  loop={true}
                  style={{ width: '80%' }}
                />
              </Col>
              <Col xs={6} className="ps-4">
                <Row className="pb-2">
                  <Col>{walletImgRender(bot.coins, '')}</Col>
                </Row>
                <Row>
                  <Col>
                    <h6 className="mb-0 fs-1">{bot.bot_details.name}</h6>
                    <small>
                      {getBotDataById(bot.bot_details.type).name || ''}
                    </small>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col>
                    <p className="mb-0 fs--1">{textLang.invested[lang]}</p>
                  </Col>
                  <Col>
                    <p className="mb-0 ms-3">
                      <b>
                        {currency}
                        {(initialEquivalent + addedCapital).toFixed(2)}
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mb-0 fs--1">{textLang.managed[lang]}</p>
                  </Col>
                  <Col>
                    <p className="mb-0 ms-3">
                      <b className={'text-dark'}>
                        {currency}
                        {lastValue.toFixed(2)}
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mb-0 fs--1">{textLang.performance[lang]}</p>
                  </Col>
                  <Col>
                    <p className="mb-0">
                      <b className={performanceColorAndCaret[0]}>
                        <span className="me-1">
                          <FontAwesomeIcon icon={performanceColorAndCaret[1]} />
                        </span>
                        {currency}
                        {Math.abs(Number(performance).toFixed(2))}
                      </b>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
          {/* BOT STATUS: Top Left */}
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <SoftBadge pill bg={botStatusColor(bot.bot_details.status)}>
              {bot.bot_details.status.toUpperCase() === 'ACTIVE'
                ? textLang.active[lang]
                : bot.bot_details.status.toUpperCase() === 'PAUSED'
                ? textLang.paused[lang]
                : textLang.error[lang]}
            </SoftBadge>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

BotRow.propTypes = {
  bot: PropTypes.object.isRequired,
  dynamicCoins: PropTypes.object
};

export default BotRow;
