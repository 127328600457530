import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button
} from 'react-bootstrap';
import AppContext from 'context/Context';
import BotCard from '../common/BotCard';
import 'assets/scss/custom/form.scss';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { Form as textLang } from 'staticData/languages';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import WizardForm from './wizard/WizardLayout';
import { trackEvent } from 'services/externals/mixpanel';
import { breakpoints } from 'helpers/utils';

const index = () => {
  const {
    config: { lang, isDark },
    userInfos,
    setUserInfos
  } = useContext(AppContext);

  const showBotCard = false;
  const queryParameters = new URLSearchParams(window.location.search);
  const queryForm = queryParameters.get('queryForm');
  const [questions, setQuestions] = useState([]);
  const [loadedContent, setLoadedContent] = useState(false);
  const [username, setUsername] = useState('');
  const [askUsername, setAskUsername] = useState(
    userInfos.frontend_version === null
  );
  const [usernameFeedback, setUsernameFeedback] = useState();
  const [validUsername, setValidUsername] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [historyArray, setHistoryArray] = useState([]);
  const [endForm, setEndForm] = useState(false);
  const [formHistory, setFormHistory] = useState({});
  const [inputAnswer, setInputAnswer] = useState([]);
  const [title, setTitle] = useState('');
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [showWizard, setShowWizard] = useState(false);

  // backend data
  // const [bot, setBot] = useState({});
  // const [coin, setCoin] = useState({});
  // const [quote, setQuote] = useState('');
  // const [amount, setAmount] = useState();
  const bot = {};
  const coin = {};
  const quote = '';
  const amount = 0;
  const [backendError, setBackendError] = useState(false);

  const getQuestions = async () => {
    var formParams = { queryForm: queryForm ? queryForm : 0 };
    let getQuestionsRepsonse = await endpointInterface(
      lang,
      'backend',
      'getForm',
      'get',
      true,
      formParams
    );
    if (getQuestionsRepsonse.validResponse) {
      setQuestions(getQuestionsRepsonse.data.form.data);
      setCurrentQuestion(getQuestionsRepsonse.data.form.data[0]);
      setShowWizard(getQuestionsRepsonse.data.form.showCheckpoints);
      if (userInfos.frontend_version === null)
        setAskUsername(getQuestionsRepsonse.data['can_set_username']);
    } else {
      setBackendError(true);
    }
  };

  const hadleBack = () => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
      delete formHistory[currentQuestion.id];
      setCurrentQuestion(historyArray[historyArray.length - 1]);
      historyArray.pop();
    }
  };

  const setAnswer = async (question, answer) => {
    var localFormHostory = { ...formHistory };
    if (questionNumber + 1 === 3) trackEvent('form', { answer: 3 });
    if (!currentQuestion.noHistory) {
      localFormHostory[question] = answer.text ? answer.id : answer;
      setFormHistory(localFormHostory);
    }
    if (answer.childID !== '') {
      const newQuestion = questions.find(o => o.id === answer.childID);
      setQuestionNumber(questionNumber + 1);
      setCurrentQuestion(newQuestion);
      setHistoryArray([...historyArray, currentQuestion]);
    } else {
      trackEvent('form', { answer: 'END' });
      setLoading(true);
      let form = new FormData();
      form.append('endedForm', true);
      form.append('form', JSON.stringify(localFormHostory));
      let insertSignupFormRepsonse = await endpointInterface(
        lang,
        'backend',
        'insertSignupForm',
        'post',
        true,
        form
      );
      setLoading(false);
      if (insertSignupFormRepsonse.validResponse) {
        if (answer.redirectTo) {
          if (userInfos.frontend_version === null)
            setUserInfos({
              ...userInfos,
              ['frontend_version']: '0',
              ['username']: username.trim()
            });
          routerHistory.push(answer.redirectTo);
        } else {
          // setBot(insertSignupFormRepsonse.data['bot']);
          // setCoin(insertSignupFormRepsonse.data['coin']);
          // setQuote(insertSignupFormRepsonse.data['quote']);
          // setAmount(insertSignupFormRepsonse.data['amount']);
          // if (userInfos.frontend_version === null)
          // setUserInfos({
          //   ...userInfos,
          //   ['frontend_version']: '0'
          // });
          if (userInfos.frontend_version === null)
            setUserInfos({
              ...userInfos,
              ['frontend_version']: '0',
              ['username']: username.trim()
            });
          setEndForm(true);
          routerHistory.push('/');
        }
      } else {
        toast.error(insertSignupFormRepsonse.responseMessage, {
          closeButton: false
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    }
  };

  const skip = async () => {
    let tmpForm =
      Object.keys(formHistory).length === 0 ? '' : JSON.stringify(formHistory);
    let form = new FormData();
    form.append('endedForm', false);
    form.append('form', tmpForm);
    let insertSignupFormRepsonse = await endpointInterface(
      lang,
      'backend',
      'insertSignupForm',
      'post',
      true,
      form
    );
    if (insertSignupFormRepsonse.validResponse) {
      if (userInfos.frontend_version === null)
        setUserInfos({
          ...userInfos,
          ['frontend_version']: '0',
          ['username']: username.trim()
        });
      setEndForm(true);
      routerHistory.push('/');
    }
  };

  const getTitle = async () => {
    if (userInfos.frontend_version === null) {
      setTitle(textLang.onboard[lang]);
    } else {
      if (queryForm) {
        if (textLang.hasOwn(queryForm)) setTitle(textLang[queryForm][lang]);
        else setTitle(textLang['error'][lang]);
      } else setTitle(textLang['0'][lang]);
    }
  };

  const changeUsernameFunction = async () => {
    // Generate Recaptcha Token & Login
    window.grecaptcha.enterprise.ready(async () => {
      const recaptcha_token = await window.grecaptcha.enterprise.execute(
        '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
        { action: 'change_username' }
      );
      let form = new FormData();
      form.append('new_username', username.trim());
      form.append('recaptcha_token', recaptcha_token);
      let changeUsernameResponse = await endpointInterface(
        lang,
        'backend',
        'changeUsername',
        'post',
        true,
        form
      );
      if (changeUsernameResponse.validResponse) {
        setAskUsername(false);
      } else {
        var check = true;
        if (changeUsernameResponse.responseMessage.startsWith('USERNAME')) {
          setUsernameFeedback(
            changeUsernameResponse.responseMessage.substring(10)
          );
          setValidUsername(false);
          check = false;
        }
        if (check) {
          toast.error(changeUsernameResponse.responseMessage, {
            closeButton: false
          });
          setTimeout(() => {
            toast.dismiss();
          }, 5000);
        }
      }
    });
  };

  const handleSubmitUsername = () => {
    setValidUsername(true);
    setUsernameFeedback(textLang.usernameLonger[lang]);
    var valid = true;
    if (
      !username.trim().match('^[A-Za-z0-9]+$') ||
      username.trim().length < 6
    ) {
      setValidUsername(false);
      valid = false;
    }
    if (valid) changeUsernameFunction();
  };

  useEffect(async () => {
    if (!loadedContent) {
      getTitle();
      await getQuestions();
      setLoadedContent(true);
    }
  }, []);

  return !loadedContent ? (
    <div style={{ padding: '15%' }}>
      <LoadingSpinner subText="Hodlie" useGif={true}></LoadingSpinner>
    </div>
  ) : userInfos.frontend_version === null && askUsername ? (
    <>
      <Row className="mb-5">
        <Card
          className="px-0"
          style={{
            boxShadow: 'unset'
          }}
        >
          <Card.Header
            className="position-relative"
            style={{
              backgroundColor: 'var(--falcon-body-bg)'
            }}
          >
            <h5
              className="text-600 my-3 text-center"
              dangerouslySetInnerHTML={{ __html: textLang.usernameTitle[lang] }}
            ></h5>
          </Card.Header>
        </Card>
      </Row>
      <Row className="mt-5 g-4 justify-content-center">
        <Col sm={8}>
          <InputGroup className="mb-3" size="lg">
            <FormControl
              value={username}
              onChange={e => setUsername(e.target.value)}
              isInvalid={!validUsername}
            />
            <Button variant="outline-secondary" onClick={handleSubmitUsername}>
              {textLang.confirm[lang]}
            </Button>
            <FormControl.Feedback type="invalid">
              {usernameFeedback}
            </FormControl.Feedback>
          </InputGroup>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row className="mb-2">
        <Card
          className="px-0"
          style={{
            boxShadow: 'unset'
          }}
        >
          <Card.Header
            className="position-relative"
            style={{
              backgroundColor: 'var(--falcon-body-bg)'
            }}
          >
            <h5
              className="text-600 my-3 text-center"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h5>
          </Card.Header>
        </Card>
      </Row>

      {backendError ? (
        <Row className="mt-5 g-4 justify-content-center">
          <Col
            key={index}
            lg={4}
            xxl={4}
            onClick={() => (window.location.href = '/')}
            style={{ cursor: 'pointer' }}
          >
            <div
              className={
                'px-4 py-4 rounded-3 border position-relative hover-bg-custom-' +
                (isDark ? '300' : '100')
              }
              style={{ backgroundColor: 'var(--falcon-line-chart-gradient)' }}
            >
              <h3 className="fs-0 fw-semi-bold mb-2 text-center">
                {textLang.goHome[lang]}
              </h3>
            </div>
          </Col>
        </Row>
      ) : loading ? (
        <div style={{ padding: '15%' }}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      ) : !endForm ? (
        <>
          <Row>
            <Col xxl={12} className="mt-0 h-100 mb-2 px-0">
              {showWizard && (
                <WizardForm
                  step={questionNumber}
                  totalSteps={questions.length}
                />
              )}
            </Col>
          </Row>
          <Row className="g-4 justify-content-center">
            <Row className="my-5 justify-content-center">
              <h1 className="text-center">
                {currentQuestion.id === 'continue'
                  ? textLang.continueTitleNoBot[lang]
                  : currentQuestion.question[lang]}
              </h1>
              {currentQuestion.id === 'continue' && (
                <Col md={8} className="">
                  <h5
                    className="text-center d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question[lang]
                    }}
                  ></h5>
                  <h6
                    className="text-center d-sm-none"
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion.question[lang]
                    }}
                  ></h6>
                </Col>
              )}
            </Row>
            {currentQuestion.type === 'multipleChoice' &&
              currentQuestion.answers.map((answer, index) => (
                <Col
                  key={index}
                  lg={4}
                  xxl={4}
                  onClick={() => setAnswer(currentQuestion.id, answer)}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    className={
                      'px-4 rounded-3 border position-relative hover-bgImg-0 hover-bg-custom-' +
                      (isDark ? '300' : '100')
                    }
                    style={{
                      backgroundImage: 'var(--falcon-line-chart-gradient)',
                      lineHeight:
                        window.innerWidth < breakpoints.sm ? '100px' : '120px',
                      height:
                        window.innerWidth < breakpoints.sm ? '100px' : '120px',
                      textAlign: 'center'
                    }}
                  >
                    <h3
                      className="fs-0 fw-semi-bold mb-2 text-center"
                      style={{
                        lineHeight: '1.5',
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }}
                    >
                      {answer.text[lang]}
                    </h3>
                  </div>
                </Col>
              ))}
            {currentQuestion.type === 'input' && (
              <Col sm={6}>
                <InputGroup className="mb-3" size="lg">
                  <FormControl
                    placeholder={currentQuestion.placeholder}
                    aria-describedby="basic-addon2"
                    onChange={e =>
                      setInputAnswer({
                        text: e.target.value,
                        childID: currentQuestion.answers[0].childID
                      })
                    }
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={() => setAnswer(currentQuestion.id, inputAnswer)}
                  >
                    {textLang.confirm[lang]}
                  </Button>
                </InputGroup>
              </Col>
            )}
          </Row>
          {questionNumber > 0 && (
            <Row className="g-4 justify-content-start mt-4">
              <Col xs={6} sm={3} className="">
                <div className="d-flex justify-content-center">
                  <button className="btn" onClick={() => hadleBack()}>
                    {textLang.back[lang]}
                  </button>
                </div>
              </Col>
            </Row>
          )}
          {!currentQuestion.noSkip && (
            <Row className="g-4 justify-content-end mt-4">
              <Col xs={6} sm={3} className="">
                <div className="d-flex justify-content-center">
                  <button className="btn" onClick={() => skip()}>
                    {textLang.skip[lang]}
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </>
      ) : showBotCard ? (
        <BotCard
          bot={bot}
          coin={coin}
          quote={quote}
          amount={amount}
          formHistory={formHistory}
        />
      ) : (
        <Row className="g-4 mt-4">
          <Col className="">
            <div className="d-flex justify-content-center">
              <h4 className="text-center">
                Grazie per aver completato il Questionario, stai per entrare
                nella piattaforma!
              </h4>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default index;
