import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { getColor, rgbaColor, breakpoints } from 'helpers/utils';
import AppContext from 'context/Context';
import { monthsList as textLang } from 'staticData/languages';
import { getBreakpoint } from 'staticData/common';
import { CanvasRenderer } from 'echarts/renderers';
import { VisualMapComponent } from 'echarts/components';
import classNames from 'classnames';
import { currencyMap } from 'services/coins/common';
import 'assets/scss/custom/LinePayment.scss';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  MarkPointComponent,
  MarkLineComponent,
  VisualMapComponent
]);

var botName = '';
const minPointInChart = 6;

const getOption = (
  data,
  maxValue,
  minValue,
  dateInfo,
  isDark,
  zoomActive,
  zoomStart,
  zoomEnd,
  markLineData = [],
  hoverDataRef,
  visualMapPieces,
  simplifyChart,
  bgStyle = null
) => {
  let minSpan = ((minPointInChart - 1) / data.length) * 100;
  var options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      triggerOn: 'mousemove|click',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      borderWidth: 1,
      transitionDuration: 0,
      textStyle: {
        fontWeight: 500,
        fontSize: 12,
        color: getColor('dark')
      },
      formatter: params =>
        !simplifyChart && getTooltipLable(params, isDark, hoverDataRef)
    },
    legend: {
      show: false,
      textStyle: {
        color: getColor('500')
      },
      selectedMode: false,
      bottom: 0
    },
    xAxis: {
      type: 'category',
      data: dateInfo,
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: rgbaColor('#fff', 0.1)
        }
      },
      axisLine: {
        lineStyle: {
          color: rgbaColor('#fff', 0.1)
        }
      },
      axisTick: {
        show: true,
        length: 10,
        lineStyle: {
          color: rgbaColor('#fff', 0.1)
        }
      },
      axisLabel: {
        show: !simplifyChart,
        color: getColor('400'),
        fontWeight: 600,
        fontSize: 10,
        margin: 15,
        interval: 'auto'
      }
    },
    yAxis: {
      type: 'value',
      min: (minValue * 0.9).toFixed(1),
      max: (maxValue * 1.05).toFixed(1),
      axisPointer: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: !simplifyChart,
        color: getColor('400'),
        fontWeight: 600,
        fontSize: 10,
        margin: 5,
        formatter: value => getYAxisLabel(value, minValue, maxValue)
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    visualMap: {
      type: 'piecewise',
      show: false,
      dimension: 0,
      seriesIndex: 0,
      pieces: visualMapPieces ? [visualMapPieces] : []
    },
    series: [
      {
        name: botName,
        type: 'line',
        data: data.map(item => Number(item)),
        symbol: data.length > 1 ? 'none' : 'emptyCircle',
        lineStyle: {
          color: bgStyle
            ? rgbaColor(getColor('dark'), 0.8)
            : rgbaColor(getColor('primary'), 0.8)
        },
        areaStyle: bgStyle
          ? {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: rgbaColor(
                      isDark ? getColor('dark') : getColor('white'),
                      0.5
                    )
                  },
                  {
                    offset: 1,
                    color: rgbaColor(
                      isDark ? getColor('dark') : getColor('white'),
                      0
                    )
                  }
                ]
              }
            }
          : {},
        emphasis: {
          lineStyle: {
            width: 2
          },
          itemStyle: {
            color: rgbaColor(getColor('100'))
          },
          scale: true
        }
      },
      {
        type: 'bar',
        data: markLineData,
        barWidth: 1, // make the bar thin like a line
        itemStyle: {
          color: 'transparent', // make the bar invisible
          borderColor: rgbaColor(getColor('primary')), // color of the line
          borderType: 'dashed', // make the line dashed
          borderWidth: 2 // thickness of the line
        },
        emphasis: {
          itemStyle: {
            color: 'transparent' // make the bar invisible even when emphasized
          }
        },
        z: 2
      }
    ],
    grid: simplifyChart
      ? { right: 0, left: 0, bottom: 0, top: 0 }
      : { right: 10, left: 40, bottom: '15%', top: '5%' }
  };
  if (zoomActive) {
    options.dataZoom = [
      {
        type: 'inside',
        start: zoomStart !== null ? zoomStart : 100 - minPointInChart,
        end: zoomEnd !== null ? zoomEnd : 100,
        minSpan: minSpan
      }
    ];
    if (window.innerWidth >= breakpoints['sm']) {
      options.dataZoom.push({
        type: 'slider',
        minSpan: minSpan
      });
    }
  }
  return options;
};

function getYAxisLabel(value, min, max) {
  let text = null;
  if (max - min > 3) {
    text = value.toFixed();
  } else {
    text = value.toFixed(1);
  }
  let finalText = text;
  if (text >= 1000000) finalText = (text / 1000000).toFixed(1) + 'M';
  return finalText;
}

const getTooltipLable = (params, isDark, hoverDataRef) => {
  if (!hoverDataRef.current) hoverDataRef.current = {};
  hoverDataRef.current.params = params;
  let text = `${params[0].axisValue}`;
  params.map(p => {
    if (p.seriesIndex === 0) {
      let textColor =
        p.seriesName == botName
          ? isDark
            ? getColor('primary')
            : getColor('dark')
          : null;
      text += `<br /><font color='${textColor}'><b>${botName}</b></font>${
        '$' + Math.round(100 * p.value) / 100
      }`;
    }
  });
  return text;
};

const LinePaymentChart = forwardRef(
  (
    {
      displayedWallet,
      granularity,
      zoomActive = false,
      events = [],
      handleCheckpointData,
      setShowPercentage,
      simplifyChart,
      bgStyle = null,
      style
    },
    ref
  ) => {
    const {
      config: { isDark, lang, currency }
    } = useContext(AppContext);
    const hoverDataRef = useRef(null);
    const isTooltipOpen = useRef(false);
    const selectCheckpoint = useRef(null);
    const zoomStart = null;
    const zoomEnd = null;
    const visualMapPieces = useRef({
      gt: 0,
      lt: displayedWallet.length - 1 || 1,
      color: rgbaColor(getColor('primary'), 0.4)
    });
    // creo una linea di due punti se ho solo un punto per evitare errori
    if (displayedWallet.length === 1) displayedWallet.push(displayedWallet[0]);
    var maxValue = 100;
    var minValue = 10;

    const formatAMPM = (date, withMinutes) => {
      var hours = date.getHours();
      var ampm = '';
      if (lang === 'en-EN') {
        ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
      }
      var strTime = hours;
      if (withMinutes) {
        var minutes = date.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : minutes;
        strTime = strTime + ':' + minutes;
      }
      strTime =
        strTime + ampm + (!withMinutes && lang !== 'en-EN' ? ':00' : '');
      return strTime;
    };

    const getTimeLabel = (time, withMinutes) => {
      let date = new Date(time);
      let months = textLang.months[lang];
      let day = date.getDate();
      let month = months[date.getMonth()];
      let year = date.getFullYear();
      let text =
        day + ' ' + month + ' ' + year + ' ' + formatAMPM(date, withMinutes);
      return text;
    };

    // Get dates array
    const datesArray = displayedWallet.map(e =>
      getTimeLabel(e.ts, granularity === '4h')
    );

    // Get bot data array
    const botData = displayedWallet.map(e =>
      Number(e[currencyMap[currency]]).toFixed(2)
    );

    if (displayedWallet.length > 0) {
      var arrOfNum = botData.map(str => {
        return Number(str);
      });
      maxValue = Math.max(...arrOfNum);
      minValue = Math.min(...arrOfNum);
    }

    const generateMarkLines = (events, displayedWallet) => {
      const walletTimestamps = displayedWallet.map(wallet => wallet.ts);
      const markLines = [];
      let showPerc = true;
      var barLineData = walletTimestamps.map(() => 0);
      for (let i = 0; i < walletTimestamps.length - 1; i++) {
        const eventInRange = events.find(
          event =>
            event.ts >= walletTimestamps[i] &&
            event.ts < walletTimestamps[i + 1]
        );
        if (eventInRange && i + 1 < walletTimestamps.length) {
          // faccio walletTimestamps.length - 2 per evitare di tirare una linea nell'ultimo punto del grafico
          // In questo modo (col -2) plotto la markline verticale solo se c'è almeno un punto dopo questa, inolter non faccio vedere la percentuale se c'è il punto
          if (i === walletTimestamps.length - 2) showPerc = false;
          else {
            markLines.push({
              xAxis: getTimeLabel(
                walletTimestamps[i + 1],
                granularity === '4h'
              ),
              index: i + 1,
              id: eventInRange.id,
              ts: eventInRange.ts
            });
            barLineData[i + 1] = botData[i + 1];
          }
        }
      }
      if (markLines.length > 0 || !showPerc) setShowPercentage(false);
      else setShowPercentage(true);
      return { markLines, barLineData };
    };

    const { barLineData, markLines } = generateMarkLines(
      events,
      displayedWallet
    );

    var option = getOption(
      botData,
      maxValue,
      minValue,
      datesArray,
      isDark,
      zoomActive,
      zoomStart,
      zoomEnd,
      barLineData,
      hoverDataRef,
      visualMapPieces?.current || null,
      simplifyChart,
      bgStyle
    );

    const handleMouseMove = () => {
      let update = false;
      const chartInstance = ref.current.getEchartsInstance();
      if (hoverDataRef.current?.params) {
        if (markLines.length === 0) return;
        markLines.sort((a, b) => a.index - b.index);
        const currentIndex = hoverDataRef.current.params[0].dataIndex;
        let start = 0;
        let end = markLines[0].index;
        for (let i = 0; i < markLines.length; i++) {
          if (currentIndex < markLines[i].index) {
            end = markLines[i].index;
            if (selectCheckpoint.current?.id !== markLines[i].id) update = true;

            selectCheckpoint.current = { ...markLines[i] };
            break;
          }
          start = markLines[i].index;
        }
        if (currentIndex >= markLines[markLines.length - 1].index) {
          start = markLines[markLines.length - 1].index;
          end = datesArray.length - 1;
          if (selectCheckpoint.current?.id !== 'end') update = true;

          selectCheckpoint.current = { ...markLines[markLines.length - 1] };
          selectCheckpoint.current.id = 'end';
        }
        let localVisualMapPieces = {
          gt: start,
          lt: end,
          color: rgbaColor(getColor('primary'), 0.4)
        };
        visualMapPieces.current = localVisualMapPieces;
        if (update)
          chartInstance.setOption({
            visualMap: {
              pieces: [localVisualMapPieces]
            }
          });
      }
    };

    const handleMouseLeave = () => {
      hoverDataRef.current = null;
      if (ref?.current) {
        const chartInstance = ref.current.getEchartsInstance();
        let localVisualMapPieces = {
          gt: 0,
          lt: datesArray.length - 1,
          color: rgbaColor(getColor('primary'), 0.4)
        };
        visualMapPieces.current = localVisualMapPieces;
        chartInstance.setOption({
          visualMap: {
            pieces: [localVisualMapPieces]
          }
        });
      }
    };

    const handleMouseClick = async () => {
      if (markLines.length === 0) return;
      if (isTooltipOpen.current && selectCheckpoint.current?.id)
        handleCheckpointData(
          selectCheckpoint.current.id,
          selectCheckpoint.current.ts
        );
    };

    useEffect(() => {
      // Set visualMapPieces to the initial state
      if (ref?.current) {
        const chartInstance = ref?.current?.getEchartsInstance() || null;
        if (chartInstance) {
          let localVisualMapPieces = {
            gt: 0,
            lt: datesArray.length - 1 || 1,
            color: rgbaColor(getColor('primary'), 0.4)
          };
          visualMapPieces.current = localVisualMapPieces;
          chartInstance.setOption({
            visualMap: {
              pieces: [localVisualMapPieces]
            }
          });

          // save tooltip state
          chartInstance.on('showTip', () => {
            isTooltipOpen.current = true;
          });
          chartInstance.on('hideTip', () => {
            isTooltipOpen.current = false;
          });
        }
      }
    }, [datesArray]);

    return (
      <span
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={handleMouseClick}
        className={classNames('', {
          'pointer-cursor-chart': simplifyChart
        })}
      >
        <ReactEChartsCore
          echarts={echarts}
          ref={ref}
          option={option}
          style={style[getBreakpoint(window.innerWidth)]}
        />
      </span>
    );
  }
);

LinePaymentChart.propTypes = {
  displayedWallet: PropTypes.array.isRequired,
  granularity: PropTypes.string.isRequired,
  zoomActive: PropTypes.bool,
  events: PropTypes.array,
  handleCheckpointData: PropTypes.func,
  setShowPercentage: PropTypes.func,
  style: PropTypes.object,
  simplifyChart: PropTypes.bool,
  bgStyle: PropTypes.string
};

export default LinePaymentChart;
