import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { walletImgRender } from 'services/coins/components';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import Flex from 'components/common/Flex';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import HalfDoughnutChart from '../HalfChartPortfolio';
import { coinsData } from 'services/coins/common';
import SimpleBarReact from 'simplebar-react';
import CardDropdown from './CardDropdown';
import AddCapital from './AddCapital';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';

const PortfolioRow = ({ bot, dynamicCoins }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const botType = bot.bot_details.type;
  const { id, name, bgStyle, riskLevels, backendMinLevels } =
    portfolioList.find(bot => String(bot.id) === String(botType));

  const riskLevel =
    riskLevels[backendMinLevels.indexOf(bot.bot_details.min_level)];
  const chartHeigth = 12;

  const initialEquivalent = bot.bot_details.initial_eqv[currencyMap[currency]];

  const addedCapital = bot.added_capital;

  const botStatusColor = status => {
    if (status.toUpperCase() === 'ACTIVE') return 'success';
    if (status.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const lastValue = bot.last_stat
    ? Object.keys(dynamicCoins).length
      ? calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
      : bot.last_stat.eqv[currencyMap[currency]]
    : initialEquivalent;

  const performance = lastValue - (initialEquivalent + addedCapital);

  const getColorAndCaret = num => {
    if (Number(num) < 0) return ['text-danger', 'caret-down'];
    if (Number(num) > 0) return ['text-success', 'caret-up'];
    return ['text-dark', 'minus'];
  };

  const performanceColorAndCaret = getColorAndCaret(performance);

  const donutData = Object.keys(dynamicCoins).length
    ? {
        labels: Object.keys(bot.last_stat.wallet),
        datasets: [
          {
            data: Object.keys(bot.last_stat.wallet).map(key =>
              Number(
                (
                  (bot.last_stat.wallet[key] * dynamicCoins[key] * 100) /
                  calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
                ).toFixed(2)
              )
            ),
            backgroundColor: Object.keys(bot.last_stat.wallet).map(
              key => coinsData[key].color
            ),
            borderColor: Object.keys(bot.last_stat.wallet).map(
              key => coinsData[key].color
            )
          }
        ]
      }
    : {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderColor: []
          }
        ]
      };

  const coinListItems = Object.keys(dynamicCoins).length ? (
    Object.keys(bot.last_stat.wallet)
      .filter(key => bot.last_stat.wallet[key] > 0)
      .sort(
        (a, b) =>
          bot.last_stat.wallet[b] * dynamicCoins[b] -
          bot.last_stat.wallet[a] * dynamicCoins[a]
      )
      .map(key => (
        <>
          {bot.last_stat.wallet[key] > 0 && (
            <p className="text-700" style={{ fontSize: '10pt' }} key={key}>
              <Flex style={{ color: coinsData[key].color }}>
                {walletImgRender([key], 'me-2')}
                {coinsData[key].name}
                <span className="text-900 ps-2 fw-medium fs--1">
                  {currency}
                  {(bot.last_stat.wallet[key] * dynamicCoins[key]).toFixed(2)}
                  <small className="text-600 ms-1">
                    (
                    {(
                      (bot.last_stat.wallet[key] * dynamicCoins[key] * 100) /
                      calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
                    ).toFixed(2)}
                    %)
                  </small>
                </span>
              </Flex>
            </p>
          )}
        </>
      ))
  ) : (
    <></>
  );

  return (
    <Row className="my-3">
      <Col>
        <Card style={{ backgroundImage: bgStyle }}>
          {/* > MOBILE */}
          <Card.Body className="d-none d-sm-block">
            <Row>
              <Col
                md={8}
                style={{ borderRight: 'var(--falcon-500) 1px solid' }}
              >
                <Row>
                  <Col sm={4} as={Flex} className="justify-content-center">
                    <LottieBotLazy
                      type={id}
                      loop={true}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col sm={8} as={Flex} className="align-items-center">
                    <div>
                      <small>AI Portfolio</small>
                      <h4>{name}</h4>
                      {riskLevel && (
                        <Flex className="align-items-center">
                          <h6 className="mb-0 me-2">
                            {textLang.riskLevel[lang]}
                          </h6>
                          <ButtonGrid
                            actives={[riskLevel]}
                            click={true}
                            buttonSize={'sm'}
                          />
                        </Flex>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="text-center">
                    <p className="mt-2 mb-0">{textLang.invested[lang]}</p>
                    <p className="mt-2 fs-3">
                      <b className={'text-dark'}>
                        {currency}
                        {(initialEquivalent + addedCapital).toFixed(2)}
                      </b>
                    </p>
                  </Col>
                  <Col md={4} className="text-center">
                    <p className="mt-2 mb-0">{textLang.managed[lang]}</p>
                    <p className="mt-2 fs-3">
                      <b className={'text-dark'}>
                        {currency}
                        {lastValue.toFixed(2)}
                      </b>
                    </p>
                  </Col>
                  <Col md={4} className="text-center">
                    <p className="mt-2 mb-0">{textLang.performance[lang]}</p>
                    <p className="mt-2 fs-3">
                      <b className={performanceColorAndCaret[0]}>
                        {Number(performance) !== 0 && (
                          <span className="me-1">
                            <FontAwesomeIcon
                              icon={performanceColorAndCaret[1]}
                            />
                          </span>
                        )}
                        {currency}
                        {Math.abs(Number(performance).toFixed(2))}
                      </b>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Flex className="justify-content-center">
                  <div
                    style={{
                      width: 'fit-content'
                    }}
                  >
                    <div
                      className="text-center pb-3"
                      style={{
                        maxWidth: `${chartHeigth}rem`
                      }}
                    >
                      <HalfDoughnutChart data={donutData} />
                      <h4 className="d-inline-block mb-0 mt-n4 fs-1 fw-medium font-sans-serif">
                        {textLang.now[lang]}
                      </h4>
                    </div>
                    <SimpleBarReact
                      style={{
                        maxHeight: `${chartHeigth * 0.6}rem`,
                        width: '100%'
                      }}
                    >
                      <div className="mt-3">{coinListItems}</div>
                    </SimpleBarReact>
                  </div>
                </Flex>
              </Col>
            </Row>
          </Card.Body>

          {/* MOBILE */}
          <Card.Body className="d-sm-none">
            <Row
              style={{ borderBottom: 'var(--falcon-500) 1px solid' }}
              className="mt-3 pb-4"
            >
              <Col xs={8}>
                <Row>
                  <Col sm={4} as={Flex}>
                    <LottieBotLazy
                      type={id}
                      loop={true}
                      style={{ width: '80%' }}
                    />
                  </Col>
                  <Col sm={8} as={Flex} className="align-items-center">
                    <div>
                      <small>AI Portfolio</small>
                      <h4>{name}</h4>
                      {riskLevel && (
                        <Flex className="align-items-center">
                          <h6 className="mb-0 me-2">
                            {textLang.riskLevel[lang]}
                          </h6>
                          <ButtonGrid
                            actives={[riskLevel]}
                            click={true}
                            buttonSize={'sm'}
                          />
                        </Flex>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={4} className="align-items-center ps-0 pt-3" as={Flex}>
                <div>
                  <Row>
                    <p className="mt-2 mb-0">{textLang.invested[lang]}</p>
                    <p className="mt-2 mb-1 fs-2">
                      <b className={'text-dark'}>
                        {currency}
                        {(initialEquivalent + addedCapital).toFixed(2)}
                      </b>
                    </p>
                  </Row>
                  <Row>
                    <p className="mt-2 mb-0">{textLang.managed[lang]}</p>
                    <p className="mt-2 mb-1 fs-2">
                      <b className={'text-dark'}>
                        {currency}
                        {lastValue.toFixed(2)}
                      </b>
                    </p>
                  </Row>
                  <Row>
                    <p className="mt-2 mb-0">{textLang.performance[lang]}</p>
                    <p className="mt-2 mb-1 fs-2">
                      <b className={performanceColorAndCaret[0]}>
                        {Number(performance) !== 0 && (
                          <span className="me-1">
                            <FontAwesomeIcon
                              icon={performanceColorAndCaret[1]}
                            />
                          </span>
                        )}
                        {currency}
                        {Math.abs(Number(performance).toFixed(2))}
                      </b>
                    </p>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Flex className="justify-content-center">
                  <div
                    style={{
                      width: 'fit-content'
                    }}
                  >
                    <div
                      className="text-center pb-3"
                      style={{
                        maxWidth: `${chartHeigth}rem`
                      }}
                    >
                      <HalfDoughnutChart data={donutData} />
                      <h4 className="d-inline-block mb-0 mt-n4 fs-1 fw-medium font-sans-serif">
                        {textLang.now[lang]}
                      </h4>
                    </div>
                    <SimpleBarReact
                      style={{
                        maxHeight: `${chartHeigth * 0.6}rem`,
                        width: '100%'
                      }}
                    >
                      <div className="mt-3">{coinListItems}</div>
                    </SimpleBarReact>
                  </div>
                </Flex>
              </Col>
            </Row>
          </Card.Body>
          {/* BOT STATUS: Top Left */}
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <SoftBadge pill bg={botStatusColor(bot.bot_details.status)}>
              {bot.bot_details.status.toUpperCase() === 'ACTIVE'
                ? textLang.active[lang]
                : bot.bot_details.status.toUpperCase() === 'PAUSED'
                ? textLang.paused[lang]
                : textLang.error[lang]}
            </SoftBadge>
          </div>
          {/* BOT info: Top Right */}
          <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Flex className="align-items-center">
              <AddCapital portfolio={bot} />
              <CardDropdown portfolio={bot} managed={lastValue} />
            </Flex>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

PortfolioRow.propTypes = {
  bot: PropTypes.object.isRequired,
  exchange: PropTypes.object,
  dynamicCoins: PropTypes.object
};

export default PortfolioRow;
