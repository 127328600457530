import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AppContext from 'context/Context';
import { TraderModal as textLang } from 'staticData/languages';
import { useHistory } from 'react-router-dom';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';

const TraderModal = ({ showModal, setShowModal, getInfo }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [waiting, setWaiting] = useState(false);
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);
  const routerHistory = useHistory();

  const updateInfo = async () => {
    setWaiting(true);
    await getInfo();
    routerHistory.push('/account/trader-procedure');
    setWaiting(false);
  };

  const requestTrader = async () => {
    setWaiting(true);
    const resp = await endpointInterface(
      lang,
      'backend',
      'requestTraderInfo',
      'post',
      true
    );
    if (!resp.validResponse) console.error(resp);
    setWaiting(false);
  };

  const startBecomingTrader = async () => {
    setWaiting(false);
    await requestTrader();
    await updateInfo();
  };

  const handleCloseMailModal = open => {
    setConfirmMailShow(open);
    setShowModal(false);
  };

  useEffect(() => {
    if (!userInfos?.email_confirmed && showModal) setConfirmMailShow(true);
  }, [showModal]);

  return (
    <>
      {userInfos && showModal && (
        <ConfirmMail
          ConfirmMailShow={ConfirmMailShow}
          setConfirmMailShow={handleCloseMailModal}
          step={0}
          withHeader={false}
          userCanClose={true}
        />
      )}
      <Modal
        show={showModal && !ConfirmMailShow}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            {textLang.title[lang]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ fontSize: 'larger' }}
          dangerouslySetInnerHTML={{ __html: textLang.text[lang] }}
        ></Modal.Body>
        <Modal.Footer>
          <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
            <IconButton
              variant="outline-danger"
              className="me-2 mb-1 "
              icon="times"
              iconAlign="left"
              transform="shrink-3"
              onClick={() => setShowModal(false)}
            >
              {textLang.close[lang]}
            </IconButton>
          </Flex>
          <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
            <IconButton
              variant="outline-success"
              className="me-2 mb-1 "
              icon={waiting ? '' : 'check'}
              iconAlign="left"
              transform="shrink-3"
              onClick={() => startBecomingTrader()}
              disabled={waiting}
            >
              {waiting ? (
                <Flex>
                  <Spinner
                    animation="border"
                    className="me-2 mt-1"
                    style={{ width: '1rem', height: '1rem' }}
                  ></Spinner>
                  {textLang.continue[lang]}
                </Flex>
              ) : (
                textLang.continue[lang]
              )}
            </IconButton>
          </Flex>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TraderModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  getInfo: PropTypes.func.isRequired
};

export default TraderModal;
