import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddLiquidityModal from 'pages/common/modals/AddLiquidity';

const AddCapital = ({ portfolio }) => {
  const { bot_details: bot } = portfolio;

  const [addLiquidityModalShow, setAddLiquidityModalShow] = useState(false);

  const handleAddButtonClick = () => {
    setAddLiquidityModalShow(true);
  };

  const handleAddedLiquidity = () => {
    window.location.reload();
  };

  return (
    bot && (
      <>
        <AddLiquidityModal
          modalShow={addLiquidityModalShow}
          setModalShow={setAddLiquidityModalShow}
          setAddedLiquidity={handleAddedLiquidity}
          botID={bot.id}
          type={'portfolio'}
        />

        <span className="pointerCursor" onClick={handleAddButtonClick}>
          <FontAwesomeIcon icon="plus" className="fs--2 me-2" />
        </span>
      </>
    )
  );
};

AddCapital.propTypes = {
  portfolio: PropTypes.object,
  managed: PropTypes.number
};

export default AddCapital;
