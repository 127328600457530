import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

const ShareOnSocialModal = ({ modalShow, setModalShow, body }) => {
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
        {body}
      </Modal.Body>
      <Modal.Footer style={{ height: '40px' }}></Modal.Footer>
    </Modal>
  );
};

ShareOnSocialModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired,
  body: PropTypes.node.isRequired
};

export default ShareOnSocialModal;
