import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Card, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import InfoPopover from 'components/utilities/InfoPopover';
import AppContext from 'context/Context';
import { Sentiment as textLang } from 'staticData/languages';

const SentimentCount = ({ sentiment, loaded }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [showNegativeTooltip, setShowNegativeTooltip] = useState(false);
  const [showPositiveTooltip, setShowPositiveTooltip] = useState(false);

  return (
    <Card className="h-md-100">
      <Card.Header as={Flex} className="pb-0">
        <h6 className="mb-0 mt-1">{textLang.sentiment[lang]}</h6>
        <InfoPopover Text={textLang.sentimentInfo[lang]} />
      </Card.Header>
      <Card.Body className="mt-3">
        {!loaded ? (
          <Flex style={{ padding: '5% 0' }} className="justify-content-center">
            <Spinner />
          </Flex>
        ) : (
          <div style={{ position: 'relative', width: '100%', height: '20px' }}>
            <ProgressBar>
              <ProgressBar
                className="rounded-0"
                variant="success"
                now={sentiment?.positive?.percentage || 50}
                key={1}
              />
              <ProgressBar
                className="rounded-0"
                variant="danger"
                now={sentiment?.negative?.percentage || 50}
                key={2}
              />
            </ProgressBar>
            <div
              style={{
                position: 'absolute',
                top: 0,
                width: `${sentiment?.positive?.percentage || 50}%`,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              onMouseEnter={() => setShowPositiveTooltip(true)}
              onMouseLeave={() => setShowPositiveTooltip(false)}
            >
              {showPositiveTooltip && (
                <span className="tooltip-text">
                  {textLang.positiveArticles[lang]}:{' '}
                  {sentiment?.positive?.number || 0}
                </span>
              )}
            </div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: `${sentiment?.positive?.percentage || 50}%`,
                width: `${sentiment?.negative?.percentage || 50}%`,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              onMouseEnter={() => setShowNegativeTooltip(true)}
              onMouseLeave={() => setShowNegativeTooltip(false)}
            >
              {showNegativeTooltip && (
                <span className="tooltip-text">
                  {textLang.negativeArticles[lang]}:{' '}
                  {sentiment?.negative?.number || 0}
                </span>
              )}
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

SentimentCount.propTypes = {
  sentiment: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired
};

export default SentimentCount;
