import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { BnbDiscountModal as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import BnbImg from 'assets/img/hodlie/coin/BNB.png';
import binanceImg from 'assets/img/hodlie/exchange/binance.png';

const SetBnbDiscountModal = ({
  modalShow,
  setModalShow,
  active,
  setBnbDiscount,
  isGoToWallet = false
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const handleChangeBnbDiscount = () => {
    setBnbDiscount(active ? false : true);
    setModalShow(false);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center m-3">
            <img src={binanceImg} style={{ height: '30px' }}></img>
            <h3
              className="text-center font-weight-bold ms-2 me-2"
              style={{ color: '#FFAF00' }}
            >
              {/*217*/}
              {textLang.title[lang]}
            </h3>
            <img src={BnbImg} style={{ height: '30px' }}></img>
          </Col>
        </Row>
        <p
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: textLang.generalText[lang]
          }}
        ></p>
        {isGoToWallet ? (
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: textLang.walletAgreement[lang]
            }}
          ></p>
        ) : (
          <>
            <p
              className="mb-1 text-center"
              dangerouslySetInnerHTML={{
                __html: textLang.actualSetting[lang]
              }}
            ></p>
            <p
              className={
                'text-center text-uppercase fw-bold ' +
                (active ? 'text-success' : 'text-danger')
              }
            >
              {active
                ? textLang.bnbDiscountPositiveText[lang]
                : textLang.bnbDiscountNegativeText[lang]}
            </p>
          </>
        )}
        <p
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: textLang.moreInfo[lang]
          }}
        ></p>
      </Modal.Body>
      <Modal.Footer>
        <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
          <IconButton
            onClick={() => setModalShow(false)}
            variant="outline-secondary"
            className="me-2 mb-1 "
            icon="arrow-left"
            iconAlign="left"
            transform="shrink-3"
          >
            {textLang.goBack[lang]}
          </IconButton>
        </Flex>
        <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
          <IconButton
            onClick={() => handleChangeBnbDiscount()}
            variant={
              isGoToWallet
                ? 'outline-warning'
                : active
                ? 'outline-danger'
                : 'outline-success'
            }
            className="me-2 mb-1 "
            icon={isGoToWallet ? 'wallet' : 'edit'}
            iconAlign="right"
            transform="shrink-3"
          >
            {isGoToWallet
              ? textLang.goToWallet[lang]
              : active
              ? textLang.bnbDiscountNegativeText[lang]
              : textLang.bnbDiscountPositiveText[lang]}
          </IconButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

SetBnbDiscountModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired,
  active: PropTypes.bool,
  setBnbDiscount: PropTypes.func.isRequired,
  isGoToWallet: PropTypes.bool
};

export default SetBnbDiscountModal;
