import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoPopover from 'components/utilities/InfoPopover';
import StatsChart from './StatsChart';
import { MarketItem as textLang } from 'staticData/languages';
import { currencyMap } from 'services/coins/common';
import { coinsData } from 'services/coins/common';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import 'assets/scss/custom/CoinStats.scss';

const StatsItem = ({ dynamicCoins, dynamic24hPerc, asset }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);
  const [coinDetails, setCoinDetails] = useState();
  const [loaded, setLoaded] = useState(false);
  const value = dynamicCoins[asset] || '';
  const perc = dynamic24hPerc[asset] || '';

  const getBinanceCoinHistory = async coin => {
    var temp = new Date();
    temp.setMilliseconds(0);
    // seconds * minutes * hours * milliseconds = 1 day
    var day = 60 * 60 * 24;
    var yesterday = new Date(temp.getTime() / 1000 - day);
    var params = {
      exchange: 'binance',
      coin: coin,
      startTime: yesterday.getTime(),
      granularity: 60
    };
    let coinHistoryResponse = await endpointInterface(
      lang,
      'backend',
      'getCoinDataWithGranularity',
      'get',
      true,
      params
    );
    if (coinHistoryResponse.validResponse) return coinHistoryResponse.data;
    else return [];
  };

  const getColorAndCaret = num => {
    if (Number(num) < 0) return ['text-danger', 'caret-down'];
    if (Number(num) > 0) return ['text-success', 'caret-up'];
    if (Number(num) === 0) return ['text-secondary', ''];
  };

  useEffect(async () => {
    try {
      let coinData = await getBinanceCoinHistory(asset + currencyMap[currency]);
      setCoinDetails(
        coinData.map(el => {
          return el.close;
        })
      );
      if (!loaded) setLoaded(true);
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  return (
    <>
      <Flex alignItems="center" className="mb-0 position-static">
        <img className="me-2" src={coinsData[asset].img} alt="" width="15" />
        <h6 className="mb-0 flex-1" style={{ maxWidth: 'fit-content' }}>
          {coinsData[asset].name}
        </h6>
        <InfoPopover Text={textLang.chartInfo[lang]}></InfoPopover>
      </Flex>
      <Row>
        <Col xs={7}>
          {perc !== '' && (
            <Flex>
              <p className="font-sans-serif lh-1 mb-1 fs-1 pe-2">
                {currency + value}
              </p>
              {perc !== 0 && (
                <FontAwesomeIcon
                  icon={getColorAndCaret(perc)[1]}
                  className={`me-1 mb-0 ${getColorAndCaret(perc)[0]}`}
                />
              )}
              <span className="fs--1">{Math.abs(perc.toFixed(2))}%</span>
            </Flex>
          )}
        </Col>
        <Col xs={5}>
          <div className="w-100 ms-2" style={{ cursor: 'default !important' }}>
            {loaded ? (
              <StatsChart color={coinsData[asset].color} data={coinDetails} />
            ) : (
              <Flex className="justify-content-center">
                <Spinner />
              </Flex>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const CoinStats = ({ dynamicCoins, dynamic24hPerc, singleCoin = '' }) => {
  const {
    config: { isReal }
  } = useContext(AppContext);
  return (
    <Card
      className={
        (singleCoin ? 'h-100 ' : '') +
        (isReal && !singleCoin ? 'realCard' : 'virtualCard')
      }
    >
      <Card.Body>
        <Row>
          {singleCoin ? (
            <Col xs={12} className="flex-between-center">
              <StatsItem
                asset={singleCoin}
                dynamicCoins={dynamicCoins}
                dynamic24hPerc={dynamic24hPerc}
              />
            </Col>
          ) : (
            <>
              {/* BTC */}
              <Col
                lg={4}
                className="border-bottom border-lg-0 border-lg-end pb-3 pb-lg-0"
              >
                <StatsItem
                  asset="BTC"
                  dynamicCoins={dynamicCoins}
                  dynamic24hPerc={dynamic24hPerc}
                />
              </Col>
              {/* ETH */}
              <Col
                lg={4}
                className="border-bottom border-lg-0 border-lg-end py-3 py-lg-0"
              >
                <StatsItem
                  asset="ETH"
                  dynamicCoins={dynamicCoins}
                  dynamic24hPerc={dynamic24hPerc}
                />
              </Col>
              {/* SOL */}
              <Col lg={4} className="pt-3 pt-lg-0">
                <StatsItem
                  asset="SOL"
                  dynamicCoins={dynamicCoins}
                  dynamic24hPerc={dynamic24hPerc}
                />
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

StatsItem.propTypes = {
  asset: PropTypes.string,
  dynamicCoins: PropTypes.object.isRequired,
  dynamic24hPerc: PropTypes.object.isRequired
};

CoinStats.propTypes = {
  dynamicCoins: PropTypes.object.isRequired,
  dynamic24hPerc: PropTypes.object.isRequired,
  singleCoin: PropTypes.string
};

export default CoinStats;
