import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { TermsAndConditions as textLang } from 'staticData/termsAndConditions';

const TosFormatter = ({ sectionRefs, isDocument = false }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    <>
      {textLang.sections.map((section, index) => (
        <div
          key={index}
          className={!isDocument ? 'mb-3 card' : 'mb-3'}
          id={!isDocument ? section.link : 'temp'}
          ref={!isDocument ? sectionRefs[index] : null}
        >
          <div className={!isDocument ? 'bg-light card-header' : 'mt-4 mb-3'}>
            <h5 className="mb-0">{index + 1 + ' ' + section.name[lang]}</h5>
          </div>
          <div className={!isDocument ? 'card-body' : ''}>
            {section.texts.map((text, insideIndex) => (
              <div key={insideIndex}>
                <p
                  className={'mb-2 ps-3 ' + textLang.textSize}
                  dangerouslySetInnerHTML={{
                    __html:
                      '<b>' +
                      (index + 1) +
                      '.' +
                      (insideIndex + 1) +
                      '</b> ' +
                      text[lang]
                  }}
                ></p>
                {text.subtexts &&
                  text.subtexts.map((subtext, subInsideIndex) => (
                    <p
                      key={subInsideIndex}
                      className={'mb-2 ps-3 ' + textLang.textSize}
                      style={{ marginLeft: '30px' }}
                      dangerouslySetInnerHTML={{
                        __html:
                          '<b>' +
                          (index + 1) +
                          '.' +
                          (insideIndex + 1) +
                          '.' +
                          (subInsideIndex + 1) +
                          '</b> ' +
                          subtext[lang]
                      }}
                    ></p>
                  ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      <p
        className={'mb-2 ps-3 ' + textLang.textSize}
        dangerouslySetInnerHTML={{ __html: textLang.ending[lang] }}
      ></p>
      <p className={'mb-2 ps-3 ' + textLang.textSize}>
        {textLang.lastUpdate[lang] + textLang.lastUpdateDate}
      </p>
    </>
  );
};

TosFormatter.propTypes = {
  sectionRefs: PropTypes.array,
  isDocument: PropTypes.bool
};

export default TosFormatter;
