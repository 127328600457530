import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, Spinner, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { Rewards as textLang } from 'staticData/languages';
import { sharedState } from 'staticData/common';
import Lottie from 'lottie-react';
import present from 'assets/img/animated-icons/present.json';

const GeneralModal = ({
  showModal,
  setShowModal,
  head,
  body,
  buttonText = '',
  refreshData = true,
  setRefreshData
}) => {
  const { lang } = sharedState.config;
  const [loadingButton, setLoadingButton] = useState(false);
  const [discountRedeemed, setDiscountRedeemed] = useState(false);

  const redeemDiscount = async () => {
    setLoadingButton(true);
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'redeemDiscount',
      httpMethod: 'post',
      usingCredentials: true
    });
    setLoadingButton(false);
    if (resp.validResponse) setDiscountRedeemed(true);
  };

  const hideModal = () => {
    if (discountRedeemed) setRefreshData(!refreshData);
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {head && (
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            {discountRedeemed ? textLang.modalHeadRedeem[lang] : head}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {discountRedeemed ? (
          <Row className="align-items-center">
            <Col lg={12} className="d-flex justify-content-center">
              <Lottie
                animationData={present}
                loop={true}
                style={{ height: '250px', width: '250px' }}
              />
            </Col>
          </Row>
        ) : (
          body
        )}
      </Modal.Body>
      <Modal.Footer>
        <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
          {discountRedeemed ? (
            <IconButton
              variant="primary"
              className="me-2 mb-1 "
              icon={'times'}
              iconAlign="left"
              transform="shrink-3"
              onClick={hideModal}
            >
              {textLang.close[lang]}
            </IconButton>
          ) : (
            <IconButton
              variant="success"
              className="me-2 mb-1 "
              icon={loadingButton ? '' : 'check'}
              iconAlign="left"
              transform="shrink-3"
              disabled={loadingButton}
              onClick={redeemDiscount}
            >
              {loadingButton ? (
                <Flex className="justify-content-center">
                  <Spinner
                    animation="border"
                    className="me-2 mt-1"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                  {buttonText}
                </Flex>
              ) : (
                buttonText
              )}
            </IconButton>
          )}
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

GeneralModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  head: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  refreshData: PropTypes.bool,
  setRefreshData: PropTypes.func
};

export default GeneralModal;
