import React from 'react';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const HalfDoughnutChart = ({ value = 70 }) => {
  const getColorBasedOnValue = value => {
    if (value >= 0 && value <= 24) {
      return 'orange';
    } else if (value >= 25 && value <= 49) {
      return 'amber';
    } else if (value >= 50 && value <= 74) {
      return 'lightgreen';
    } else if (value >= 75 && value <= 100) {
      return 'green';
    }
  };

  const options = {
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: getColor('gray-500'),
        borderColor: getColor('gray-500'),
        borderWidth: 1,
        callbacks: {
          labelTextColor() {
            return getColor('black');
          },
          label: function (tooltipItem) {
            return ' ' + tooltipItem.raw + '%';
          }
        }
      }
    }
  };

  const data = {
    labels: ['Value', 'Remaining'],
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [getColorBasedOnValue(value), getColor('gray-900')],
        borderColor: [getColorBasedOnValue(value), getColor('gray-900')]
      }
    ]
  };
  return (
    <Doughnut data={data} options={options} width="112" className="mb-n6" />
  );
};

HalfDoughnutChart.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string
};

export default HalfDoughnutChart;
