import PropTypes from 'prop-types';
import { Col, Accordion } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import React, { useContext } from 'react';
import AppContext from 'context/Context';
import { Pricing as textLang } from 'staticData/languages';
import 'assets/scss/custom/pricing.scss';
import { breakpoints } from 'helpers/utils';

const PricingDefaultCard = ({
  pricing: {
    title,
    titleIcon = '',
    subTitle,
    priceTime = 'monthly',
    price,
    isFeatured,
    featureTitle,
    features,
    isYearlyPromo,
    textUnderPrice
  },
  index
}) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const subTextLang = textLang.pricingCard;

  const FeatureList = ({ features, lang }) => (
    <ul className="list-unstyled mt-3">
      {features.map(feature => (
        <li className="py-1 fs--1" key={feature.title[lang]}>
          {feature.beforeIcon ? (
            <>
              <FontAwesomeIcon
                icon={feature.beforeIcon.name}
                className={`me-2 text-${
                  feature.beforeIcon?.color || 'success'
                }`}
                size={'sm'}
              />{' '}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
            </>
          )}
          {feature.title[lang]}{' '}
          {feature.icon && <FontAwesomeIcon icon={feature.icon} size={'sm'} />}
          {feature.tag && (
            <SoftBadge pill bg={feature.tag.type}>
              {feature.tag.label[lang]}
            </SoftBadge>
          )}
        </li>
      ))}
    </ul>
  );

  const Header = (
    <>
      <h3 className="fw-normal mt-2 my-sm-0 d-flex justify-content-center">
        {title}
        {titleIcon && (
          <FontAwesomeIcon icon={titleIcon} className="ps-2" size={'md'} />
        )}
      </h3>
      {subTitle[lang] && (
        <div
          className="m-0 d-flex align-items-center justify-content-center"
          style={{ minHeight: '60px' }}
        >
          <p className="mt-2 mb-0">{subTitle[lang]}</p>
        </div>
      )}
    </>
  );

  FeatureList.propTypes = {
    features: PropTypes.object,
    lang: PropTypes.string
  };

  const Prices = (
    <>
      {price.map((singlePrice, index) => (
        <div
          key={index}
          className="mb-0 pb-0 d-flex flex-row align-items-end justify-content-center position-relative"
        >
          <p
            className={
              index == price.length - 1
                ? 'p-0 m-0 ms-5 fw-medium fs-3 text-1000'
                : 'p-0 m-0 ms-5 fw-medium text-700 text-decoration-line-through'
            }
          >
            € {singlePrice.value}
          </p>
          <small
            className={
              index == price.length - 1
                ? 'mb-1 me-5 fs--1 text-700'
                : 'fs--2 me-5 text-700'
            }
          >
            /{' '}
            {priceTime === 'annual'
              ? subTextLang.year[lang]
              : subTextLang.month[lang]}
          </small>
          {singlePrice.tag && singlePrice.tag[lang] && (
            <SoftBadge
              className="p-1 discount-tag"
              pill
              style={{
                backgroundColor: singlePrice.tagBg,
                color: singlePrice.tagColor
              }}
            >
              <p
                style={
                  index == price.length - 1
                    ? { fontSize: '13px' }
                    : { fontSize: '8px' }
                }
                className="m-0 p-0"
              >
                {singlePrice.tag[lang]}
              </p>
            </SoftBadge>
          )}
        </div>
      ))}
      {textUnderPrice?.texts.map((text, index) => (
        <p key={index} className="mb-0 fs--1">
          {text}
        </p>
      ))}
      {textUnderPrice?.footer && (
        <small className="mb-0">{textUnderPrice.footer[lang]}</small>
      )}
      {isYearlyPromo && (
        <small className="mt-0 pt-0">{subTextLang.annualPayment[lang]} </small>
      )}
    </>
  );

  const Features = (
    <div className={`text-start px-sm-4 pt-2 py-sm-2`}>
      <h5 className="fw-medium fs-0">{featureTitle[lang]}</h5>
      <span className="d-none d-sm-block">
        <FeatureList features={features} lang={lang} />
      </span>
      {Object.keys(features).length > 0 && (
        <Accordion className="d-sm-none" defaultActiveKey="">
          <Accordion.Item>
            <Accordion.Header>
              {textLang.accordionHeader[lang]}
            </Accordion.Header>
            <Accordion.Body>
              <FeatureList features={features} lang={lang} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );

  return (
    <Col
      lg={4}
      className={classNames('pb-2 px-4', {
        'border-start border-end':
          isFeatured && window.innerWidth >= breakpoints['sm'],
        'border-top': window.innerWidth >= breakpoints['sm'] || index === 0
      })}
    >
      <div className="h100">
        <div
          className={
            'text-center ' + (userInfos?.pro_tier <= 2 ? 'pt-4' : 'py-4')
          }
        >
          {Header}
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              minHeight: '110px'
            }}
          >
            {Array.isArray(price) ? Prices : <h5>{price[lang]}</h5>}
          </div>
        </div>
        {Features}
      </div>
    </Col>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string,
    titleColor: PropTypes.string,
    titleIcon: PropTypes.string,
    subTitle: PropTypes.object,
    price: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    priceTime: PropTypes.string,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.object,
    features: PropTypes.object,
    isYearlyPromo: PropTypes.bool,
    textUnderPrice: PropTypes.object
  }),
  index: PropTypes.number
};

export default PricingDefaultCard;
