import React, { useEffect, useContext } from 'react';
import AuthCardLayout from './AuthCardLayout';
import AddUsernameForm from './AddUsernameForm';
import { useParams } from 'react-router-dom';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const { token } = useParams();

  const getUnsubscribe = async token => {
    var params = { token: token };
    await endpointInterface(
      lang,
      'backend',
      'getUnsubscribe',
      'get',
      true,
      params
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        await getUnsubscribe(token);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <AuthCardLayout footer={false}>
      <AddUsernameForm layout="card" />
    </AuthCardLayout>
  );
};

export default index;
