import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import { MostBots as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { coinsData } from 'services/coins/common';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart
]);

const getOptions = (coinChartData = [], holdChartData = []) => ({
  color: [
    getColor('info'),
    getColor('primary'),
    getColor('secondary'),
    getColor('warning'),
    getColor('success')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    show: coinChartData && coinChartData.length > 0, // if there are no bots don't print the percentage
    transitionDuration: 0,
    formatter: function (params) {
      return `<strong>${
        params.data.key
          ? coinsData[params.data.key].name + ` (${params.data.key})`
          : params.data.name
      }:</strong> ${Number(params.percent).toFixed(2)}% (${Number(
        params.data.value
      ).toFixed(2)}$)`;
    }
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius:
        coinChartData && coinChartData.length > 0
          ? ['65%', '80%']
          : ['0%', '0%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: coinChartData
    },
    {
      name: 'Marketing',
      type: 'pie',
      radius:
        holdChartData && holdChartData.length > 0
          ? ['88%', '100%']
          : ['0%', '0%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: holdChartData
    }
  ]
});

const CoinsCake = ({ coinChartData, holdChartData }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const chartRef = useRef(null);

  const totalCoins = coinChartData.length;
  const transformedChartData = coinChartData.map(item => ({
    key: item.code,
    perc: item.hodliePercentage,
    value: item.equivalent,
    itemStyle: { color: coinsData[item.code].color }
  }));
  const investedMap = {
    allocated: { name: textLang.allocated[lang], color: 'rgba(0,116,217,0.7)' },
    notAllocated: {
      name: textLang.notAllocated[lang],
      color: 'rgba(0,116,217,0.4)'
    }
  };

  // create invested / not invested cake
  const totalValue = Object.values(holdChartData).reduce(
    (sum, value) => sum + parseFloat(value),
    0
  );
  const investedCake = Object.entries(holdChartData).map(([key, value]) => {
    const numericValue = parseFloat(value);
    const percentage = (numericValue / totalValue) * 100;
    return {
      name: investedMap[key].name,
      value: value,
      perc: percentage.toFixed(2),
      itemStyle: { color: investedMap[key].color || '#ffffff' }
    };
  });

  return (
    <div className="position-relative py-2">
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(transformedChartData, investedCake)}
        style={{ height: '9.5rem' }}
      />
      <div className="position-absolute top-50 start-50 translate-middle text-center">
        <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
          <b>
            {totalCoins !== 0 ? textLang.assets[lang] : textLang.noBots[lang]}
          </b>
        </p>
        <p className="fs-2 mb-0 font-sans-serif fw-medium mt-n2 pt-2">
          {totalCoins !== 0 ? totalCoins : null}
        </p>
      </div>
    </div>
  );
};

CoinsCake.propTypes = {
  coinChartData: PropTypes.array,
  totalCoins: PropTypes.number,
  holdChartData: PropTypes.array
};

export default CoinsCake;
