import Calendar from 'components/common/Calendar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import AppContext from 'context/Context';

// eventName and eventNameLive must be different (variable is set in config(localstorage))
// Every event MUST have a different name:
// (standard: name-date for eventName and name-dateLIVE for eventNameLIVE)
const eventName = 'HodlieTwitch-26Giu';
const eventNameLive = 'HodlieTwitch-26GiuLIVE';

// START time to show banner
const showTimeStart = new Date('2024-06-26T16:45:00');
// start time to show LIVE data
const eventStartTime = new Date('2024-06-26T21:00:00');
// END time to show banner
const showTimeStop = new Date('2024-06-26T22:00:00');

// BANNER DATA
const calendar = {
  'en-EN': { month: 'Jun', day: '26' },
  'it-IT': { month: 'Giu', day: '26' }
};

// Event Link
const link = 'https://www.twitch.tv/hodlie';

// Show this data when start to show banner
const eventData = {
  title: {
    'en-EN': 'Tonight LIVE on Twitch',
    'it-IT': 'Stasera LIVE su Twitch'
  },
  hour: { 'en-EN': 'At: <b>9:00 PM</b>', 'it-IT': 'Alle ore: <b>21.00</b>' },
  description: {
    'en-EN': 'Click HERE and join us LIVE!',
    'it-IT': 'Clicca QUI e vieni in LIVE con noi!'
  }
};

// Show this data when the event is live
const eventDataLIVE = {
  title: {
    'en-EN': 'We are LIVE on our Twitch channel',
    'it-IT': 'La live su Twitch è iniziata'
  },
  hour: { 'en-EN': '', 'it-IT': '' },
  description: {
    'en-EN': 'Click HERE, we are LIVE!',
    'it-IT': 'Clicca QUI, siamo in diretta! '
  }
};
// END BANNER DATA

const Event = () => {
  const {
    config: { event, lang },
    setConfig
  } = useContext(AppContext);
  const [eventKey, setEventKey] = useState(eventName);
  const [show, setShow] = useState(false);
  const [eventLive, setEventLive] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleCloseModal = () => {
    setConfig('event', eventKey);
    setShow(!show);
  };

  const checkDate = () => {
    // before start LIVE
    if (
      currentDate >= showTimeStart &&
      currentDate < showTimeStop &&
      currentDate < eventStartTime
    ) {
      setShow(event !== eventName && true);
    }
    // After start LIVE
    else if (
      currentDate >= showTimeStart &&
      currentDate < showTimeStop &&
      currentDate >= eventStartTime
    ) {
      setShow(event !== eventNameLive && true);
      setEventLive(true);
      setEventKey(eventNameLive);
    }
    // END live
    else {
      setShow(false);
    }
  };

  useEffect(() => {
    checkDate();
    let localCurrentDate = new Date();
    var intervalCheckDate;
    if (localCurrentDate < showTimeStop) {
      intervalCheckDate = setInterval(() => {
        setCurrentDate(new Date());
      }, 5000);
    }
    return () => clearInterval(intervalCheckDate);
  }, [currentDate]);

  return (
    <Toast
      show={show}
      onClose={() => handleCloseModal()}
      className="position-fixed"
      style={{
        zIndex: '1016',
        right: window.innerWidth < 576 ? '0' : '1rem',
        backgroundColor: 'var(--falcon-200)',
        width: 'fit-content'
      }}
    >
      <Toast.Header>
        <strong className="me-auto" style={{ color: 'white' }}>
          {eventLive ? eventDataLIVE.title[lang] : eventData.title[lang]}
        </strong>
      </Toast.Header>
      <a href={link} target="__blank" style={{ textDecoration: 'none' }}>
        <Toast.Body>
          <Flex>
            <Calendar {...calendar[lang]} />
            <div
              className={
                'flex-1 position-relative ps-3' +
                (eventLive ? ' align-items-center d-flex' : '')
              }
            >
              {eventLive ? (
                <h5
                  style={{ color: '#2c7be5' }}
                  dangerouslySetInnerHTML={{
                    __html: eventDataLIVE.description[lang]
                  }}
                ></h5>
              ) : (
                <>
                  <p
                    className="text-1000 mb-1"
                    dangerouslySetInnerHTML={{
                      __html: eventData.hour[lang]
                    }}
                  ></p>
                  <p className="mb-1">{eventData.description[lang]}</p>
                </>
              )}
            </div>
          </Flex>
        </Toast.Body>
      </a>
    </Toast>
  );
};

Event.propTypes = {
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizer: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string,
    badge: PropTypes.object
  }),
  isLast: PropTypes.bool
};

export default Event;
