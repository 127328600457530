import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React, { useContext } from 'react';
import { Col, Form, FormCheck, Row } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Pricing as textLang } from 'staticData/languages';

const PricingDefaultHeader = ({
  isYearly,
  setIsYearly = null,
  disabled = false
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const subTextLang = textLang.pricingHeader;
  return (
    <Col xs={12} className="mb-3">
      <Row className="justify-content-center justify-content-sm-between">
        <Col sm="auto" className="text-center pt-2">
          <h5 className="d-inline-block">
            {subTextLang.title[lang] +
              ' ' +
              (setIsYearly === null
                ? subTextLang.quarterly[lang]
                : isYearly
                ? subTextLang.yearly[lang]
                : subTextLang.monthly[lang])}
          </h5>
          {isYearly && setIsYearly !== null && (
            <SoftBadge bg="success" className="ms-2" pill>
              {subTextLang.badge[lang]}
            </SoftBadge>
          )}
        </Col>
        <Col
          sm="auto"
          as={Flex}
          justifyContent="center"
          alignItems="center"
          className="mt-1 mt-sm-0"
        >
          {setIsYearly !== null && (
            <>
              <FormCheck.Label htmlFor="custom-switch" className="mt-2 me-2">
                {subTextLang.monthly[lang]}
              </FormCheck.Label>
              <Form.Check type="switch" id="yearly">
                <Form.Check.Input
                  style={{ cursor: 'pointer' }}
                  name="yearly"
                  checked={isYearly}
                  disabled={disabled}
                  onChange={() => setIsYearly(!isYearly)}
                />
              </Form.Check>
              <Form.Check.Label htmlFor="custom-switch" className="mt-2">
                {subTextLang.yearly[lang]}
              </Form.Check.Label>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};

PricingDefaultHeader.propTypes = {
  isYearly: PropTypes.bool,
  setIsYearly: PropTypes.func,
  disabled: PropTypes.bool
};

export default PricingDefaultHeader;
