import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Col, Row, Modal, Spinner } from 'react-bootstrap';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { cleanCache } from 'services/cache';
import { Done as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { calculateCoinsValue } from 'services/coins/functions';
import { walletImgRender } from 'services/coins/components';
import { botGeneralInfo } from 'staticData/common';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';

const checkPrimaryLightOptions = {
  loop: true,
  autoplay: true,
  animationData: checkPrimaryLight,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const CreateBotModal = ({ showModal, setShowModal }) => {
  const {
    config: { lang, savedBotParams, currency },
    setConfig,
    coinValues
  } = useContext(AppContext);
  const [createdBot, setCreatedBot] = useState();
  const [runBotButtonDisabled, setRunBotButtonDisabled] = useState(false);

  const createBot = async () => {
    setRunBotButtonDisabled(true);
    const filteredParams = { ...savedBotParams };

    // del useless keys
    delete filteredParams.time;
    delete filteredParams.botCategory;

    let resp = await endpointInterfaceV2({
      internalEndpoint: 'createBot',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: filteredParams
    });

    if (resp.validResponse) {
      setCreatedBot(resp.data.bot_info);
      await cleanCache(0);
    }
    setRunBotButtonDisabled(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setConfig('savedBotParams', {});
  };

  return (
    <Modal
      show={showModal}
      onHide={() => handleCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {createdBot ? (
        <Row
          className="align-items-center mt-6 mb-6 gutter-x-0"
          style={{ textAlign: 'center' }}
        >
          <Col>
            <Lottie
              options={checkPrimaryLightOptions}
              width="120px"
              height="120px"
            />
            <h2 className="fw-normal text-900 mt-2">
              {textLang.yourPersonal[lang]}
              <strong>{createdBot.name}</strong>
              {textLang.running[lang]}
            </h2>
            <h4 className="fw-normal text-700 mt-2">{textLang.check[lang]}</h4>
          </Col>
        </Row>
      ) : (
        Object.keys(savedBotParams).length && (
          <>
            <Modal.Header closeButton closeVariant="white">
              <Modal.Title id="contained-modal-title-vcenter">
                {textLang.head[lang]}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {textLang.body[lang]}
              <div className="py-3">
                <p className="mb-0">
                  {textLang.strategy[lang]}:{' '}
                  <b>
                    {savedBotParams.botCategory === 'bot'
                      ? Object.values(botGeneralInfo).find(
                          bot => bot.id === savedBotParams.type
                        )?.name
                      : savedBotParams.botCategory === 'portfolio' &&
                        portfolioList.find(
                          item => item.id === String(savedBotParams.type)
                        )?.name}
                  </b>
                </p>
                <Flex>
                  {textLang.coins[lang]}:{' '}
                  {walletImgRender(savedBotParams.wallet, 'ps-2')}
                </Flex>
                <p>
                  {textLang.value[lang]}:{' '}
                  <b>
                    {currency}
                    {calculateCoinsValue(
                      savedBotParams.wallet,
                      coinValues.current
                    )}
                  </b>
                </p>
              </div>
              {textLang.body_what[lang]}
            </Modal.Body>
            <Modal.Footer>
              <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
                <IconButton
                  variant="outline-secondary"
                  className="me-2 mb-1 "
                  icon="times"
                  iconAlign="left"
                  transform="shrink-3"
                  onClick={() => handleCloseModal()}
                >
                  {textLang.button_0[lang]}
                </IconButton>
              </Flex>
              <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
                <IconButton
                  onClick={() => createBot()}
                  variant="outline-success"
                  className="me-2 mb-1"
                  icon={runBotButtonDisabled ? '' : 'rocket'}
                  iconAlign="left"
                  transform="shrink-1"
                >
                  {runBotButtonDisabled && (
                    <Spinner
                      animation="border"
                      role="status"
                      className="me-2"
                      style={{ width: '1rem', height: '1rem' }}
                    ></Spinner>
                  )}
                  {textLang.runBot[lang]}
                </IconButton>
              </Flex>
            </Modal.Footer>
          </>
        )
      )}
    </Modal>
  );
};

CreateBotModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default CreateBotModal;
