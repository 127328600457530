import React, { useContext } from 'react';
import AppContext from 'context/Context';

const AddUsernameForm = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const text = {
    'en-EN': 'You will no longer receive marketing emails',
    'it-IT': 'Non riceverai più mail marketing'
  };
  return <h5>{text[lang]}</h5>;
};

export default AddUsernameForm;
