import React, { useState, useEffect, useContext } from 'react';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { Col, Row, Spinner, Button } from 'react-bootstrap';
import LinePayment from 'pages/common/BotWallet/LinePayment';
import MostBots from 'pages/common/BotWallet/MostBots';
import AppContext from 'context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import BotRow from 'pages/common/BotRow';
import PortfolioRow from 'pages/common/portfolioRow/PortfolioRow';
import SubSectionHeader from 'pages/common/SubSectionHeader';
import WalletManagement from 'pages/Account/WalletManagement';
import PageHeader from 'components/common/PageHeader';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Wallet as textLang } from 'staticData/languages';
import { currencyMap } from 'services/coins/common';

const index = ({ realoadUserInfo }) => {
  const {
    config: { lang, currency },
    userInfos,
    coinValues
  } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [botList, setBotList] = useState([]);
  const [loadedBotList, setLoadedBotList] = useState(false);
  const [dynamicLoaded, setDynamicLoaded] = useState(false);
  const [wallet, setWallet] = useState({});
  const [generalWallet, setGeneralWallet] = useState();
  const [dynamicBotMap, setDynamicBotMap] = useState();
  const [portfolioList, setPortfolioList] = useState([]);
  const [loadedLineChart, setLoadedLineChart] = useState(false);
  const [loadedCakeData, setLoadedCakeData] = useState(false);
  const [readyDynamicBot, setReadyDynamicBot] = useState(false);
  const [updateCoinValue, setUpdateCoinValue] = useState(true);
  const [walletCoinMap, setWalletCoinMap] = useState();
  const [dynamicCoins, setDynamicCoins] = useState({});
  const exchange = 'binance';

  const getCurrentAndPreviousMinuteIsoStrings = () => {
    const now = new Date();
    const oneMinuteAgo = new Date(now);

    // Set current time to 00:00:00 UTC for both
    now.setUTCHours(0, 0, 0, 0);
    oneMinuteAgo.setUTCHours(0, 0, 0, 0);

    // Subtract one minute from the previous time
    oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1);

    // Convert to ISO string and remove milliseconds
    const formatToIsoString = date =>
      date.toISOString().split('.')[0] + '+00:00';

    // Return array with one minute ago first, then now
    return [formatToIsoString(oneMinuteAgo), formatToIsoString(now)];
  };

  const getWalletStats = async () => {
    var params = { exchange: exchange, virtual: false };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getWallet',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      let data = walletStatsResponse.data;
      if (
        data[exchange]?.all_timepoints &&
        Object.keys(data[exchange]?.all_timepoints).length == 0
      ) {
        walletStatsResponse.data[exchange].all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[0]
        ] = { [currencyMap[currency]]: 0 };
        walletStatsResponse.data[exchange].all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[1]
        ] = { [currencyMap[currency]]: 0 };
        setUpdateCoinValue(false);
      }
      return walletStatsResponse.data;
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const processLiveData = (walletObj = wallet) => {
    var botLocalMap = {};
    var walletCoinsLocalMap = {};
    const aggregatedWallet = {};

    Object.entries(walletObj).map(([id, data]) => {
      let actualValue = null;
      let wallet = {};
      let assets = [];
      let botInitialAmount = 0;
      let sharpe = null;
      let coinSharpe = null;

      if (id === exchange) {
        // get last data in exchange timeseries
        if (Object.keys(data['4h_timepoints']).length > 0) {
          const newestDateKey = Object.keys(data['4h_timepoints']).reduce(
            (oldest, current) => {
              return new Date(current) > new Date(oldest) ? current : oldest;
            }
          );
          actualValue =
            data['4h_timepoints'][newestDateKey][currencyMap[currency]] || 0;
        }
      } else {
        botInitialAmount =
          data.bot_details.initial_eqv[currencyMap[currency]] || 0;
        wallet = data.last_stat.wallet;
        assets = data.bot_details.assets;
        sharpe = data.indicators.sharpe;
        coinSharpe = data.indicators.coin_sharpe;
        // get data for otal wallet
        for (let [currency, amount] of Object.entries(wallet)) {
          if (aggregatedWallet[currency]) {
            aggregatedWallet[currency] += amount;
          } else {
            aggregatedWallet[currency] = amount;
          }
        }
      }

      botLocalMap[id] = {
        initialAmount: botInitialAmount,
        wallet: wallet,
        assets: assets,
        sharpe: sharpe,
        coinSharpe: coinSharpe,
        actualValue: actualValue
      };
    });
    // Convert wallet to coin values and keep track of the total value
    walletCoinsLocalMap[exchange] = Object.entries(aggregatedWallet).reduce(
      (acc, [currency, amount]) => {
        const currentPrice = coinValues.current[currency] || 0;
        const value = amount * currentPrice;
        // Add the current value to the aggregated map
        acc[currency] = value;
        // Accumulate the total value
        if (acc.total) {
          acc.total += value;
        } else {
          acc.total = value;
        }
        return acc;
      },
      {}
    );

    if (!dynamicBotMap || !walletCoinMap || updateCoinValue) {
      setWalletCoinMap(walletCoinsLocalMap);
      setDynamicBotMap(botLocalMap);
    }
    if (
      Object.keys(botLocalMap).includes(exchange) &&
      Object.keys(botLocalMap[exchange]).includes('actualValue')
    )
      setGeneralWallet(botLocalMap[exchange].actualValue || 0);
    setReadyDynamicBot(true);
  };
  const convertWalletStats = walletObj => {
    const filteredAndMappedArray = Object.keys(walletObj)
      .filter(key => key !== exchange)
      .map(key => {
        const { bot_details, indicators, last_stat, snapshots } =
          walletObj[key];
        const added_capital = snapshots.reduce(
          (sum, snapshot) =>
            sum + (snapshot.event_metadata.eqv[currencyMap[currency]] || 0),
          0
        );
        return {
          bot_details: bot_details,
          indicators: indicators,
          last_stat: last_stat,
          added_capital: added_capital,
          coins: bot_details.assets
        };
      })
      .sort(
        (a, b) =>
          new Date(b.bot_details.created_at) -
          new Date(a.bot_details.created_at)
      );

    const bots = filteredAndMappedArray.filter(
      item => item.bot_details?.category === 'bot'
    );
    const portfolios = filteredAndMappedArray.filter(
      item => item.bot_details?.category === 'portfolio'
    );

    return {
      bots,
      portfolios
    };
  };

  const manageBotsData = async localWalletStat => {
    let localBotList = convertWalletStats(localWalletStat);
    setBotList(localBotList.bots);
    // setPortfolioList(localBotList.bots);
    // TODO use this
    setPortfolioList(localBotList.portfolios);
    setLoadedBotList(true);
  };

  useEffect(() => {
    let localWallet = {};
    const fetchData = async () => {
      try {
        localWallet = await getWalletStats();
        manageBotsData(localWallet);
        // If there is no data setLoadedLineChart only for tab enable
        let checkLineChart;
        if (
          !(
            Object.keys(localWallet).length > 0 &&
            localWallet[exchange] &&
            Object.keys(localWallet[exchange]).length > 0 &&
            Object.keys(localWallet[exchange]['all_timepoints']).length > 0
          )
        )
          checkLineChart = true;
        else checkLineChart = false;
        if (checkLineChart !== loadedLineChart)
          setLoadedLineChart(checkLineChart);
        // END If there is no data setLoadedLineChart only for tab enable
        setWallet(localWallet);
        setDynamicLoaded(true);
        setLoaded(true);
        processLiveData(localWallet);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (coinValues.current) {
      setDynamicCoins(coinValues.current);
    }
    if (Object.keys(userInfos).length > 0 && !loaded) fetchData();
    else if (Object.keys(wallet).length > 0) {
      processLiveData();
    }
  }, [userInfos]);

  useEffect(async () => {
    var intervalCoinsData = setInterval(async () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
      }
    }, 100);

    return () => {
      clearInterval(intervalCoinsData);
    };
  }, []);

  return (
    <>
      {userInfos.pro_tier > 0 || !userInfos.trial_allowed ? (
        <>
          <Row className="mb-3 gy-3">
            <Col sm={8}>
              <LinePayment
                allWallet={wallet}
                dynamicBotMap={dynamicBotMap}
                generalWallet={generalWallet}
                h100={true}
                loaded={loadedLineChart}
                dynamicLoaded={dynamicLoaded}
                setLoaded={setLoadedLineChart}
                parentLoaded={loaded && readyDynamicBot}
                multiBot={true}
              />
            </Col>
            <Col sm={4}>
              <MostBots
                dataReady={readyDynamicBot}
                walletCoinMap={walletCoinMap}
                loaded={loadedCakeData}
                setLoaded={setLoadedCakeData}
                parentLoaded={loaded}
              />
            </Col>
          </Row>
          <SubSectionHeader title={<Flex>AI Portfolio</Flex>} />
          {loadedBotList ? (
            portfolioList.length ? (
              portfolioList.map((bot, index) => (
                <PortfolioRow
                  bot={bot}
                  key={index}
                  dynamicCoins={dynamicCoins}
                />
              ))
            ) : (
              <Row className={'pb-3'}>
                <Col xs={12}>
                  <PageHeader
                    as={Link}
                    to="/invest?type=portfolio"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    title={textLang.addPortfolio[lang]}
                    description={textLang.addTextPortfolio[lang]}
                    className="mb-3"
                    col={{ lg: 12 }}
                  >
                    <Button variant="link" size="sm" className="ps-0">
                      {textLang.addButton[lang]}
                      <FontAwesomeIcon
                        icon="chevron-right"
                        className="ms-1 fs--2"
                      />
                    </Button>
                  </PageHeader>
                </Col>
              </Row>
            )
          ) : (
            <Flex className="justify-content-center mt-4">
              <Spinner />
            </Flex>
          )}
          <SubSectionHeader
            title={
              <Flex>
                {botList.length > 0 && 'AI Agents'}
                <>
                  {' '}
                  <span className="d-none d-sm-block">
                    {botList.length > 0 && `(${String(botList.length)}) -`}
                    <Link to="/invest">{textLang.addStrat[lang]}</Link>
                  </span>
                </>
              </Flex>
            }
          />
          <Link className="d-sm-none" to="/invest">
            {textLang.addStrat[lang]}
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Link>
          <Row className={'pb-3'}>
            <Col xs={12}>
              {loadedBotList ? (
                botList.length ? (
                  botList.map((bot, index) => (
                    <BotRow bot={bot} key={index} dynamicCoins={dynamicCoins} />
                  ))
                ) : (
                  <Row className={'pb-3'}>
                    <Col xs={12}>
                      <PageHeader
                        as={Link}
                        to="/invest"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                        title={textLang.add[lang]}
                        description={textLang.addText[lang]}
                        className="mb-3"
                        col={{ lg: 12 }}
                      >
                        <Button variant="link" size="sm" className="ps-0">
                          {textLang.addButton[lang]}
                          <FontAwesomeIcon
                            icon="chevron-right"
                            className="ms-1 fs--2"
                          />
                        </Button>
                      </PageHeader>
                    </Col>
                  </Row>
                )
              ) : (
                <Flex className="justify-content-center mt-4">
                  <Spinner />
                </Flex>
              )}
            </Col>
          </Row>
          <Row className={'pb-3'}>
            <Col xs={12}>
              <PageHeader
                as={Link}
                to="/account/wallet-management"
                style={{ textDecoration: 'none', color: 'inherit' }}
                title={textLang.manage[lang]}
                description={textLang.manageText[lang]}
                className="mb-3"
              >
                <Button variant="link" size="sm" className="ps-0">
                  {textLang.goto[lang]}
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="ms-1 fs--2"
                  />
                </Button>
              </PageHeader>
            </Col>
          </Row>
        </>
      ) : (
        <WalletManagement realoadUserInfo={realoadUserInfo} showCoins={true} />
      )}
    </>
  );
};

export default index;
