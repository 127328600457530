import React, { useContext } from 'react';
import AppContext from 'context/Context';
import { TermsAndConditions as textLang } from 'staticData/termsAndConditions';
import { Col } from 'react-bootstrap';
import TosFormatter from './tosFormatter';
import 'assets/scss/custom/documentToPrint.scss';

const DocumentToPrint = React.forwardRef((props, ref) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    <Col ref={ref} className="pe-lg-2">
      <h2 className="mb-5 text-center">{textLang.title[lang]}</h2>
      <TosFormatter isDocument={true} />
      <p className="mt-5 d-flex justify-content-end">
        <i>{textLang.signature[lang]}</i>
      </p>
    </Col>
  );
});

export default DocumentToPrint;
