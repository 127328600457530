import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LoginForm from 'components/authentication/LoginForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { Link } from 'react-router-dom';
import { login as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const Login = ({ getInfo }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          {textLang.noAccount[lang]}
          <br />
          <Link className="text-white text-decoration-underline" to={'signup'}>
            {textLang.register[lang]}
          </Link>
        </p>
      }
      footer={false}
    >
      <h3>{textLang.login[lang]}</h3>
      <LoginForm layout="card" hasLabel getInfo={getInfo} />
    </AuthCardLayout>
  );
};

Login.propTypes = {
  getInfo: PropTypes.func
};

export default Login;
