import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardDropdown as textLang, monthsList } from 'staticData/languages';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Modal,
  Spinner,
  Col,
  Row,
  Button,
  FormControl,
  InputGroup,
  Form,
  ButtonGroup,
  ToggleButton
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import SoftBadge from 'components/common/SoftBadge';
import { cleanCache } from 'services/cache';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { coinsData } from 'services/coins/common';

const PacModal = ({ showModal, setShowModal, name, bot }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const defaultAmount = 200;

  // Determine the existing PAC quote used
  const allowedQuotes = ['USDC', 'USDT'];
  const pacQuote =
    allowedQuotes.find(
      quote =>
        bot?.pac?.amounts &&
        Object.prototype.hasOwnProperty.call(bot.pac.amounts, quote)
    ) || 'USDC';

  const [selectedQuote, setSelectedQuote] = useState(pacQuote);
  const [showEditPacModal, setShowEditPacModal] = useState(false);
  const [dayInWeek, setDayInWeek] = useState(() => {
    return bot?.pac?.schedule_type === 'WEEKLY' ? bot.pac.schedule_day : 0;
  });
  const [dayInMonth, setDayInMonth] = useState(() => {
    return bot?.pac?.schedule_type === 'MONTHLY' ? bot.pac.schedule_day : 1;
  });
  const [typePac, setTypePac] = useState(() => {
    return bot?.pac?.schedule_type || 'MONTHLY';
  });
  const [amount, setAmount] = useState(() => {
    return bot?.pac?.amounts[selectedQuote] || defaultAmount;
  });
  const [showDeletePacModal, setShowDeletePacModal] = useState(false);
  const [waitPac, setWaitPac] = useState(false);
  const [operationPacSuccess, setOperationPacSuccess] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(null);
  const [showError, setShowError] = useState(null);

  const handleClosePacModal = () => {
    setShowModal(false);
    setShowDeletePacModal(false);
    setShowEditPacModal(false);
    if (operationPacSuccess) handleCloseClickTimer();
  };

  const daysInWeekMap = {
    0: textLang.monday[lang],
    1: textLang.tuesday[lang],
    2: textLang.wednesday[lang],
    3: textLang.thursday[lang],
    4: textLang.friday[lang],
    5: textLang.saturday[lang],
    6: textLang.sunday[lang]
  };
  const availableDaysInWeek = Object.keys(daysInWeekMap);
  const minAmount = 50;
  const stepAmount = 5;
  const maxAmount = 1000;
  const minPacAmount = 50;

  const availableDaysInMonth = [...Array(28).keys()];

  const botStatusColor = status => {
    if (status?.toUpperCase() === 'ACTIVE') return 'success';
    if (status?.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const getDate = dateStr => {
    let date = new Date(dateStr);

    let day = date.getDate();
    let month = monthsList.monthsLong[lang][date.getMonth() || 0];
    let year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const restartPac = async () => {
    setWaitPac(true);
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'botPac',
      httpMethod: 'put',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: { status: 'ACTIVE' }
    });
    if (resp.validResponse) {
      setOperationPacSuccess(true);
      await cleanCache(0);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
      setRedirectTimer(timer);
    }
    setWaitPac(false);
  };

  const editPac = async () => {
    setShowError(null);
    if (Number(amount) < minPacAmount) {
      setShowError(textLang.minAmountError[lang]);
      return;
    }
    setWaitPac(true);
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'botPac',
      httpMethod: 'put',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: {
        amounts: {
          [selectedQuote]: Number(amount)
        },
        schedule_day: Number(typePac === 'MONTHLY' ? dayInMonth : dayInWeek),
        schedule_type: typePac,
        status: 'ACTIVE'
      }
    });
    if (resp.validResponse) {
      setOperationPacSuccess(true);
      await cleanCache(0);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
      setRedirectTimer(timer);
    }
    setWaitPac(false);
  };

  const deletePac = async () => {
    setWaitPac(true);
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'botPac',
      httpMethod: 'delete',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      }
    });
    if (resp.validResponse) {
      setOperationPacSuccess(true);
      await cleanCache(0);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
      setRedirectTimer(timer);
    }
    setWaitPac(false);
  };

  const handleCloseClickTimer = () => {
    if (redirectTimer) {
      clearTimeout(redirectTimer);
    }
    window.location.reload();
  };

  // del timer during return
  useEffect(() => {
    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [redirectTimer]);

  return (
    <Modal
      show={showModal}
      onHide={() => handleClosePacModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          {textLang.recurringIncrease[lang]}
        </Modal.Title>
      </Modal.Header>
      {!operationPacSuccess ? (
        !showDeletePacModal && !showEditPacModal ? (
          <>
            <Modal.Body>
              <h5 className="mb-2">{name}</h5>
              {bot?.pac?.status.toUpperCase() === 'ERROR' && (
                <Alert variant="danger">
                  <p
                    className="mb-0 fs--1"
                    dangerouslySetInnerHTML={{
                      __html: textLang.pacError[lang]
                    }}
                  ></p>
                </Alert>
              )}
              <p>
                <b>{textLang.state[lang]}</b>:{' '}
                <SoftBadge pill bg={botStatusColor(bot?.pac?.status)}>
                  {bot?.pac?.status.toUpperCase() === 'ACTIVE'
                    ? textLang.active[lang]
                    : bot?.pac?.status.toUpperCase() === 'PAUSED'
                    ? textLang.paused[lang]
                    : textLang.error[lang]}
                </SoftBadge>
                {bot?.pac?.status.toUpperCase() === 'ERROR' && (
                  <IconButton
                    variant={'primary'}
                    className="ms-2"
                    icon={waitPac ? '' : 'play'}
                    disabled={waitPac}
                    iconAlign="left"
                    transform="shrink-3"
                    onClick={() => restartPac()}
                  >
                    {waitPac && (
                      <Spinner
                        className="me-2 mt-1"
                        style={{ width: '1rem', height: '1rem' }}
                      />
                    )}
                    {textLang.restart[lang]}
                  </IconButton>
                )}
              </p>
              <p>
                <b>{textLang.startDate[lang]}</b>:{' '}
                <span className="text-dark">
                  {bot?.pac?.created_at
                    ? getDate(bot?.pac?.created_at)
                    : 'n.d.'}
                </span>
              </p>
              <p>
                <b>{textLang.mode[lang]}</b>:{' '}
                <span className="text-dark">
                  {bot?.pac?.schedule_type
                    ? bot?.pac?.schedule_type === 'WEEKLY'
                      ? `Settimanale (${daysInWeekMap[bot?.pac?.schedule_day]})`
                      : `Mensile (${bot?.pac?.schedule_day}° giorno del mese)`
                    : 'n.d.'}
                </span>
              </p>
              <p>
                <b>{textLang.quantity[lang]}</b>:{' '}
                <span className="text-dark">
                  {bot?.pac?.amounts[pacQuote] || 'n.d.'} {pacQuote}
                </span>
              </p>
              <p
                className="text-primary pointerCursor"
                onClick={() => setShowEditPacModal(true)}
              >
                <FontAwesomeIcon icon="edit" className="me-2" />
                <b>{textLang.editPAC[lang]}</b>
              </p>
              <p
                className="text-danger pointerCursor"
                onClick={() => setShowDeletePacModal(true)}
              >
                <FontAwesomeIcon icon="trash" className="me-2" />
                <b>{textLang.deletePAC[lang]}</b>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
                <IconButton
                  variant={'primary'}
                  className="me-2 mb-1 "
                  icon={'times'}
                  iconAlign="left"
                  transform="shrink-3"
                  onClick={() => handleClosePacModal(false)}
                >
                  {textLang.close[lang]}
                </IconButton>
              </Flex>
            </Modal.Footer>
          </>
        ) : (
          <>
            {
              // edit Pac
              showEditPacModal && (
                <>
                  <Modal.Body>
                    <h4
                      className="mt-2 text-center font-weight-bold"
                      dangerouslySetInnerHTML={{
                        __html: textLang.editPAC[lang]
                      }}
                    ></h4>
                    <Row className="justify-content-center">
                      <Col
                        xs={6}
                        className="align-items-center mt-4 mb-3 gutter-x-0"
                        style={{ textAlign: 'center' }}
                      >
                        <Row className="pb-2 d-flex align-items-center">
                          <Col className="justify-content-center d-flex">
                            <ButtonGroup>
                              {['WEEKLY', 'MONTHLY'].map(item => (
                                <ToggleButton
                                  id={`radio-` + item}
                                  key={item}
                                  type="radio"
                                  variant={'outline-primary'}
                                  name="radio"
                                  value={item}
                                  checked={typePac === item}
                                  onChange={e =>
                                    setTypePac(e.currentTarget.value)
                                  }
                                >
                                  {textLang[item.toLowerCase()][lang]}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <Row className="pt-2 pb-2 d-flex align-items-center justify-content-center">
                          <Col sm={8}>
                            {typePac == 'WEEKLY' && (
                              <InputGroup size="sm">
                                <Form.Select
                                  className="pe-5 "
                                  defaultValue={dayInWeek}
                                  onChange={({ target }) =>
                                    setDayInWeek(target.value)
                                  }
                                >
                                  {availableDaysInWeek.map(item => (
                                    <option key={item} value={item}>
                                      {daysInWeekMap[item]}
                                    </option>
                                  ))}
                                </Form.Select>
                              </InputGroup>
                            )}
                            {typePac == 'MONTHLY' && (
                              <InputGroup size="sm">
                                <Form.Select
                                  className="pe-5 "
                                  defaultValue={dayInMonth}
                                  onChange={({ target }) =>
                                    setDayInMonth(target.value)
                                  }
                                >
                                  {availableDaysInMonth.map(item => (
                                    <option key={item} value={item + 1}>
                                      {item + 1}° giorno del mese
                                    </option>
                                  ))}
                                </Form.Select>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>
                        <hr />
                        <Row className="pb-2 d-flex align-items-center justify-content-center">
                          <Col className="justify-content-center d-flex">
                            <ButtonGroup>
                              {allowedQuotes.map(item => (
                                <ToggleButton
                                  id={`quote-` + item}
                                  key={item}
                                  type="radio"
                                  variant={'outline-primary'}
                                  name="quote"
                                  value={item}
                                  checked={selectedQuote === item}
                                  onChange={e =>
                                    setSelectedQuote(e.currentTarget.value)
                                  }
                                >
                                  {item}
                                </ToggleButton>
                              ))}
                            </ButtonGroup>
                          </Col>
                        </Row>

                        <Row className="pb-2 d-flex align-items-center justify-content-center">
                          <h6 className="mb-1 text-secondary position-relative">
                            {textLang.amount[lang] + ' (' + selectedQuote + ')'}
                          </h6>
                          <Col
                            xs={4}
                            sm={2}
                            className="d-flex align-items-center justify-content-center flex-row"
                          >
                            <Avatar src={coinsData[selectedQuote].img}></Avatar>
                          </Col>
                          <Col xs={8} className="ms-0 ps-0">
                            <InputGroup className="ms-0 p-1">
                              <FormControl
                                type="number"
                                onChange={e => setAmount(e.target.value)}
                                min={minAmount}
                                step={stepAmount}
                                max={maxAmount}
                                value={amount}
                              />
                            </InputGroup>
                          </Col>
                          {showError && (
                            <p className="text-danger">
                              <b>{showError}</b>
                            </p>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <small>{textLang.pacDisclaimer[lang]}</small>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Flex
                      className="col-6 m-0"
                      style={{ justifyContent: 'start' }}
                    >
                      <IconButton
                        variant={'outline-primary'}
                        className="me-2 mb-1 "
                        icon={'arrow-left'}
                        iconAlign="left"
                        transform="shrink-3"
                        onClick={() => setShowEditPacModal(false)}
                      >
                        {textLang.back[lang]}
                      </IconButton>
                    </Flex>
                    <Flex
                      className="col-6 m-0"
                      style={{ justifyContent: 'end' }}
                    >
                      <IconButton
                        variant={'success'}
                        className="me-2 mb-1 "
                        icon={waitPac ? '' : 'check'}
                        disabled={waitPac}
                        iconAlign="left"
                        transform="shrink-3"
                        onClick={() => editPac()}
                      >
                        {waitPac && (
                          <Spinner
                            className="me-2 mt-1"
                            style={{ width: '1rem', height: '1rem' }}
                          />
                        )}
                        {textLang.confirm[lang]}
                      </IconButton>
                    </Flex>
                  </Modal.Footer>
                </>
              )
            }
            {
              // delete pac
              showDeletePacModal && (
                <>
                  <Modal.Body>{textLang.deletePACText[lang]}</Modal.Body>
                  <Modal.Footer>
                    <Flex
                      className="col-6 m-0"
                      style={{ justifyContent: 'start' }}
                    >
                      <IconButton
                        variant={'outline-primary'}
                        className="me-2 mb-1 "
                        icon={'arrow-left'}
                        iconAlign="left"
                        transform="shrink-3"
                        onClick={() => setShowDeletePacModal(false)}
                      >
                        {textLang.back[lang]}
                      </IconButton>
                    </Flex>
                    <Flex
                      className="col-6 m-0"
                      style={{ justifyContent: 'end' }}
                    >
                      <IconButton
                        variant={'danger'}
                        className="me-2 mb-1 "
                        icon={waitPac ? '' : 'trash'}
                        disabled={waitPac}
                        iconAlign="left"
                        transform="shrink-3"
                        onClick={() => deletePac()}
                      >
                        {waitPac && (
                          <Spinner
                            className="me-2 mt-1"
                            style={{ width: '1rem', height: '1rem' }}
                          />
                        )}
                        {textLang.delete[lang]}
                      </IconButton>
                    </Flex>
                  </Modal.Footer>
                </>
              )
            }
          </>
        )
      ) : (
        <>
          <Modal.Body>
            <Row className="align-items-center">
              <Col lg={12} className="d-flex justify-content-center">
                <Lottie
                  animationData={checkPrimaryLight}
                  loop={true}
                  style={{ height: '120px', width: '120px' }}
                />
              </Col>
            </Row>
            <h4
              className="text-center font-weight-bold"
              dangerouslySetInnerHTML={{
                __html:
                  !showEditPacModal && !showDeletePacModal
                    ? textLang.restartSuccess[lang]
                    : showEditPacModal
                    ? textLang.recurringIncreaseEdit[lang]
                    : textLang.recurringIncreaseDeleted[lang]
              }}
            ></h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleCloseClickTimer()}>
              {textLang.close[lang]}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

PacModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  bot: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default PacModal;
