import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FormTitle from 'pages/common/FormTitle';
import { Support as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { telegramChannel } from 'staticData/hodlieInfos';
import { Community as textLangCommunity } from 'staticData/languages';
import Telegram from 'assets/img/hodlie/community/telegram.png';
import Instagram from 'assets/img/hodlie/community/instagram.png';
import Linkedin from 'assets/img/hodlie/community/linkedin.png';
import { firstProTier } from 'staticData/common';
import { trackEvent } from 'services/externals/mixpanel';
import Flex from 'components/common/Flex';
import { supportLinks } from 'staticData/support';

const index = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);

  const userLevel =
    userInfos.pro_tier < firstProTier
      ? 'USER'
      : userInfos.pro_tier == firstProTier
      ? 'EXPLORER'
      : 'PRIVATE';

  const handleTrackEvent = () => {
    trackEvent('Support', {
      event: 'Click on calendar link'
    });
  };

  return (
    <>
      <Row>
        <FormTitle icon="headset" title={textLang.title[lang]} subtitle="" />
      </Row>
      <Row className="mt-3">
        <Col lg={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h4 className="">Community</h4>
              <p style={{ fontSize: 'large' }}>
                {textLangCommunity.title[lang]}
              </p>
              <Row>
                <Col as={Flex} className="align-items-center">
                  <p>{textLangCommunity.where[lang]}</p>
                </Col>
                <Col>
                  <Row className="justify-content-center mb-2">
                    <Col xs={4}>
                      <a
                        href={telegramChannel}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Card.Img
                          src={Telegram}
                          variant="top"
                          style={{ maxWidth: '80px' }}
                        />
                      </a>
                    </Col>
                    <Col xs={4}>
                      <a
                        href="https://www.instagram.com/hodlie.ai/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Card.Img
                          src={Instagram}
                          variant="top"
                          style={{ maxWidth: '80px' }}
                        />
                      </a>
                    </Col>
                    <Col xs={4}>
                      <a
                        href="https://www.linkedin.com/company/hodlie/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Card.Img
                          src={Linkedin}
                          variant="top"
                          style={{ maxWidth: '80px' }}
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <h4 className="">{textLang.titleCard3[lang]}</h4>
              <p className="mt-1" style={{ fontSize: 'large' }}>
                {textLang.text3[lang]}{' '}
                <a href="mailto:support@hodlie.net">support@hodlie.net</a>
                {', '}
                {textLang.text4[lang]}
              </p>
              {userLevel != 'EXPLORER' && (
                <p className="mt-1" style={{ fontSize: 'large' }}>
                  {textLang.text5[userLevel][lang]}{' '}
                  <a
                    href={
                      userInfos.pro_tier < firstProTier
                        ? supportLinks['USER']
                        : userInfos.pro_tier === firstProTier
                        ? supportLinks['EXPLORER']
                        : supportLinks['INVESTOR_&_PRIVATE']
                    }
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleTrackEvent()}
                  >
                    {textLang.here[lang]}
                  </a>
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default index;
